import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";

interface historyEntries {
    pageTitle: string;
    databaseVersionToShow: string;
    dateVisited: string;
    persistentUrl: string;
}

interface DataObj {
    taskId: string;
    sessionId: string;
    species: string;
    inputItems: string;
    proteinCount: number;
    nodeCount: number;
    pageTitle: string;
    Error?: string;
    ErrorMessage?: string;
    channel1?: string;
    channel2?: string;
    channel3?: string;
    channel4?: string;
    channel5?: string;
    channel6?: string;
    channel7?: string;
}

const MyHistory = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<historyEntries[]>([]);
    const {UrlSTRING,} = useContext(GlobalContext);


    let openIdAuthentifiedId: string | undefined = undefined;
    let cookies = document.cookie.split(';');
    let stringLoginCredentialsValues: Array<string> = [];

    // Collect all instances of 'string_login_credentials' with values
    cookies.forEach(cookie => {
        const [name, value] = cookie.trim().split('=');

        if (name === 'm.string_login_credentials' && value !== '_unassigned' && value !== undefined) {
            stringLoginCredentialsValues.push(value);
        }
    });
    let url = '';
    if (stringLoginCredentialsValues.length > 0) {
        openIdAuthentifiedId = stringLoginCredentialsValues[0];
        const randomParam = new Date().getTime(); // or Math.random()
        url = `${UrlSTRING}/cgi/api?apiMethod=user_browsing_history&apiOutputType=json&openIdAuthentifiedId=${openIdAuthentifiedId}&caller_identity=mobileapp&nocache=${randomParam}`;
    }

    const {showBoundary} = useErrorBoundary();

    useEffect(() => {
        if (url === '') {
            return;
        }
        const fetchInfo = () => {
            return fetch(url)
                .then((res) => res.json())
                .then((d) => setData(d))
                .catch((error) => {
                    setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
                });
        };
        fetchInfo();

    }, [openIdAuthentifiedId]);


    const filterUnassignedParams = (url: string): string => {
        // Parse the URL
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);

        // Filter out parameters with the value '_unassigned'
        params.forEach((value, key) => {
            if (!key.includes("identifier")){
                if (value === '_unassigned' || value === '0') {
                    params.delete(key);
                }
            }
            const channelRegex = /^channel[1-7]$/;
            if (channelRegex.test(key)) {
                if (value === "1") {
                    params.set(key, "on");
                }
            }
        });

        let filterOut = ['suppressDisambiguation', 'targetMode', 'textminingInputPartnersOnly', 'textminingShowAllDirect', 'textminingShowAllTransferred', 'geEnrichmentTablesSortColumn', 'setEvidenceShowAllDirect', 'networkClusteringParameterMcl'];
        filterOut.forEach((key) => {
            if (params.has(key)) {
                params.delete(key);
            }
        });

        // Construct the new URL with filtered parameters
        urlObj.search = params.toString();
        return urlObj.toString();
    };
    const navigate_to_page = async (url: string) => {

        // let openIdAuthentifiedId = document.cookie.split(';').find((cookie) => cookie.includes('string_login_credentials'))?.split('=')[1];
        // let parameters = url.replace(`${UrlSTRING}/cgi/network?`, '');
        const UrlSTRINGwithVersion = /^https:\/\/[^/]+\.string-db\.org\/cgi\/network\?/;
        let parameters = url.replace(UrlSTRINGwithVersion, '');

        let new_url = `${UrlSTRING}/cgi/api?apiMethod=init_taskdata&apiOutputType=json&${parameters}&openIdAuthentifiedId=${openIdAuthentifiedId}&caller_identity=mobileapp`;

        new_url = filterUnassignedParams(new_url);
        new_url = new_url.replace(/\r/g, '%0d');
        const urlParams = new URLSearchParams(new_url);
        let identifiers: string | undefined | null = urlParams.get('identifiers');
        const requiredScore = urlParams.get('requiredScore');
        const networkType = urlParams.get('networkType');
        const limit = urlParams.get('limit');
        const additionalNetworkNodes = urlParams.get('additionalNetworkNodes');
        const netFdr = urlParams.get('netFdr');

        try {
            const res = await fetch(new_url);
            const d: DataObj[] = await res.json();
            const data = d.map((item) => ({
                taskId: item.taskId,
                sessionId: item.sessionId,
                species: item.species,
                inputItems: item.inputItems,
                proteinCount: item.proteinCount,
                nodeCount: item.nodeCount,
                pageTitle: item.pageTitle,
                Error: item.Error,
                ErrorMessage: item.ErrorMessage,
                channel1: item.channel1,
                channel2: item.channel2,
                channel3: item.channel3,
                channel4: item.channel4,
                channel5: item.channel5,
                channel6: item.channel6,
                channel7: item.channel7,
            }));

            if (data[0].Error) {
                let errorData = {
                    title: data[0].Error,
                    message: data[0].ErrorMessage
                }
                return {
                    path: '/error',
                    newData: errorData
                };
            }

            if (data.length > 0) {
                // Construct the new path
                // if (!identifiers){
                identifiers = data[0].inputItems
                // }
                const newPath = `/cgi/network/${data[0].taskId}/${data[0].sessionId}`;
                navigate(newPath, {
                    state: {
                        species: data[0].species,
                        identifier: identifiers,
                        requiredScore: requiredScore,
                        networkType: networkType,
                        networkFlavor: "evidence",
                        limit: limit,
                        additionalNetworkNodes: additionalNetworkNodes,
                        netFdr: netFdr,
                        proteinCount: data[0].proteinCount,
                        nodeCount: data[0].nodeCount,
                        pageTitle: data[0].pageTitle,
                        sessionId: data[0].sessionId,
                        channel1: data[0].channel1,
                        channel2: data[0].channel2,
                        channel3: data[0].channel3,
                        channel4: data[0].channel4,
                        channel5: data[0].channel5,
                        channel6: data[0].channel6,
                        channel7: data[0].channel7
                    }
                });
                return;
            }
        } catch (error) {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        }
    }

    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const baseURL = "https://m.string-db.org"
    let script_name = window.location.href.replace("https://m.string-db.org", "")
    let loginUrl = `https://string-db.eu.auth0.com/authorize?client_id=${auth0ClientId}&response_type=code&redirect_uri=${baseURL}/cgi/login%3Fscript_name%3D${script_name}`

    let displayName = document.cookie.split(';').find((cookie) => cookie.includes('string_db_display_name.12.0'))?.split('=')[1];

    if (openIdAuthentifiedId === undefined) {
        return (
            <div className="menu_content">
                <div className="edge_page_wrap" style={{padding: "5vh 3vw 10vh 3vw", textAlign: "center"}}>
                    <h1 style={{paddingBottom: "10vh", textTransform: "none"}}>You are not logged in</h1>
                    <p>Please log in to view your browsing history</p>
                    <button type="submit" className="button large_submit_button"
                            onClick={() => window.location.href = loginUrl}>
                        &nbsp;Login&nbsp;</button>
                </div>
            </div>
        );
    }

    return (
        <div className="menu_content">
            <div className="edge_page_wrap">
                <h1>MY HISTORY</h1>
                <div className="confidence_score">
                    Welcome back {displayName}
                </div>
                <div className="interaction_title"> Your browsing history</div>
                <div className="interaction_table">
                    {data.map((entry, index, array) => {
                        return (
                            <div key={index}>
                                <div key={index} className="interaction_column_title">
                                    {entry.pageTitle}
                                </div>
                                <div style={index === array.length - 1 ? {display: "flex"} : {
                                    display: "flex",
                                    borderBottom: "1px solid #A0A0A0"
                                }}>
                                    <div className="interaction_column_data" style={{width: "75%"}}>
                                        <span className="enrichment_table_small_title">db version: </span>
                                        {entry.databaseVersionToShow}
                                        <br/>
                                        <span className="enrichment_table_small_title">last visited: </span>
                                        {entry.dateVisited} </div>
                                    <div className="interaction_column_data" style={{width: "25%"}}>
                                        <button className={"show_button"}
                                                onClick={() => navigate_to_page(entry.persistentUrl)}> show
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default MyHistory;