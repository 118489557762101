import React, {useContext, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import HomePage from './pages/HomePage';
import SearchSingleProtein from './pages/SearchSingleProtein';
import NetworkPage from './pages/NetworkPage';
import SearchMenu from './pages/SearchMenu';
import InteractionPage from './pages/InteractionPage';
import NodePage from './pages/NodePage';

import NavBar from './components/NavBar';
import TopBar from './components/TopBar';
import PageFooter from './components/PageFooter';
import ScrollToTop from './components/ScrollToTop';
import SearchMultipleProteins from './pages/SearchMultipleProteins';
import GetTaskDataFromSTRING from './pages/GetTaskDataFromSTRING';
import OrganismPage from './pages/OrganismPage';
import SearchOrganism from './pages/SearchOrganism';
import SearchTerm from './pages/SearchTerm';
import SearchTermDisambiguation from './pages/SearchTermDisambiguation';
import ErrorMessage from './components/ErrorMessage';
import SearchProteinSequences from './pages/SearchProteinSequences';
import MyHistory from './pages/MyHistory';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import NetworkPageIndexed from "./pages/NetworkPageIndexed";
import ScrollRestorationComponent from "./components/ScrollRestorationComponent";
import GlobalProvider, {GlobalContext} from './components/GlobalProvider';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import NotFoundPage from "./pages/NotFoundPage";


const ErrorFallback: React.FC<FallbackProps> = ({error, resetErrorBoundary}) => {
    let navigate = useNavigate();


    if (error.message === 'Failed to fetch') {
        error.title = 'Communication Error';
        error.message = "Error: server communication failure - if the problem persists, please contact the STRING maintainers."
    }
    // else{
    //     error.title = 'Error';
    //     error.message = 'Something went wrong! -- please contact STRING maintainers if the issue persists.';
    // }

    useEffect(() => {
        let errorData = {};
        // Reset the error boundary before navigation
        resetErrorBoundary();
        // Navigate to the desired path
        navigate('/error', {state: errorData});
        return;
    }, [navigate, resetErrorBoundary]);
    return null;
};

const App: React.FC = () => {

    const UrlSTRING = useContext(GlobalContext);

    // Function to log errors to a service
    async function logErrorToService(error: Error, errorInfo: React.ErrorInfo) {
        let errorMessage = error.message;

        let url = window.location.href;
        let stackTrace = error.stack || "No stack trace available";
        let componentStack = errorInfo?.componentStack || "No component stack available";

        let ipAddress = "Unknown IP"; // Default if request fails
        try {
            const response = await fetch("https://api64.ipify.org?format=json");
            const data = await response.json();
            ipAddress = data.ip; // Extract IP from response
        } catch (ipError) {
            console.warn("Failed to retrieve IP address:", ipError);
        }

        let logurl = `${UrlSTRING}/cgi/api?apiOutputType=json&apiMethod=log_mobile_error&caller_identity=mobileapp`;

        try {
            await fetch(
                `${logurl}&mobile_error_message=${encodeURIComponent(
                    `${errorMessage}\n\nURL: ${url}\n\nIP Address: ${ipAddress}\n\nStack Trace:\n${stackTrace}\n\nComponent Stack:\n${componentStack}`
                )}`
            );
        } catch (fetchError) {
            console.error("Error logging to service:", fetchError);
        }
    }


    // prevent zooming on touch devices
    const preventDefault = (e: TouchEvent) => {
        if (e.touches.length > 1) {
            e.preventDefault();
        }
    };
    useEffect(() => {
        window.addEventListener('touchstart', preventDefault, {passive: false});
        window.addEventListener('touchmove', preventDefault, {passive: false});
        window.addEventListener('touchend', preventDefault, {passive: false});

        return () => {
            window.removeEventListener('touchstart', preventDefault);
            window.removeEventListener('touchmove', preventDefault);
            window.removeEventListener('touchend', preventDefault);
        };
    }, []);

    return (
        <GlobalProvider>
            <div className="App" spellCheck={false}>
                <div className="body">
                    <ScrollRestorationComponent>
                        <Router>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onError={logErrorToService}
                            >
                                <TopBar/>
                                <NavBar/>

                                <div id="page-body">
                                    <Routes>
                                        <Route path="/" element={<HomePage/>}/>
                                        <Route path="/SearchMenu" element={<SearchMenu/>}/>
                                        <Route path="/singleProtein" element={<SearchSingleProtein/>}/>
                                        <Route path="/multipleProteins" element={<SearchMultipleProteins/>}/>
                                        <Route path="/SearchOrganism" element={<SearchOrganism/>}/>
                                        <Route path="/SearchTerm" element={<SearchTerm/>}/>
                                        <Route path="/proteinSequences" element={<SearchProteinSequences/>}/>
                                        <Route path="/cgi/network/:taskId/:sessionId/" element={<NetworkPage/>}/>
                                        <Route path="/interaction/:identifier1/:identifier2"
                                               element={<InteractionPage/>}/>
                                        <Route path="/node/:species/:identifier" element={<NodePage/>}/>
                                        <Route path="/GetTaskDataFromSTRING/:taskId/:sessionId"
                                               element={<GetTaskDataFromSTRING/>}/>
                                        <Route path="/organism/:organism" element={<OrganismPage/>}/>
                                        <Route path="/SearchTermDisambiguation/:species_text_term/:network_term_text"
                                               element={<SearchTermDisambiguation/>}/>
                                        <Route path="/error" element={<ErrorMessage/>}/>
                                        <Route path="/my" element={<MyHistory/>}/>
                                        <Route path="/cgi/login" element={<LoginPage/>}/>
                                        <Route path="/cgi/logout" element={<LogoutPage/>}/>
                                        <Route path="/network/:species?/:identifier" element={<NetworkPageIndexed/>}/>
                                        <Route path="*" element={<NotFoundPage/>}/>
                                    </Routes>
                                </div>
                                <ScrollToTop/>
                                <PageFooter/>
                            </ErrorBoundary>

                        </Router>
                    </ScrollRestorationComponent>
                </div>
            </div>
        </GlobalProvider>
    );
};

export default App;
