import React, { createContext, ReactNode, useState, FC } from 'react';

// Define the context value type
interface GlobalContextType {
    UrlSTRING: string;
    setUrlSTRING: React.Dispatch<React.SetStateAction<string>>;
}

// Provide a default value for the context
const defaultValue: GlobalContextType = {
    UrlSTRING: 'https://string-db.org',
    setUrlSTRING: () => {},
};

export const GlobalContext = createContext<GlobalContextType>(defaultValue);

interface ScrollRestorationComponentProps {
    children: ReactNode;
}

const GlobalProvider: FC<ScrollRestorationComponentProps> = ({ children }) => {
    const [UrlSTRING, setUrlSTRING] = useState('https://string-db.org');

    return (
        <GlobalContext.Provider value={{ UrlSTRING, setUrlSTRING }}>
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;
