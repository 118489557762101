import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import updateInput from "../utils/update_input";
import {useErrorBoundary} from "react-error-boundary";

interface SettingsParams {
    networkType: string,
    requiredScore: string,
    customScore: string,
    limit: string,
    customLimit: string,
    additionalNetworkNodes: string,
    customAdditionalNetworkNodes: string,
    networkFlavor: string,
    channel1: string,
    channel2: string,
    channel3: string,
    channel4: string,
    channel5: string,
    channel6: string,
    channel7: string,
    // displayMode: string,
    // colorblindEdges: string,
    bubbleNodeDesign: string,
    blockStructures: string,
    centerNodeLabels: string,
    showQueryNodeLabels: string,
    hideSingletons: string,
    hideLabels: string,
    dataSettingsLabel: string
}

const Settings = ({
                      taskId,
                      sessionId,
                      species,
                      identifier,
                      networkType,
                      requiredScore,
                      limit,
                      networkFlavor,
                      additionalNetworkNodes,
                      channel1,
                      channel2,
                      channel3,
                      channel4,
                      channel5,
                      channel6,
                      channel7,
                      // displayMode,
                      // colorblindEdges,
                      bubbleNodeDesign,
                      blockStructures,
                      centerNodeLabels,
                      showQueryNodeLabels,
                      hideSingletons,
                      hideLabels,
                      dataSettingsLabel,
                  }: {
    taskId: string, sessionId: string, species: string, identifier: string, networkType: string,
    requiredScore: string, limit: string, networkFlavor: string, additionalNetworkNodes: string, channel1: string,
    channel2: string, channel3: string, channel4: string, channel5: string, channel6: string, channel7: string,
    bubbleNodeDesign: string, blockStructures: string,
    centerNodeLabels: string, showQueryNodeLabels: string, hideSingletons: string, hideLabels: string,
    dataSettingsLabel: string //displayMode: string,
}) => {

    const [settings, setSettings] = useState<SettingsParams>()

    const [requiredScoreValue, setRequiredScoreValue] = useState<string>("900")
    const [customScoreValue, setCustomScoreValue] = useState<string>("")
    const [customScoreDisplay, setcustomScoreDisplay] = useState<string>("none")

    const [limitValue, setLimitValue] = useState<string>("10")
    const [customLimitValue, setCustomLimitValue] = useState<string>("")
    const [customLimitDisplay, setCustomLimitDisplay] = useState<string>("none")

    const [additionalNetworkNodesValue, setAdditionalNetworkNodesValue] = useState<string>("0")
    const [customAdditionalNetworkNodesValue, setCustomAdditionalNetworkNodesValue] = useState<string>("")
    const [customAdditionalNetworkNodesDisplay, setCustomAdditionalNetworkNodesDisplay] = useState<string>("none")

    const {showBoundary} = useErrorBoundary();

    useEffect(() => {

        // set the settings object parameters
        setSettings({} as SettingsParams)
        let newesttings: SettingsParams = {} as SettingsParams;
        newesttings.networkType = networkType ? networkType : "functional";

        if (!["900", "700", "400", "150"].includes(requiredScore)) {
            setcustomScoreDisplay("inline")
            newesttings.requiredScore = "901"
        } else {
            newesttings.requiredScore = requiredScore ?? "400";
        }

        newesttings.customScore = `${(parseFloat(requiredScore) / 1000).toFixed(3)}`
        setRequiredScoreValue(newesttings.requiredScore)
        setCustomScoreValue(newesttings.customScore)
        // set the setting value because it wouldn't update automatically
        let scoreSelect = document.getElementById("score_select") as HTMLSelectElement
        if (scoreSelect !== null) {
            scoreSelect.value = newesttings.requiredScore
        }
        let customScoreDiv = document.getElementById("custom_score_div") as HTMLInputElement
        let customScoreInput = document.getElementById("custom_score_input") as HTMLInputElement
        if (customScoreInput !== null) {
            if (newesttings.requiredScore === "901") {
                customScoreInput.value = newesttings.customScore
                customScoreDiv.style.display = "inline"
            } else {
                customScoreDiv.style.display = "none"
            }
        }

        if (!["0", "5", "10", "20", "50"].includes(limit)) {
            setCustomLimitDisplay("inline")
            newesttings.limit = "51"
        } else {
            newesttings.limit = limit ?? "10";
        }
        newesttings.customLimit = limit;
        setLimitValue(newesttings.limit)
        setCustomLimitValue(newesttings.customLimit)
        // set the setting value because it wouldn't update automatically
        let limitSelect = document.getElementById("limit_select") as HTMLSelectElement
        if (limitSelect !== null) {
            limitSelect.value = newesttings.limit
        }
        let customLimitDiv = document.getElementById("custom_limit_div") as HTMLInputElement
        let customLimitInput = document.getElementById("custom_limit_input") as HTMLInputElement
        if (customLimitInput !== null) {
            if (newesttings.limit === "51") {
                customLimitInput.value = newesttings.customLimit
                customLimitDiv.style.display = "inline"
            } else {
                customLimitDiv.style.display = "none"
            }
        }

        if (!["0", "5", "10", "20", "50"].includes(additionalNetworkNodes)) {
            setCustomAdditionalNetworkNodesDisplay("inline")
            newesttings.additionalNetworkNodes = "51"
        } else {
            newesttings.additionalNetworkNodes = additionalNetworkNodes ?? "0";
        }
        newesttings.customAdditionalNetworkNodes = additionalNetworkNodes;
        setAdditionalNetworkNodesValue(newesttings.additionalNetworkNodes)
        setCustomAdditionalNetworkNodesValue(newesttings.customAdditionalNetworkNodes)
        // set the setting value because it wouldn't update automatically
        let additionalNetworkNodesSelect = document.getElementById("secondshelllimit_select") as HTMLSelectElement
        if (additionalNetworkNodesSelect !== null) {
            additionalNetworkNodesSelect.value = newesttings.additionalNetworkNodes
        }
        let customAdditionalNetworkNodesDiv = document.getElementById("custom_additional_network_nodes_div") as HTMLInputElement
        let customAdditionalNetworkNodesInput = document.getElementById("custom_additional_network_nodes_input") as HTMLInputElement
        if (customAdditionalNetworkNodesInput !== null) {
            if (newesttings.additionalNetworkNodes === "51") {
                customAdditionalNetworkNodesInput.value = newesttings.customAdditionalNetworkNodes
                customAdditionalNetworkNodesDiv.style.display = "inline"
            } else {
                customAdditionalNetworkNodesDiv.style.display = "none"
            }
        }

        // set the channel parameters
        if (newesttings.networkType === "physical") {
            newesttings.channel1 = channel1 ? channel1 : "off";
            newesttings.channel2 = channel2 ? channel2 : "off";
            newesttings.channel3 = channel3 ? channel3 : "off";
            newesttings.channel4 = channel4 ? channel4 : "off";
        } else {
            newesttings.channel1 = channel1 ? channel1 : "on";
            newesttings.channel2 = channel2 ? channel2 : "on";
            newesttings.channel3 = channel3 ? channel3 : "on";
            newesttings.channel4 = channel4 ? channel4 : "on";
        }
        newesttings.channel5 = channel5 ? channel5 : "on";
        newesttings.channel6 = channel6 ? channel6 : "on";
        newesttings.channel7 = channel7 ? channel7 : "on";

        newesttings.networkFlavor = networkFlavor ? networkFlavor : "evidence";
        // settings.displayMode = displayMode ? displayMode : "svg";
        // newesttings.colorblindEdges = colorblindEdges ? colorblindEdges : "off";
        newesttings.bubbleNodeDesign = bubbleNodeDesign ? bubbleNodeDesign : "off";
        newesttings.blockStructures = blockStructures ? blockStructures : "off";
        newesttings.centerNodeLabels = centerNodeLabels ? centerNodeLabels : "off";
        newesttings.showQueryNodeLabels = showQueryNodeLabels ? showQueryNodeLabels : "off";
        newesttings.hideSingletons = hideSingletons ? hideSingletons : "off";
        newesttings.hideLabels = hideLabels ? hideLabels : "off";
        newesttings.dataSettingsLabel = dataSettingsLabel ? dataSettingsLabel : "12";

        setSettings({...newesttings});

        // manually change parameter selection
        let evidenceInput = document.getElementById("evidence") as HTMLInputElement;
        let confidenceInput = document.getElementById("confidence") as HTMLInputElement;
        let blockStructuresInput = document.getElementById("blockStructures") as HTMLInputElement;

        // Set the checked state of the inputs
        if (evidenceInput && confidenceInput) {
            if (newesttings.networkFlavor === "confidence"){
                evidenceInput.checked = false; // Unselect "evidence"
                confidenceInput.checked = true; // Select "confidence"
            } else {
                evidenceInput.checked = true; // select "evidence"
                confidenceInput.checked = false; // Unselect "confidence"
            }
        }
        if (blockStructuresInput){
            blockStructuresInput.checked = newesttings.blockStructures === "on";
        }

    }, [taskId, sessionId, networkType, requiredScore, limit, networkFlavor, additionalNetworkNodes, channel1,
        channel2, channel3, channel4, channel5, channel6, channel7, bubbleNodeDesign,
        blockStructures, centerNodeLabels, showQueryNodeLabels, hideSingletons, hideLabels, dataSettingsLabel]) //displayMode,

    let displayPhysical;
    let nextLineDisplay;
    if (networkType === 'physical') {
        displayPhysical = "none"
        nextLineDisplay = "none"
    } else {
        displayPhysical = ""
        nextLineDisplay = "table-row"
    }

    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        // replace svg with a spinner
        let networkImage = document.getElementById('stringEmbedded');
        if (networkImage) {
            networkImage.innerHTML = `
                        <div style='display: flex; justify-content: center; align-items: center' class='oversized_input_wait'>
                            <div class='spinner'></div>
                        </div>
                    `;
        }

        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        // check customScore if selected, get the value from the input field
        let newRequiredScore = event.currentTarget.requiredScore.value
        let customScore = event.currentTarget.custom_score.value

        if (newRequiredScore === "901") {
            if (customScore === "") {
                newRequiredScore = "900"
            } else {
                newRequiredScore = parseFloat(customScore) * 1000
            }
        }

        let newLimit = event.currentTarget.limit.value
        let customLimit = event.currentTarget.custom_limit.value

        if (newLimit === "51") {
            if (customLimit === "") {
                newLimit = "10"
            } else {
                newLimit = customLimit
            }
        }

        let newAdditionalNetworkNodes = event.currentTarget.additionalNetworkNodes.value
        let customAdditionalNetworkNodes = event.currentTarget.custom_additional_network_nodes.value

        if (newAdditionalNetworkNodes === "51") {
            if (customAdditionalNetworkNodes === "") {
                newAdditionalNetworkNodes = "0"
            } else {
                newAdditionalNetworkNodes = customAdditionalNetworkNodes
            }
        }

        const additionalData = {
            // displayMode: event.currentTarget.displayMode.value,
            // colorblindEdges: event.currentTarget.colorblindEdges.checked ? "on" : "off",
            bubbleNodeDesign: event.currentTarget.bubbleNodeDesign.checked ? "on" : "off",
            blockStructures: event.currentTarget.blockStructures.checked ? "on" : "off",
            centerNodeLabels: event.currentTarget.centerNodeLabels.checked ? "on" : "off",
            showQueryNodeLabels: event.currentTarget.showQueryNodeLabels.checked ? "on" : "off",
            hideSingletons: event.currentTarget.hideSingletons.checked ? "on" : "off",
            hideLabels: event.currentTarget.hideLabels.checked ? "on" : "off",
            dataSettingsLabel: event.currentTarget.dataSettingsLabel.value
        };

        try {
            const result = await updateInput(
                "SingleProtein", species, identifier.replaceAll('\r', '%0d'),
                newRequiredScore, event.currentTarget.networkType.value, event.currentTarget.networkFlavor.value,
                newLimit, newAdditionalNetworkNodes, event.currentTarget.channel1.checked ? "on" : "off",
                event.currentTarget.channel2.checked ? "on" : "off",
                event.currentTarget.channel3.checked ? "on" : "off",
                event.currentTarget.channel4.checked ? "on" : "off",
                event.currentTarget.channel5.checked ? "on" : "off",
                event.currentTarget.channel6.checked ? "on" : "off",
                event.currentTarget.channel7.checked ? "on" : "off",
                "", sessionId);

            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                submit_button.disabled = false;
                submit_button.classList.remove("large_submit_button_active");
                return;
            }


            if (result) {
                navigate(result.path, {
                    state: {
                        ...result.newData,  // Spread existing data
                        ...additionalData   // Add new fields
                    }
                });
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Optional: animated scrolling
                });
                submit_button.disabled = false;
                submit_button.classList.remove("large_submit_button_active");
                return;

            }
        } catch (error) {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        }
        submit_button.disabled = false;
        submit_button.classList.remove("large_submit_button_active");
    }

    const checkCustomScore = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "901") {
            document.getElementById("custom_score_div")!.style.display = "inline"
            let customScoreInput = document.getElementById("custom_score_input") as HTMLInputElement
            // set the custom score value to the required score value
            customScoreInput!.value = (parseFloat(requiredScoreValue) / 1000).toFixed(3)
            setCustomScoreValue(requiredScoreValue)
        } else {
            document.getElementById("custom_score_div")!.style.display = "none"
        }
        setRequiredScoreValue(event.target.value)
    }

    const checkCustomLimit = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "51") {
            document.getElementById("custom_limit_div")!.style.display = "inline"
            let customLimitInput = document.getElementById("custom_limit_input") as HTMLInputElement
            // set the custom limit value to the required limit value
            customLimitInput!.value = limitValue
            setCustomLimitValue(limitValue)
        } else {
            document.getElementById("custom_limit_div")!.style.display = "none"
        }
        setLimitValue(event.target.value)
    }

    const checkCustomAdditionalNetworkNodes = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "51") {
            document.getElementById("custom_additional_network_nodes_div")!.style.display = "inline"
            let customAdditionalNetworkNodesInput = document.getElementById("custom_additional_network_nodes_input") as HTMLInputElement
            // set the custom limit value to the required limit value
            customAdditionalNetworkNodesInput!.value = additionalNetworkNodesValue
            setCustomAdditionalNetworkNodesValue(additionalNetworkNodesValue)
        } else {
            document.getElementById("custom_additional_network_nodes_div")!.style.display = "none"
        }
        setAdditionalNetworkNodesValue(event.target.value)
    }

    return (
        <>
            {settings ? (
                <div className="main_page_content_container" id="bottom_page_selector_settings_container"
                     style={localStorage.getItem('activeView') === "bottom_page_selector_settings" ? {display: "block"} : {display: "none"}}>
                    <div className="settings_wrap"
                         style={{
                             display: 'inline-block',
                             paddingRight: "2%",
                             paddingLeft: "2%",
                             width: "100%"
                         }}>
                        <div className="settings_box_wrap">
                            <div className="error_info_wrap data_settings_wrap">
                                <div className="error_info_box">
                                    <div className="box_body data_settings_body">
                                        <form onSubmit={handleSubmit} id="standard_parameters" method="post"
                                              style={{padding: "2%"}}>
                                            <div style={{textAlign: "center", paddingBottom: "3vw"}}>
                                                <button type="submit" className="button large_submit_button">
                                                    update
                                                </button>
                                            </div>
                                            <div style={{
                                                display: 'table',
                                                borderCollapse: 'separate',
                                                borderSpacing: '10px',
                                                width: "100%"
                                            }}>
                                                <div style={{
                                                    display: 'table-row',
                                                    fontWeight: "bold",
                                                    fontSize: "1.2em"
                                                }}>
                                                    Basic Settings
                                                </div>

                                                {/*------------------------------------------------------------------*/}

                                                <div className="view_settings_section_headline">
                                                    Network type:
                                                </div>
                                                <div style={{
                                                    display: 'table',
                                                    borderCollapse: 'separate',
                                                    borderSpacing: '0px'
                                                }}>
                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="functional" name="networkType" type="radio"
                                                                   value="functional"
                                                                   defaultChecked={settings && settings.networkType === "functional"}/>
                                                            <label htmlFor="functional">full network</label>
                                                        </div>
                                                        <div className="view_settings_checkbox_wrap">
                                                            (the edges indicate both functional and physical
                                                            protein associations)
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="physical" name="networkType" type="radio"
                                                                   value="physical"
                                                                   defaultChecked={settings && settings.networkType === "physical"}
                                                            />
                                                            <label htmlFor="physical">physical subnetwork</label>
                                                        </div>
                                                        <div className="view_settings_checkbox_wrap">
                                                            (the edges indicate that the proteins are part of
                                                            a physical complex)
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*------------------------------------------------------------------*/}
                                                <div className="view_settings_separator"></div>

                                                <div className="view_settings_section_headline">
                                                    Meaning of network edges:
                                                </div>
                                                <div style={{
                                                    display: 'table',
                                                    borderCollapse: 'separate',
                                                    borderSpacing: '0px'
                                                }}>
                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="evidence" name="networkFlavor" type="radio"
                                                                   value="evidence"
                                                                   defaultChecked={settings && settings.networkFlavor === "evidence"}
                                                            />
                                                            <label htmlFor="evidence">evidence</label>
                                                        </div>
                                                        <div className="view_settings_checkbox_wrap">
                                                            (<span
                                                            className="evidence_links_icon"></span>line
                                                            color indicates the type of interaction evidence )
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="confidence" name="networkFlavor"
                                                                   type="radio" value="confidence"
                                                                   defaultChecked={settings && settings.networkFlavor === "confidence"}
                                                            />
                                                            <label htmlFor="confidence">confidence</label>
                                                        </div>
                                                        <div className="view_settings_checkbox_wrap">
                                                            (<span
                                                            className="confidence_links_icon"></span>line
                                                            thickness indicates the strength of data support )
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*------------------------------------------------------------------*/}
                                                <div className="view_settings_separator"></div>

                                                <div className="data_settings_section_headline">
                                                    Active interaction sources:
                                                </div>
                                                <div style={{
                                                    display: 'table',
                                                    borderCollapse: 'separate',
                                                    borderSpacing: '0px'
                                                }}>
                                                    <div style={{display: 'table-row'}}>
                                                        <div className="data_settings_checkbox_wrap">
                                                            <input id="channel7" name="channel7" type="checkbox"
                                                                   defaultChecked={settings && settings.channel7 === "on"}
                                                            />
                                                            <label htmlFor="channel7">Textmining</label>
                                                        </div>
                                                        <div className="data_settings_checkbox_wrap">
                                                            <input id="channel5" name="channel5" type="checkbox"
                                                                   defaultChecked={settings && settings.channel5 === "on"}
                                                            />
                                                            <label htmlFor="channel5">Experiments</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="data_settings_checkbox_wrap">
                                                            <input id="channel6" name="channel6" type="checkbox"
                                                                   defaultChecked={settings && settings.channel6 === "on"}
                                                            />
                                                            <label htmlFor="channel6">Databases</label>
                                                        </div>
                                                        <div className="data_settings_checkbox_wrap"
                                                             style={{display: displayPhysical}}>
                                                            <input id="channel4" name="channel4" type="checkbox"
                                                                   defaultChecked={settings && settings.channel4 === "on"}
                                                            />
                                                            <label htmlFor="channel4">Co‑expression</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: nextLineDisplay}}>
                                                        <div className="data_settings_checkbox_wrap">
                                                            <input id="channel1" name="channel1" type="checkbox"
                                                                   defaultChecked={settings && settings.channel1 === "on"}
                                                            />
                                                            <label htmlFor="channel1">Neighborhood</label>
                                                        </div>
                                                        <div className="data_settings_checkbox_wrap">
                                                            <input id="channel2" name="channel2" type="checkbox"
                                                                   defaultChecked={settings && settings.channel2 === "on"}
                                                            />
                                                            <label htmlFor="channel2">Gene Fusion</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: nextLineDisplay}}>
                                                        <div className="data_settings_checkbox_wrap">
                                                            <input id="channel3" name="channel3" type="checkbox"
                                                                   defaultChecked={settings && settings.channel3 === "on"}
                                                            />
                                                            <label htmlFor="channel3">Co‑occurrence</label>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/*------------------------------------------------------------------*/}
                                                <div className="data_settings_separator"></div>

                                                <div className="data_settings_section_headline">
                                                    Minimum required interaction score:
                                                </div>
                                                <div style={{
                                                    display: 'table',
                                                    borderCollapse: 'separate',
                                                    borderSpacing: '0px',
                                                    width: '100%'
                                                }}>
                                                    <div style={{display: 'table-row'}}>
                                                        <div className="data_settings_select_wrap">
                                                            <select defaultValue={requiredScore}
                                                                    name="requiredScore"
                                                                    id="score_select"
                                                                    className="data_settings_select"
                                                                    autoComplete="off"
                                                                    onChange={checkCustomScore}
                                                            >
                                                                <option value="900">highest confidence (0.900)</option>
                                                                <option value="700">high confidence (0.700)</option>
                                                                <option value="400">medium confidence (0.400)</option>
                                                                <option value="150">low confidence (0.150)</option>
                                                                <option value="901">custom value</option>
                                                            </select>
                                                        </div>
                                                        <div className="data_settings_select_wrap" id="custom_score_div"
                                                             style={{display: customScoreDisplay}}>
                                                            <input
                                                                style={{borderRadius: "0"}}
                                                                className="data_settings_custom"
                                                                id="custom_score_input"
                                                                name="custom_score"
                                                                defaultValue={customScoreValue}
                                                                maxLength={5}
                                                                onChange={(e) => setCustomScoreValue(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*------------------------------------------------------------------*/}
                                                <div className="data_settings_separator"></div>

                                                <div className="data_settings_section_headline">
                                                    Max number of interactors to show:
                                                </div>
                                                <div style={{
                                                    display: 'table',
                                                    borderCollapse: 'separate',
                                                    borderSpacing: '0px'
                                                }}>
                                                    <div style={{display: 'table-row'}}>
                                                        <div id="limit_label" style={{
                                                            display: 'table-cell',
                                                            textAlign: 'right',
                                                            verticalAlign: 'middle'
                                                        }} className="data_settings_label">
                                                            1st&nbsp;shell:&nbsp;&nbsp;
                                                        </div>
                                                        <div style={{display: 'table-cell', paddingRight: '10px'}}>
                                                            <select defaultValue={limitValue}
                                                                    name="limit" id="limit_select"
                                                                    className="data_settings_select"
                                                                    autoComplete="off"
                                                                    onChange={checkCustomLimit}
                                                            >
                                                                <option value="0">- none / query proteins only -
                                                                </option>
                                                                <option value="5">no more than 5 interactors
                                                                </option>
                                                                <option value="10">no more than 10 interactors
                                                                </option>
                                                                <option value="20">no more than 20 interactors
                                                                </option>
                                                                <option value="50">no more than 50 interactors
                                                                </option>
                                                                <option value="51">custom value
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="data_settings_select_wrap" id="custom_limit_div"
                                                             style={{display: customLimitDisplay}}>
                                                            <input
                                                                style={{borderRadius: "0"}}
                                                                className="data_settings_custom"
                                                                id="custom_limit_input"
                                                                name="custom_limit"
                                                                defaultValue={customLimitValue}
                                                                maxLength={5}
                                                                onChange={(e) => setCustomLimitValue(e.target.value)}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div style={{
                                                        display: 'table-row',
                                                        borderCollapse: 'separate',
                                                        borderSpacing: '0px'
                                                    }}>
                                                        <div id="secondshelllimit_label" style={{
                                                            display: 'table-cell',
                                                            textAlign: 'right',
                                                            verticalAlign: 'middle'
                                                        }} className="data_settings_label">
                                                            2nd&nbsp;shell:&nbsp;&nbsp;
                                                        </div>
                                                        <div style={{display: 'table-cell', paddingRight: '10px'}}>
                                                            <select
                                                                defaultValue={additionalNetworkNodesValue}
                                                                name="additionalNetworkNodes"
                                                                id="secondshelllimit_select"
                                                                className="data_settings_select"
                                                                onChange={checkCustomAdditionalNetworkNodes}
                                                                autoComplete="off">
                                                                <option value="0">- none -</option>
                                                                <option value="5">no more than 5 interactors
                                                                </option>
                                                                <option value="10">no more than 10 interactors
                                                                </option>
                                                                <option value="20">no more than 20 interactors
                                                                </option>
                                                                <option value="50">no more than 50
                                                                    interactors&nbsp;&nbsp;
                                                                </option>
                                                                <option value="51">custom value
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="data_settings_select_wrap"
                                                             id="custom_additional_network_nodes_div"
                                                             style={{display: customAdditionalNetworkNodesDisplay}}>
                                                            <input
                                                                style={{borderRadius: "0"}}
                                                                className="data_settings_custom"
                                                                id="custom_additional_network_nodes_input"
                                                                name="custom_additional_network_nodes"
                                                                defaultValue={customAdditionalNetworkNodesValue}
                                                                maxLength={5}
                                                                onChange={(e) => setCustomAdditionalNetworkNodesValue(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*------------------------------------------------------------------*/}
                                                <div className="data_settings_separator"></div>


                                                <div style={{
                                                    display: 'table-row',
                                                    fontWeight: "bold",
                                                    fontSize: "1.2em"
                                                }}>
                                                    Advanced Settings
                                                </div>

                                                {/*<div className="view_settings_section_headline">*/}
                                                {/*    Network display mode:*/}
                                                {/*</div>*/}

                                                {/*/!*------------------------------------------------------------------*!/*/}

                                                {/*<div style={{*/}
                                                {/*    display: 'table',*/}
                                                {/*    borderCollapse: 'separate',*/}
                                                {/*    borderSpacing: '0px'*/}
                                                {/*}}>*/}
                                                {/*    <div style={{display: 'table-row'}}>*/}
                                                {/*        <div className="view_settings_checkbox_wrap">*/}
                                                {/*            <input id="static" name="displayMode" type="radio"*/}
                                                {/*                   value="static"*/}
                                                {/*                   defaultChecked={settings && settings.displayMode === "static"}/>*/}
                                                {/*            <label htmlFor="static">static png</label>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="view_settings_checkbox_wrap">*/}
                                                {/*            ( network is a simple bitmap image; not interactive )*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div style={{display: 'table-row'}}>*/}
                                                {/*        <div className="view_settings_checkbox_wrap">*/}
                                                {/*            <input id="svg" name="displayMode" type="radio"*/}
                                                {/*                   value="svg"*/}
                                                {/*                   defaultChecked={settings && settings.displayMode === "svg"}*/}
                                                {/*            />*/}
                                                {/*            <label htmlFor="svg">interactive svg</label>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="view_settings_checkbox_wrap">*/}
                                                {/*            ( network is a scalable vector graphic [SVG]; interactive )*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                {/*/!*------------------------------------------------------------------*!/*/}
                                                {/*<div className="data_settings_separator"></div>*/}

                                                <div className="view_settings_section_headline">
                                                    Network display options:

                                                </div>

                                                {/*------------------------------------------------------------------*/}

                                                <div style={{
                                                    display: 'table',
                                                    borderCollapse: 'separate',
                                                    borderSpacing: '0px'
                                                }}>
                                                    {/*<div style={{display: 'table-row'}}>*/}
                                                    {/*    <div className="view_settings_checkbox_wrap">*/}
                                                    {/*        <input id="colorblind" name="colorblindEdges"*/}
                                                    {/*               type="checkbox"*/}
                                                    {/*               value="colorblind"*/}
                                                    {/*               defaultChecked={settings && settings.colorblindEdges === 'on'}/>*/}
                                                    {/*        <label htmlFor="colorblind">colorblind-friendly*/}
                                                    {/*            edges</label>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="bubbleNodeDesign" name="bubbleNodeDesign"
                                                                   type="checkbox"
                                                                   value="bubbleNodeDesign"
                                                                   defaultChecked={settings && settings.bubbleNodeDesign === 'on'}/>
                                                            <label htmlFor="bubbleNodeDesign">enable 3D bubble
                                                                design</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="blockStructures" name="blockStructures"
                                                                   type="checkbox"
                                                                   value="blockStructures"
                                                                   defaultChecked={settings && settings.blockStructures === 'on'}/>
                                                            <label htmlFor="blockStructures">disable structure previews
                                                                inside network bubbles
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="centerNodeLabels" name="centerNodeLabels"
                                                                   type="checkbox"
                                                                   value="centerNodeLabels"
                                                                   defaultChecked={settings && settings.centerNodeLabels === 'on'}/>
                                                            <label htmlFor="centerNodeLabels">center protein names on
                                                                nodes</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="showQueryNodeLabels" name="showQueryNodeLabels"
                                                                   type="checkbox"
                                                                   value="showQueryNodeLabels"
                                                                   defaultChecked={settings && settings.showQueryNodeLabels === 'on'}/>
                                                            <label htmlFor="showQueryNodeLabels">show your query protein
                                                                names</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="hideSingletons" name="hideSingletons"
                                                                   type="checkbox"
                                                                   value="hideSingletons"
                                                                   defaultChecked={settings && settings.hideSingletons === 'on'}/>
                                                            <label htmlFor="hideSingletons">hide disconnected nodes in
                                                                the network</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input id="hideLabels" name="hideLabels"
                                                                   type="checkbox"
                                                                   value="hideLabels"
                                                                   defaultChecked={settings && settings.hideLabels === 'on'}/>
                                                            <label htmlFor="hideLabels">hide protein names</label>
                                                        </div>
                                                    </div>

                                                    <div style={{display: 'table-row'}}>
                                                        <div className="view_settings_checkbox_wrap">
                                                            <input
                                                                style={{
                                                                    borderRadius: "0", width: "10%", paddingRight: "0",
                                                                    marginRight: "3vw", marginLeft: "-3vw"
                                                                }}
                                                                type="number"
                                                                className="data_settings_custom"
                                                                id="dataSettingsLabel"
                                                                name="dataSettingsLabel"
                                                                defaultValue={12}
                                                                onInput={(e) => {
                                                                    const input = e.target as HTMLInputElement; // Type assertion
                                                                    if (parseInt(input.value, 10) > 99) {
                                                                        input.value = "99";
                                                                    }
                                                                    else if (parseInt(input.value, 10) < 1) {
                                                                        input.value = "1";
                                                                    }
                                                                    // if input is not a single number, replace with 12, except if its empty then its fine
                                                                    else if (isNaN(parseInt(input.value, 10)) && input.value !== "") {
                                                                        input.value = "12";
                                                                    }
                                                                }}
                                                            />
                                                            <label htmlFor="dataSettingsLabel">protein name font
                                                                size</label>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default Settings