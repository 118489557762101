import React from "react";
import {useLocation} from "react-router-dom";

interface ErrorObj {
    title: string;
    message: string;
}


const ErrorMessage = () => {
    const location = useLocation()
    let errorData: ErrorObj = location.state as ErrorObj
    if (errorData === undefined || errorData === null || Object.keys(errorData).length === 0) {
        errorData = {
            title: 'Error',
            message: 'Something went wrong! -- please contact STRING maintainers if the issue persists.'
        }
    }
    // find all tags in the message and replace them with new line
    errorData.message = errorData.message.replace(/<br\/>/g, "\n\n")
    errorData.message = errorData.message.replace(/<\/br>/g, "\n\n")
    errorData.message = errorData.message.replace(/<\/p>/g, "\n\n")
    errorData.message = errorData.message.replace(/<\/b>/g, "\n\n")
    errorData.message = errorData.message.replace(/<p>/g, "\n\n")
    errorData.message = errorData.message.replace(/<b>/g, "\n\n")
    errorData.message = errorData.message.replace(/\s+/g, ' ')
    errorData.message = errorData.message.replace(/&nbsp;/g, '')

    return (
        <div className="menu_content">
            <div className="search_input_wrap">
                <div className="search_input_box">

                    <div className="box_title">{errorData.title}</div>
                    <div className="box">
                        <div className="error_info_message" style={{paddingBottom: "25px"}}>
                            {errorData.message}
                        </div>
                        <button onClick={() => window.location.href = '/SearchMenu'}
                                className="button large_submit_button"
                                style={{width: "70%"}}>
                            Start Over
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorMessage;
