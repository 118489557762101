import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useErrorBoundary} from "react-error-boundary";

const LoginPage = () => {
    const [openIdAuthentifiedId, setOpenIdAuthentifiedId] = useState('');
    const [openIdProvider, setOpenIdProvider] = useState('');
    const [openIdDisplayName, setOpenIdDisplayName] = useState('');
    const [openIdGivenName, setOpenIdGivenName] = useState('');
    const [cookiesSet, setCookiesSet] = useState(false);
    const [scriptName, setScriptName] = useState('');

    const { showBoundary } = useErrorBoundary();
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            let pageUrl = window.location.href;
            pageUrl = pageUrl.replaceAll("%2F", "/").replaceAll("%3F", "?").replaceAll("%3D", "=").replaceAll("%26", "&").replaceAll("%3A", ":");
            // remove # from page url
            pageUrl = pageUrl.split("#")[0];
            let pageParts = pageUrl.split("?");
            let pageParams = pageParts[1].split("&");
            let scriptName = pageParams[0].split("=")[1];
            let code = pageParams[1].split("=")[1];

            const tokenUrl = "https://string-db.eu.auth0.com/oauth/token";
            const userInfoUrl = 'https://string-db.eu.auth0.com/userinfo';

            const tokenPayload = {
                client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
                redirect_uri: pageUrl,
                code: code,
                grant_type: 'authorization_code'
            }

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(tokenPayload) // Convert payload to JSON string
            };

            try {
                fetch(tokenUrl, requestOptions)
                    .then((response) => response.json())
                    .then((responseData) => {
                        const token2Playload = {
                            access_token: responseData['access_token']
                        }
                        const request2Options: RequestInit = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify(token2Playload) // Convert payload to JSON string
                        };

                        fetch(userInfoUrl, request2Options)
                            .then((response2) => response2.json())
                            .then((secondResponseData) => {
                                let userId = secondResponseData['user_id'];
                                let provider = 'generic';
                                if (userId.includes('|')) {
                                    provider = userId.split('|')[0];
                                }
                                let displayName = secondResponseData['name'] || '';
                                let givenName = secondResponseData['given_name'] || '';
                                if (givenName === '_unassigned' && secondResponseData['nickname']) {
                                    givenName = secondResponseData['nickname'];
                                }
                                if (displayName === '_unassigned') {
                                    displayName = givenName;
                                }
                                // Adjusting userId for legacy support
                                userId = userId.replace('google-oauth2|', 'https://www.google.com/profiles/');
                                userId = userId.replace('facebook|', 'http://www.facebook.com/profile.php?id=');

                                setScriptName(scriptName);
                                setOpenIdAuthentifiedId(userId);
                                setOpenIdProvider(provider);
                                setOpenIdDisplayName(displayName);
                                setOpenIdGivenName(givenName);

                                // next step is to set the cookies

                                let authenticatedUserCookieName = "m.string_login_credentials";
                                let authenticatedUserCookieValue = `${userId}; path=/; expires=Sun, 02-Oct-2033 16:37:39 GMT`;
                                document.cookie = authenticatedUserCookieName + "=" + authenticatedUserCookieValue;

                                // set display name cookie
                                let displayNameCookieName = "m.string_db_display_name.12.0";
                                let displayNameCookieValue = `${displayName}; path=/; expires=Sun, 02-Oct-2033 16:37:39 GMT`;
                                document.cookie = displayNameCookieName + "=" + displayNameCookieValue;
                                setCookiesSet(true);

                                // redirect to the previous page
                                window.location.href = `${scriptName}`;
                                // navigate(`${scriptName`);
                            });
                    });
            } catch (error) {
                setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
            }
        };
        fetchData();
    }, [navigate, showBoundary]);

    useEffect(() => {
        if (openIdAuthentifiedId !== '' && openIdProvider !== '' && openIdDisplayName !== '' && openIdGivenName !== '' && !cookiesSet) {

            let authenticatedUserCookieName = "m.string_login_credentials";
            let authenticatedUserCookieValue = `${openIdAuthentifiedId}; path=/; expires=Sun, 02-Oct-2033 16:37:39 GMT`;
            document.cookie = authenticatedUserCookieName + "=" + authenticatedUserCookieValue;

            // set display name cookie
            let displayNameCookieName = "m.string_db_display_name.12.0";
            let displayNameCookieValue = `${openIdDisplayName}; path=/; expires=Sun, 02-Oct-2033 16:37:39 GMT`;
            document.cookie = displayNameCookieName + "=" + displayNameCookieValue;
            setCookiesSet(true);

            // redirect to the previous page
            window.location.href = `${scriptName}`;
        }
    }, [openIdAuthentifiedId, openIdProvider, openIdDisplayName, openIdGivenName, cookiesSet, scriptName]);

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className='oversized_input_wait'>
            <div className="spinner"></div>
        </div>
    );
}

export default LoginPage;
