import React from "react";

const PageFooter = () => {
    return (
        <div className="main_footer">
            <div className="column" style={{justifyContent: "center"}}>
                <ul className="horizontal_list">
                    <li>&copy; STRING Consortium 2024</li>
                    <li id="SIB_logo" onClick={() => {
                        window.location.href = "https://www.isb-sib.ch/";
                    }}></li>
                    <li id="CPR_logo" onClick={() => {
                        window.location.href = "https://www.cpr.ku.dk/";
                    }}></li>
                    <li id="EMBL_logo" onClick={() => {
                        window.location.href = "https://www.embl.de/";
                    }}></li>
                </ul>
            </div>
        </div>);
}

export default PageFooter;