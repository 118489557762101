import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "./GlobalProvider";
import PollingMessage from "./PollingMessage";
import {useLocation} from "react-router-dom";

interface DataObj {
    categoryId: string;
    categoryFullName: string;
}


const EnrichmentFigure = ({taskId, sessionId}: { taskId: string, sessionId: string }) => {

    const [categories, setCategories] = useState<DataObj[]>([]);
    const [groupMergeSimilarity, setGroupMergeSimilarity] = useState<string | null>(null);
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [maxCount, setMaxCount] = useState<string | null>(null);
    const [colorPalette, setColorPalette] = useState<string | null>(null);
    const [error] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState<string | null>(null);
    const {UrlSTRING,} = useContext(GlobalContext);

    const location = useLocation()
    const {
        species,
        identifier,
        requiredScore,
        networkType,
        networkFlavor,
        limit,
        additionalNetworkNodes,
        channel1,
        channel2,
        channel3,
        channel4,
        channel5,
        channel6,
        channel7,
        dataAll
    } = location.state;

    useEffect(() => {
        const fetchEnrichmentCategories = async () => {
            var url;
            let data;
            if (taskId) {
                url = `${UrlSTRING}/cgi/api?apiMethod=enrichmentCategories&apiOutputType=json&taskId=${taskId}&sessionId=${sessionId}&caller_identity=mobileapp&addSignal=1`;
                const response = await fetch(url);
                data = await response.json();
            } else {
                data = dataAll[0]["enrichmentCategories"];
            }

            try {
                if (Array.isArray(data) && data.length > 0 && data[0].error === "communication_error") {
                    return; // Do nothing if there is a communication error
                }

                setCategories(data || []);
            } catch (error) {
                if (error instanceof SyntaxError) {
                    console.error("JSON Parsing Error:", error);
                    setCategories([]);
                } else {
                    console.error("Fetch Error:", error);
                }
            }
        };

        fetchEnrichmentCategories();
    }, [taskId, sessionId, UrlSTRING]);


    if (error) {
        return <div>Error loading SVG: {error}</div>;
    }


    const options = [
        {value: "0", label: "Don't group"},
        {value: "1.0", label: "similarity = 1.0"},
        {value: "0.9", label: "similarity >= 0.9"},
        {value: "0.8", label: "similarity >= 0.8"},
        {value: "0.7", label: "similarity >= 0.7"},
        {value: "0.6", label: "similarity >= 0.6"},
        {value: "0.5", label: "similarity >= 0.5"},
        {value: "0.4", label: "similarity >= 0.4"},
        {value: "0.3", label: "similarity >= 0.3"},
        {value: "0.2", label: "similarity >= 0.2"},
        {value: "0.1", label: "similarity >= 0.1"},
    ];
    var imageUrl = "";
    if (taskId) {
        imageUrl = `${UrlSTRING}/cgi/api?apiMethod=enrichmentfigure&taskId=${taskId}&sessionId=${sessionId}&apiOutputType=svg&category=${category}&group_by_similarity=${groupMergeSimilarity}&x_axis=${sortColumn}&number_of_term_shown=${maxCount}&color_palette=${colorPalette}`;
    } else {
        imageUrl = `${UrlSTRING}/cgi/api?apiMethod=enrichmentfigure&species=${species}&identifier=${identifier}&requiredScore=${requiredScore}&networkType=${networkType}&networkFlavor=${networkFlavor}&limit=${limit}&additionalNetworkNodes=${additionalNetworkNodes}&channel1=${channel1}&channel2=${channel2}&channel3=${channel3}&channel4=${channel4}&channel5=${channel5}&channel6=${channel6}&channel7=${channel7}&caller_identity=mobileapp&apiOutputType=svg&category=${category}&group_by_similarity=${groupMergeSimilarity}&x_axis=${sortColumn}&number_of_term_shown=${maxCount}&color_palette=${colorPalette}`;
    }

    const handleClick = () => {
        window.location.href = imageUrl;
    };

    return (
        <div className="main_page_content_container" id="bottom_page_enrichment_figure_container"
             style={localStorage.getItem('activeView') === "bottom_page_selector_figure" ? {display: "block"} : {display: "none"}}
        >
            <div className="settings_wrap" style={{
                display: 'inline-block',
                paddingRight: "2%",
                paddingLeft: "2%",
                width: "100%"
            }}>
                <div className="settings_box_wrap">
                    <div className="data_settings_wrap">
                        <div className="error_info_box">
                            <div className="box_body data_settings_body error_info_wrap">
                                <div style={{
                                    fontWeight: "bold",
                                    fontSize: "1.2em",
                                    margin: "2%"
                                }}>
                                    Figure Settings
                                </div>
                                <table className={"enrichmentGraphSettings"}>
                                    <thead>
                                    {/* Add header rows here if needed */}
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            {/*---------------------Category ---------------------*/}
                                            <div id="limit_label" className="data_settings_label"> Category:</div>
                                        </td>
                                        <td>

                                            <select
                                                name="category" id="category_select"
                                                className="data_settings_select"
                                                onChange={(e) => setCategory(e.target.value)}
                                            >
                                                {categories.map((object) => (
                                                    <option key={object.categoryId} value={object.categoryId}>
                                                        {object.categoryFullName}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>

                                    {/*---------------------Group terms by: ---------------------*/}
                                    <tr>
                                        <td>
                                            <div id="limit_label" className="data_settings_label"> Group terms by:</div>
                                        </td>
                                        <td>
                                            <select
                                                name="groupMergeSimilarity" id="groupMergeSimilarity_select"
                                                className="data_settings_select"
                                                defaultValue="0"
                                                onChange={(e) => setGroupMergeSimilarity(e.target.value)}
                                            >
                                                {options.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>

                                    {/*---------------------Sort terms by: ---------------------*/}
                                    <tr>
                                        <td>
                                            <div id="limit_label" className="data_settings_label"> Sort terms by:</div>
                                        </td>
                                        <td>
                                            <div style={{display: 'table-cell', paddingRight: '10px'}}>
                                                <select
                                                    name="sortColumn" id="sortColumn_select"
                                                    className="data_settings_select"
                                                    defaultValue="signal"
                                                    onChange={(e) => setSortColumn(e.target.value)}
                                                >
                                                    <option value="gene_count"> Gene count</option>
                                                    <option value="strength"> Strength</option>
                                                    <option value="signal"> Signal</option>
                                                    <option value="fdr"> -log(FDR)</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>

                                    {/*--------------------- Nr. of terms shown: ---------------------*/}
                                    <tr>
                                        <td>
                                            <div id="limit_label" className="data_settings_label"> Nr. of terms
                                                shown:
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                name="maxTermsPlot"
                                                id="maxcount_select"
                                                className="data_settings_select analysis_data_select"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMaxCount(e.target.value)}
                                                size={4}
                                                step={1}
                                                defaultValue={10}
                                                min={1}
                                                max={50}
                                                type="number"
                                            />
                                        </td>
                                    </tr>

                                    {/*---------------------Color Palette: ---------------------*/}
                                    <tr>
                                        <td style={{width: "41%"}}>
                                            <div id="limit_label" className="data_settings_label"> Color Palette:
                                            </div>
                                        </td>
                                        <td>
                                            <select
                                                name="colorPalette" id="colorPalette_select"
                                                className="data_settings_select"
                                                defaultValue="mint_blue"
                                                autoComplete="off"
                                                onChange={(e) => setColorPalette(e.target.value)}
                                            >
                                                <option value="mint_blue">mint - blue</option>
                                                <option value="lime_emerald">lime - emerald</option>
                                                <option value="green_blue">green - blue</option>
                                                <option value="peach_purple">peach - purple</option>
                                                <option value="straw_navy">straw - navy</option>
                                                <option value="yellow_pink">yellow - pink</option>
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                {/*---------------------figure: ---------------------*/}
                                <div className="graph-container" onClick={handleClick}>
                                    {loading && <PollingMessage/>}
                                    <img src={imageUrl} alt="enrichment graph" style={{width: "100%", height: "auto"}}
                                         onLoad={() => setLoading(false)}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnrichmentFigure;
