import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";
import {Helmet} from "react-helmet-async";

interface EvidenceRecord {
    rowTitle: string;
    textToShow: string;
    evidenceViewerLink: string;
}

interface Info {
    combinedScoreFraction: string;
    confidence: string;
    records: EvidenceRecord[];
}

interface EvidenceTable {
    inputSpeciesName: string;
    item1: string;
    item2: string;
    item1identifier: string;
    item2identifier: string;
    item1nameToShow: string;
    item2nameToShow: string;
    physical: Info;
    functional: Info;
}

const InteractionPage = () => {
    const {
        identifier1, identifier2
    } = useParams();

    const species = identifier1?.split('.')[0];
    const ldScriptRef = useRef<HTMLScriptElement | null>(null);
    const {UrlSTRING,} = useContext(GlobalContext);
    const {showBoundary} = useErrorBoundary();

    useEffect(() => {
        const fetchScript = async () => {
            try {
                const response = await fetch(`${UrlSTRING}/cgi/api?apiMethod=get_semantic_schema&apiOutputType=json&species=${species}&identifiers=${identifier1}%0D${identifier2}&semantic_schema_page=interaction&caller_identity=mobileapp`);
                const data: string = await response.text(); // Fetching text instead of JSON
                const script = document.createElement("script");
                script.type = "application/ld+json";
                script.innerHTML = data; // Use the fetched text directly
                document.head.appendChild(script);
                ldScriptRef.current = script;
            } catch (error) {
                setTimeout(() => {
                    showBoundary(error);
                }, 2000); // Delay of 2 seconds
            }
        };
        try {
            fetchScript();
        } catch (error) {
            setTimeout(() => {
                showBoundary(error);
            }, 2000); // Delay of 2 seconds
        }

        return () => {
            if (ldScriptRef.current) {
                document.head.removeChild(ldScriptRef.current);
                ldScriptRef.current = null;
            }
        };
    }, [species, identifier1, identifier2, UrlSTRING, showBoundary]);

    const url = `${UrlSTRING}/cgi/api?apiMethod=interaction&apiOutputType=json&species=${species}&identifiers=${identifier1}%0D${identifier2}&caller_identity=mobileapp`;
    const organismUrl = `/organism/${species}`; //${UrlSTRING}/organism/${species}`;
    const [data, setData] = useState<EvidenceTable[]>([]);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchInfo = () => {
            return fetch(url)
                .then((res) => res.json())
                .then((d) => setData(d))
                .catch((error) => {
                    setTimeout(() => {
                        showBoundary(error);
                    }, 2000); // Delay of 2 seconds
                });
        };
        fetchInfo();

    }, [species, identifier1, identifier2, url, navigate, showBoundary]);

    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        document.title = `${data[0].item1nameToShow}-${data[0].item2nameToShow} - STRING Interaction (${data[0].inputSpeciesName})`
    }, [data]);


    const confidenceColor = (combinedScore: string) => {
        if (combinedScore === "very high") {
            return <span style={{color: 'green', opacity: 0.75}}>{combinedScore}</span>;
        } else if (combinedScore === "high") {
            return <span style={{color: 'LimeGreen', opacity: 0.75}}>high</span>;
        } else if (combinedScore === "medium") {
            return <span style={{color: 'orange', opacity: 0.75}}>medium</span>;
        } else if (combinedScore === "exploratory") {
            return <span style={{color: 'red', opacity: 0.75}}>exploratory</span>;
        }
    };

    const toggleInteractions = (item: any) => {
        //    get the div of class edge_function_data and edge_physical_data
        const edge_function_data = document.getElementsByClassName("edge_function_data");
        const edge_physical_data = document.getElementsByClassName("edge_physical_data");

        if (item.textContent === "functional") {
            (edge_function_data[0] as HTMLElement).style.display = "block";
            (edge_physical_data[0] as HTMLElement).style.display = "none";
        } else {
            (edge_function_data[0] as HTMLElement).style.display = "none";
            (edge_physical_data[0] as HTMLElement).style.display = "block";
        }

        // now remove interaction_selected class from the current selected div and add it to the other div
        const interaction_selector = document.getElementsByClassName("interaction_selector")[0].children;
        for (let i = 0; i < interaction_selector.length; i++) {
            interaction_selector[i].classList.remove("interaction_selected");
        }
        item.classList.add("interaction_selected");
    };

    // Function to parse and make just the numbers bold
    const parseText = (text: string) => {
        const parts = text.split(/(score \d+\.\d+)/g); // Split by the entire "score number" part
        return parts.map((part, index) => {
            if (/score \d+\.\d+/.test(part)) {
                const [label, number] = part.split(' '); // Split into "score" and number
                return (
                    <React.Fragment key={index}>
                        {label} <strong>{number}</strong>
                    </React.Fragment>
                );
            }
            return part; // Return text that doesn't match the pattern
        });
    };

    return (
        <>
            <Helmet>
                <link rel="canonical" href={UrlSTRING + window.location.pathname}/>
            </Helmet>

            <div className="menu_content">
                <div className="edge_page_wrap">
                    <h1>STRING INTERACTION</h1>
                    <div className="interaction_selector" style={{}}>
                        <div
                            className="interaction_page_network_type_switch interaction_selected"
                            onClick={(e) => toggleInteractions(e.currentTarget)}
                        >
                            <div className="interaction_title">functional</div>
                        </div>
                        <span>&nbsp;</span>
                        <div
                            className="interaction_page_network_type_switch"
                            onClick={(e) => toggleInteractions(e.currentTarget)}
                        >
                            <div className="interaction_title">physical (co-complex)</div>
                        </div>
                        <span>&nbsp;</span>
                    </div>

                    {data.map((item, index) => (
                        <div key={`${index}key`}>
                            <div className="edge_function_data">
                                <p className="edge_organism_wrap" key={index}>
                                    Organism
                                    <button
                                        style={{padding: "3px", marginLeft: "5px"}}
                                        onClick={() => window.location.href = organismUrl}
                                    >
                                        {item.inputSpeciesName}
                                    </button>
                                </p>

                                <div className={"set_interaction_table"}
                                     style={{display: "flex", alignItems: "center"}}>
                                    <div className={"data_white_table_row"}
                                         style={{width: '48%'}}>{item.item1nameToShow} [{item.item1identifier}]
                                    </div>
                                    <div id={"arrow"}></div>

                                    <div className={"data_white_table_row"}
                                         style={{width: '48%'}}>{item.item2nameToShow} [{item.item2identifier}]
                                    </div>
                                </div>

                                {item.functional !== undefined ? (
                                    <>
                                        <div className="confidence_score">Combined
                                            confidence: {item.functional.combinedScoreFraction} ( <span
                                                style={{fontWeight: "600"}}>{confidenceColor(item.functional.confidence)}</span> )
                                        </div>
                                        <div className="interaction_title"> EVIDENCE TABLE</div>
                                        <div className="interaction_table">
                                            {item.functional.records
                                                .filter((networkType) => !networkType.textToShow.includes("none / insignificant"))
                                                .map((networkType, index, array) => (
                                                    <div key={`${index}1`}>
                                                        <div key={`${index}2`} className="interaction_column_title"
                                                             style={{display: 'flex', alignItems: 'center'}}>
                                                            <div style={{flexGrow: 1}}>
                                                                {networkType.rowTitle}
                                                            </div>
                                                            <button
                                                                className="show_button"
                                                                onClick={() => window.location.href = `${UrlSTRING}${networkType.evidenceViewerLink}`}
                                                                style={{marginLeft: '10px'}}
                                                            >
                                                                show
                                                            </button>
                                                        </div>
                                                        <div key={`${index}3`}>
                                                            <div
                                                                style={index === array.length - 1 ? {display: "flex"} : {
                                                                    display: "flex",
                                                                    borderBottom: "1px solid #A0A0A0"
                                                                }}>
                                                                <div className="interaction_column_data">
                                                                    {parseText(networkType.textToShow.replace(/&nbsp;/g, ' '))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </>
                                ) : (<>
                                    <div className="confidence_score" style={{color: "#be635f"}}> No evidence for
                                        functional
                                        interaction
                                    </div>
                                </>)}
                            </div>

                            <div className="edge_physical_data" style={{display: "none"}}>
                                <p className="edge_organism_wrap" key={index}>
                                    Organism
                                    <button
                                        style={{padding: "3px", marginLeft: "5px"}}
                                        onClick={() => window.location.href = organismUrl}
                                    >
                                        {item.inputSpeciesName}
                                    </button>
                                </p>
                                <div className={"set_interaction_table"}
                                     style={{display: "flex", alignItems: "center"}}>
                                    <div className={"data_white_table_row"}
                                         style={{width: '48%'}}>{item.item1nameToShow} [{item.item1identifier}]
                                    </div>
                                    <div id={"arrow"}></div>
                                    <div className={"data_white_table_row"}
                                         style={{width: '48%'}}>{item.item2nameToShow} [{item.item2identifier}]
                                    </div>
                                </div>
                                {item.physical !== undefined ? (
                                    <>
                                        <div className="confidence_score">Combined
                                            confidence: {item.physical.combinedScoreFraction} ( <span
                                                style={{fontWeight: "600"}}>{confidenceColor(item.physical.confidence)}</span> )
                                        </div>
                                        <div className="interaction_title"> EVIDENCE TABLE</div>
                                        <div className="interaction_table">
                                            {item.physical.records
                                                .filter((networkType) => !networkType.textToShow.includes("none / insignificant"))
                                                .map((networkType, index, array) => (
                                                    <div key={`${index}4`}>
                                                        <div key={`${index}5`} className="interaction_column_title"
                                                             style={{display: 'flex', alignItems: 'center'}}>
                                                            <div style={{flexGrow: 1}}>
                                                                {networkType.rowTitle}
                                                            </div>
                                                            <button
                                                                className="show_button"
                                                                onClick={() => window.location.href = `${UrlSTRING}${networkType.evidenceViewerLink}`}
                                                                style={{marginLeft: '10px'}}
                                                            >
                                                                show
                                                            </button>
                                                        </div>
                                                        <div key={`${index}6`}>
                                                            <div
                                                                style={index === array.length - 1 ? {display: "flex"} : {
                                                                    display: "flex",
                                                                    borderBottom: "1px solid #A0A0A0"
                                                                }}>
                                                                <div className="interaction_column_data"
                                                                     style={{width: "75%"}}>
                                                                    {parseText(networkType.textToShow.replace(/&nbsp;/g, ' '))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </>
                                ) : (<>
                                    <div className="confidence_score" style={{color: "#be635f"}}> No evidence for
                                        physical
                                        (co-complex) interaction
                                    </div>
                                </>)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default InteractionPage;