import React from "react";
import {useNavigate} from "react-router-dom";
import updateInput from "../utils/update_input";
import PollingMessage from "../components/PollingMessage";
import SearchOrganisms from "../components/SearchOrganisms";
import {useErrorBoundary} from "react-error-boundary";
import {Helmet} from "react-helmet-async";


const SearchProteinSequences = () => {

    document.title = "STRING: functional protein association networks";

    const navigate = useNavigate();
    const [polling, setPolling] = React.useState<boolean>(false);
    const {showBoundary} = useErrorBoundary();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        if (event.currentTarget.species_text.value === "") {
            event.currentTarget.species_text.value = "auto-detect";
        }

        try {
            setPolling(true);
            let limit = "10"
            if ((event.currentTarget.identifier.value.match(/>/g) || []).length > 1) {
                limit = "0";
            }

            const result = await updateInput(
                "ProteinSequences", event.currentTarget.species_text.value,
                event.currentTarget.identifier.value.replaceAll('', '').replaceAll('\n', '%0d').replaceAll(',', '%0d'),
                event.currentTarget.requiredScore.value, event.currentTarget.networkType.value, "evidence",
                limit, "0", "on", "on", "on", "on",
                "on", "on", "on", event.currentTarget.net_fdr.value, ""
            );
            if (result?.path === 'Not found') {
                // navigate('/error', {state: result.newData});
                return;
            }
            if (result) {
                navigate(result.path, {state: result.newData});
                return;
            }
        } catch (error) {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        }
        submit_button.disabled = false;
    }

    const toggleAdvancedSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let as_multiple_sequences_wrapper = document.getElementById("as_multiple_sequences_wrapper");
        let settings_button = document.getElementsByClassName("settings_button")[0];
        if (!as_multiple_sequences_wrapper) {
            return;
        }
        if (as_multiple_sequences_wrapper.style.display === "none") {
            as_multiple_sequences_wrapper.style.display = "block";
            settings_button.classList.add("active_button");
        } else {
            as_multiple_sequences_wrapper.style.display = "none";
            settings_button.classList.remove("active_button");
        }
    }

    const toggleInputExample = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {

        event.preventDefault();

        let sequence1 = ">ANP1_YEAST\n" +
            "MKYNNRKLSFNPTTVSIAGTLLTVFFLTRLVLSFFSISLFQLVTFQGIFKPYVPDFKNTPSVEFYDLRNYQGNKDGWQQGDRILFCVPLRDASEHLPMFFNHLNTMTYPHNLIDLSFLVSDSSDNTMGVLLSNLQMAQSQQDKSKRFGNIEIYEKDFGQIIGQSFSDRHGFGAQGPRRKLMARARNWLGSVALKPYHSWVYWRDVDVETIPTTIMEDLMHHDKDVIVPNVWRPLPDWLGNIQPYDLNSWKESEGGLQLADSLDEDAVIVEGYPEYATWRPHLAYMRDPNGNPEDEMELDGIGGVSILAKAKVFRTGSHFPAFSFEKHAETEAFGRLSRRMNYNVIGLPHYVIWHIYEPSSDDLKHMAWMAEEEKRKLEEERIREFYNKIWEIGFEDVRDQWNEERDSILKNIDSTLNNKVTVDWSEEGDGSELVDSKGDFVSPNNQQQQQQQQQQQQQQQQQQQQQQLDGNPQGKPLDDNDKNKKKHPKEVPLDFDPDRN"
        let organism1 = "Saccharomyces cerevisiae";
        let sequence2 = ">SMO_DROME\n" +
            "MQYLNFPRMPNIMMFLEVAILCLWVVADASASSAKFGSTTPASAQQSDVELEPINGTLNYRLYAKKGRDDKPWFDGLDSRHIQCVRRARCYPTSNATNTCFGSKLPYELSSLDLTDFHTEKELNDKLNDYYALKHVPKCWAAIQPFLCAVFKPKCEKINGEDMVYLPSYEMCRITMEPCRILYNTTFFPKFLRCNETLFPTKCTNGARGMKFNGTGQCLSPLVPTDTSASYYPGIEGCGVRCKDPLYTDDEHRQIHKLIGWAGSICLLSNLFVVSTFFIDWKNANKYPAVIVFYINLCFLIACVGWLLQFTSGSREDIVCRKDGTLRHSEPTAGENLSCIVIFVLVYYFLTAGMVWFVFLTYAWHWRAMGHVQDRIDKKGSYFHLVAWSLPLVLTITTMAFSEVDGNSIVGICFVGYINHSMRAGLLLGPLCGVILIGGYFITRGMVMLFGLKHFANDIKSTSASNKIHLIIMRMGVCALLTLVFILVAIACHVTEFRHADEWAQSFRQFIICKISSVFEEKSSCRIENRPSVGVLQLHLLCLFSSGIVMSTWCWTPSSIETWKRYIRKKCGKEVVEEVKMPKHKVIAQTWAKRKDFEDKGRLSITLYNTHTDPVGLNFDVNDLNSSETNDISSTWAAYLPQCVKRRMALTGAATGNSSSHGPRKNSLDSEISVSVRHVSVESRRNSVDSQVSVKIAEMKTKVASRSRGKHGGSSSNRRTQRRRDYIAAATGKSSRRRESSTSVESQVIALKKTTYPNASHKVGVFAHHSSKKQHNYTSSMKRRTANAGLDPSILNEFLQKNGDFIFPFLQNQDMSSSSEEDNSRASQKIQDLNVVVKQQEISEDDHDGIKIEELPNSKQVALENFLKNIKKSNESNSNRHSRNSARSQSKKSQKRHLKNPAADLDFRKDCVKYRSNDSLSCSSEELDVALDVGSLLNSSFSGISMGKPHSRNSKTSCDVGIQANPFELVPSYGEDELQQAMRLLNAASRQRTEAANEDFGGTELQGLLGHSHRHQREPTFMSESDKLKMLLLPSK"
        let organism2 = "Drosophila melanogaster";
        let sequence3 = ">TRPA_ECOLI\n" +
            "MERYESLFAQLKERKEGAFVPFVTLGDPGIEQSLKIIDTLIEAGADALELGIPFSDPLADGPTIQNATLRAFAAGVTPAQCFEMLALIRQKHPTIPIGLLMYANLVFNKGIDEFYAQCEKVGVDSVLVADVPVEESAPFRQAALRHNVAPIFICPPNADDDLLRQIASYGRGYTYLLSRAGVTGAENRAALPLNHLVAKLKEYNAAPPLQGFGISAPDQVKAAIDAGAAGAISGSAIVKIIEQHINEPEKMLAALKVFVQPMKAATRS"
            + "\n>TRPB_ECOLI\n" +
            "MTTLLNPYFGEFGGMYVPQILMPALRQLEEAFVSAQKDPEFQAQFNDLLKNYAGRPTALTKCQNITAGTNTTLYLKREDLLHGGAHKTNQVLGQALLAKRMGKTEIIAETGAGQHGVASALASALLGLKCRIYMGAKDVERQSPNVFRMRLMGAEVIPVHSGSATLKDACNEALRDWSGSYETAHYMLGTAAGPHPYPTIVREFQRMIGEETKAQILEREGRLPDAVIACVGGGSNAIGMFADFINETNVGLIGVEPGGHGIETGEHGAPLKHGRVGIYFGMKAPMMQTEDGQIEESYSISAGLDFPSVGPQHAYLNSTGRADYVSITDDEALEAFKTLCLHEGIIPALESSHALAHALKMMRENPDKEQLLVVNLSGRGDKDIFTVHDILKARGEI"
            + "\n>TRPC_ECOLI\n" +
            "MQTVLAKIVADKAIWVEARKQQQPLASFQNEVQPSTRHFYDALQGARTAFILECKKASPSKGVIRDDFDPARIAAIYKHYASAISVLTDEKYFQGSFNFLPIVSQIAPQPILCKDFIIDPYQIYLARYYQADACLLMLSVLDDDQYRQLAAVAHSLEMGVLTEVSNEEEQERAIALGAKVVGINNRDLRDLSIDLNRTRELAPKLGHNVTVISESGINTYAQVRELSHFANGFLIGSALMAHDDLHAAVRRVLLGENKVCGLTRGQDAKAAYDAGAIYGGLIFVATSPRCVNVEQAQEVMAAAPLQYVGVFRNHDIADVVDKAKVLSLAAVQLHGNEEQLYIDTLREALPAHVAIWKALSVGETLPAREFQHVDKYVLDNGQGGSGQRFDWSLLNGQSLGNVLLAGGLGADNCVEAAQTGCAGLDFNSAVESQPGIKDARLLASVFQTLRA"
            + "\n>TRPD_ECOLI\n" +
            "MADILLLDNIDSFTYNLADQLRSNGHNVVIYRNHIPAQTLIERLATMSNPVLMLSPGPGVPSEAGCMPELLTRLRGKLPIIGICLGHQAIVEAYGGYVGQAGEILHGKASSIEHDGQAMFAGLTNPLPVARYHSLVGSNIPAGLTINAHFNGMVMAVRHDADRVCGFQFHPESILTTQGARLLEQTLAWAQQKLEPANTLQPILEKLYQAQTLSQQESHQLFSAVVRGELKPEQLAAALVSMKIRGEHPNEIAGAATALLENAAPFPRPDYLFADIVGTGGDGSNSINISTASAFVAAACGLKVAKHGNRSVSSKSGSSDLLAAFGINLDMNADKSRQALDELGVCFLFAPKYHTGFRHAMPVRQQLKTRTLFNVLGPLINPAHPPLALIGVYSPELVLPIAETLRVLGYQRAAVVHSGGMDEVSLHAPTIVAELHDGEIKSYQLTAEDFGLTPYHQEQLAGGTPEENRDILTRLLQGKGDAAHEAAVAANVAMLMRLHGHEDLQANAQTVLEVLRSGSAYDRVTALAARG"
        let organism3 = "Escherichia coli str. K-12 substr. MG1655";
        let sequence4 = ">b2022\n" +
            "MSQKYLFIDRDGTLISEPPSDFQVDRFDKLAFEPGVIPELLKLQKAGYKLVMITNQDGLGTQSFPQADFDGPHNLMMQIFTSQGVQFDEVLICPHLPADECDCRKPKVKLVERYLAEQAMDRANSYVIGDRATDIQLAENMGITGLRYDRETLNWPMIGEQLTRRDRYAHVVRNTKETQIDVQVWLDREGGSKINTGVGFFDHMLDQIATHGGFRMEINVKGDLYIDDHHTVEDTGLALGEALKIALGDKRGICRFGFVLPMDECLARCALDISGRPHLEYKAEFTYQRVGDLSTEMIEHFFRSLSYTMGVTLHLKTKGKNDHHRVESLFKAFGRTLRQAIRVEGDTLPSSKGVL"
            + "\n>b2023\n" +
            "MNVVILDTGCANLNSVKSAIARHGYEPKVSRDPDVVLLADKLFLPGVGTAQAAMDQVRERELFDLIKACTQPVLGICLGMQLLGRRSEESNGVDLLGIIDEDVPKMTDFGLPLPHMGWNRVYPQAGNRLFQGIEDGAYFYFVHSYAMPVNPWTIAQCNYGEPFTAAVQKDNFYGVQFHPERSGAAGAKLLKNFLEM"
            + "\n>b2024\n" +
            "MIIPALDLIDGTVVRLHQGDYGKQRDYGNDPLPRLQDYAAQGAEVLHLVDLTGAKDPAKRQIPLIKTLVAGVNVPVQVGGGVRSEEDVAALLEAGVARVVVGSTAVKSQDMVKGWFERFGADALVLALDVRIDEQGNKQVAVSGWQENSGVSLEQLVETYLPVGLKHVLCTDISRDGTLAGSNVSLYEEVCARYPQVAFQSSGGIGDIDDVAALRGTGVRGVIVGRALLEGKFTVKEAIACWQNA"
            + "\n>b2026\n" +
            "MLTEQQRRELDWEKTDGLMPVIVQHAVSGEVLMLGYMNPEALDKTLESGKVTFFSRTKQRLWTKGETSGNFLNVVSIAPDCDNDTLLVLANPIGPTCHKGTSSCFGDTAHQWLFLYQLEQLLAERKSADPETSYTAKLYASGTKRIAQKVGEEGVETALAATVHDRFELTNEASDLMYHLLVLLQDQGLDLTTVIENLRKRHQ"
        let organism4 = "Escherichia coli str. K-12 substr. MG1655";

        let example_button1 = document.getElementById("1");
        let example_button2 = document.getElementById("2");
        let example_button3 = document.getElementById("3");
        let example_button4 = document.getElementById("4");

        if (id === "1") {
            example_button1?.classList.add("active_button");
            example_button2?.classList.remove("active_button");
            example_button3?.classList.remove("active_button");
            example_button4?.classList.remove("active_button");

            let input = document.getElementById("primary_input:multiple_sequences") as HTMLTextAreaElement | null;
            if (input) {
                input.setAttribute("value", sequence1);
                input.innerHTML = sequence1;
                input.value = sequence1;
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", organism1);
                organism_input.innerHTML = organism1;
                organism_input.value = organism1;
            }

            let network_type_selector = document.getElementById('network_type_multiple_sequences_select');
            let network_type_selector_option = network_type_selector?.querySelector('option[value="functional"]') as HTMLOptionElement;
            if (network_type_selector_option) {
                network_type_selector_option.selected = true;
                (network_type_selector as HTMLOptionElement).value = network_type_selector_option.value;
            }

            let required_score_selector = document.getElementById('required_score_multiple_sequences_select');
            let required_score_option = required_score_selector?.querySelector('option[value="400"]') as HTMLOptionElement;
            if (required_score_option) {
                required_score_option.selected = true;
                (required_score_selector as HTMLOptionElement).value = required_score_option.value;
            }

            let net_fdr_selector = document.getElementById('net_fdr_select');
            let net_fdr_option = net_fdr_selector?.querySelector('option[value="0.05"]') as HTMLOptionElement;
            if (net_fdr_option) {
                net_fdr_option.selected = true;
                (net_fdr_selector as HTMLOptionElement).value = net_fdr_option.value;
            }

        } else if (id === "2") {
            example_button2?.classList.add("active_button");
            example_button1?.classList.remove("active_button");
            example_button3?.classList.remove("active_button");
            example_button4?.classList.remove("active_button");

            let input = document.getElementById("primary_input:multiple_sequences") as HTMLTextAreaElement | null;
            if (input) {
                input.setAttribute("value", sequence2);
                input.innerHTML = sequence2;
                input.value = sequence2;
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", organism2);
                organism_input.innerHTML = organism2;
                organism_input.value = organism2;
            }

            let network_type_selector = document.getElementById('network_type_multiple_sequences_select');
            let network_type_selector_option = network_type_selector?.querySelector('option[value="physical"]') as HTMLOptionElement;
            if (network_type_selector_option) {
                network_type_selector_option.selected = true;
                (network_type_selector as HTMLOptionElement).value = network_type_selector_option.value;
            }

            let required_score_selector = document.getElementById('required_score_multiple_sequences_select');
            let required_score_option = required_score_selector?.querySelector('option[value="400"]') as HTMLOptionElement;
            if (required_score_option) {
                required_score_option.selected = true;
                (required_score_selector as HTMLOptionElement).value = required_score_option.value;
            }

            let net_fdr_selector = document.getElementById('net_fdr_select');
            let net_fdr_option = net_fdr_selector?.querySelector('option[value="0.05"]') as HTMLOptionElement;
            if (net_fdr_option) {
                net_fdr_option.selected = true;
                (net_fdr_selector as HTMLOptionElement).value = net_fdr_option.value;
            }

        } else if (id === "3") {
            example_button3?.classList.add("active_button");
            example_button1?.classList.remove("active_button");
            example_button2?.classList.remove("active_button");
            example_button4?.classList.remove("active_button");

            let input = document.getElementById("primary_input:multiple_sequences") as HTMLTextAreaElement | null;
            if (input) {
                input.setAttribute("value", sequence3);
                input.value = sequence3;
                input.innerHTML = sequence3;
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", organism3);
                organism_input.innerHTML = organism3;
                organism_input.value = organism3;
            }

            let network_type_selector = document.getElementById('network_type_multiple_sequences_select');
            let network_type_selector_option = network_type_selector?.querySelector('option[value="physical"]') as HTMLOptionElement;
            if (network_type_selector_option) {
                network_type_selector_option.selected = true;
                (network_type_selector as HTMLOptionElement).value = network_type_selector_option.value;
            }

            let required_score_selector = document.getElementById('required_score_multiple_sequences_select');
            let required_score_option = required_score_selector?.querySelector('option[value="400"]') as HTMLOptionElement;
            if (required_score_option) {
                required_score_option.selected = true;
                (required_score_selector as HTMLOptionElement).value = required_score_option.value;
            }

            let net_fdr_selector = document.getElementById('net_fdr_select');
            let net_fdr_option = net_fdr_selector?.querySelector('option[value="0.05"]') as HTMLOptionElement;
            if (net_fdr_option) {
                net_fdr_option.selected = true;
                (net_fdr_selector as HTMLOptionElement).value = net_fdr_option.value;
            }

        } else if (id === "4") {
            example_button4?.classList.add("active_button");
            example_button1?.classList.remove("active_button");
            example_button2?.classList.remove("active_button");
            example_button3?.classList.remove("active_button");

            let input = document.getElementById("primary_input:multiple_sequences");
            if (input) {
                input.setAttribute("value", sequence4);
                input.innerHTML = sequence4;
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", organism4);
                organism_input.innerHTML = organism4;
                organism_input.value = organism4;
            }

            let network_type_selector = document.getElementById('network_type_multiple_sequences_select');
            let network_type_selector_option = network_type_selector?.querySelector('option[value="physical"]') as HTMLOptionElement;
            if (network_type_selector_option) {
                network_type_selector_option.selected = true;
                (network_type_selector as HTMLOptionElement).value = network_type_selector_option.value;
            }

            let required_score_selector = document.getElementById('required_score_multiple_sequences_select');
            let required_score_option = required_score_selector?.querySelector('option[value="400"]') as HTMLOptionElement;
            if (required_score_option) {
                required_score_option.selected = true;
                (required_score_selector as HTMLOptionElement).value = required_score_option.value;
            }

            let net_fdr_selector = document.getElementById('net_fdr_select');
            let net_fdr_option = net_fdr_selector?.querySelector('option[value="0.05"]') as HTMLOptionElement;
            if (net_fdr_option) {
                net_fdr_option.selected = true;
                (net_fdr_selector as HTMLOptionElement).value = net_fdr_option.value;
            }
        }
    }

    return (
        <>
            <Helmet>
                <title> STRING: functional protein association networks</title>
            </Helmet>

            {polling ? (
                <PollingMessage/>) : (
                <div className="menu_content">
                    <div className="search_input_wrap">
                        <div className="search_input_box">
                            <div className="box_title">Single / Multiple Proteins by Sequence</div>
                            <div className="box">
                                <form id="input_form_multiple_sequences" onSubmit={handleSubmit}
                                      method="post" spellCheck="false" encType="multipart/form-data">
                                    <div>
                                        <input type="hidden" name="sessionId" value="btoPeBXp5k4Z"/>
                                        <input type="hidden" name="have_user_input" value="2"/>
                                    </div>
                                    <div className="row_100">
                                        <div className="wrap_input_with_examples">
                                            <label className="label">Amino Acid Sequence(s):</label>
                                            <span className="example_small">
                                                <button className="string_example small_button" id={"1"}
                                                        onClick={(e) => toggleInputExample(e, "1")}>1</button>
                                                &nbsp;&nbsp;
                                                <button className="string_example small_button" id={"2"}
                                                        onClick={(e) => toggleInputExample(e, "2")}>2</button>
                                                &nbsp;&nbsp;
                                                <button className="string_example small_button" id={"3"}
                                                        onClick={(e) => toggleInputExample(e, "3")}>3</button>
                                        </span>
                                            <div className="wrap_search_item">
                                    <textarea className="input submit_on_enter"
                                              style={{minHeight: "115px"}}
                                              id="primary_input:multiple_sequences"
                                              name="identifier"
                                              autoComplete="on"
                                              required>
                                    </textarea>
                                                <input name="targetmode" type="hidden" value="proteins"/>
                                            </div>
                                        </div>
                                    </div>
                                    <SearchOrganisms/>
                                    <div className="row_100">
                                        <div className="wrap_input_with_examples">
                                            <button className="settings_button"
                                                    onClick={toggleAdvancedSettings}>Advanced
                                                Settings
                                            </button>
                                            <div className="wrap_search_item">
                                                <div id="as_multiple_sequences_wrapper"
                                                     className="advanced_settings_wrapper"
                                                     style={{display: "none"}}>
                                                    <div className="advanced_settings_options"
                                                         id="as_multiple_sequences_options">
                                                        <div style={{display: "table"}}>
                                                            <div style={{display: "table-row"}}>
                                                                <div className="advanced_settings_options_labels">
                                                                    Network Type:&nbsp;&nbsp;
                                                                </div>
                                                                <div className="data_settings_select_wrap">
                                                                    <select
                                                                        name="networkType"
                                                                        id="network_type_multiple_sequences_select"
                                                                        className="data_settings_select"
                                                                        style={{width: "100%"}}
                                                                        autoComplete="off"
                                                                        defaultValue="functional"
                                                                    >
                                                                        <option value="functional">full STRING network
                                                                        </option>
                                                                        <option value="physical">physical subnetwork
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div style={{display: 'table-row'}}>
                                                                <div className="advanced_settings_options_labels">
                                                                    Required score:&nbsp;&nbsp;
                                                                </div>
                                                                <div className="data_settings_select_wrap">
                                                                    <select
                                                                        name="requiredScore"
                                                                        id="required_score_multiple_sequences_select"
                                                                        className="data_settings_select"
                                                                        autoComplete="off"
                                                                        defaultValue="400" // Set the value for the default selected option
                                                                    >
                                                                        <option value="900">highest confidence (0.900)
                                                                        </option>
                                                                        <option value="700">high confidence (0.700)
                                                                        </option>
                                                                        <option value="400">medium confidence (0.400)
                                                                        </option>
                                                                        <option value="150">low confidence (0.150)
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div style={{display: 'table-row'}}>
                                                                <div className="advanced_settings_options_labels">
                                                                    FDR&nbsp;stringency:&nbsp;
                                                                </div>
                                                                <div className="data_settings_select_wrap">
                                                                    <select
                                                                        name="net_fdr"
                                                                        id="net_fdr_select"
                                                                        className="data_settings_select"
                                                                        autoComplete="off"
                                                                        defaultValue="0.05" // Set the value for the default selected option
                                                                    >
                                                                        <option value="0.01">high (1 percent)
                                                                        </option>
                                                                        <option value="0.05">medium (5 percent)
                                                                        </option>
                                                                        <option value="0.25">lenient (25 percent)
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="button large_submit_button">
                                        <div></div>
                                        &nbsp;Search&nbsp;
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"shadow"}></div>
                </div>
            )}
        </>
    );
}

export default SearchProteinSequences;