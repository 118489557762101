import React, {useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PollingMessage from "../components/PollingMessage";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";

interface DataObj {
    species: string;
    identifier: string;
    inputItems: string;
    requiredScore: string;
    networkType: string;
    networkFlavor: string;
    limit: string;
    additionalNetworkNodes: string;
    channel1: string;
    channel2: string;
    channel3: string;
    channel4: string;
    channel5: string;
    channel6: string;
    channel7: string;
}

const GetTaskDataFromSTRING = () => {
    const {taskId, sessionId} = useParams();
    const [data, setData] = React.useState<DataObj[]>([]);
    // get data from string then go to network (for now / any page that was requested) page
    const {UrlSTRING,} = useContext(GlobalContext);
    const url = `${UrlSTRING}/cgi/api?apiMethod=get_taskdata&apiOutputType=json&taskId=${taskId}&sessionId=${sessionId}&caller_identity=mobileapp`
    const {showBoundary} = useErrorBoundary();

    fetch(url)
        .then((res) => res.json())
        .then((d) => {
            const formattedData = d.map((item: any) => ({
                taskId: item.taskId,
                sessionId: item.sessionId,
                species: item.species,
                identifier: item.identifier ? item.identifier : item.inputItems,
                requiredScore: item.requiredScore,
                networkType: item.networkType,
                networkFlavor: item.networkFlavor,
                limit: item.limit,
                additionalNetworkNodes: item.additionalNetworkNodes,
                channel1: item.channel1,
                channel2: item.channel2,
                channel3: item.channel3,
                channel4: item.channel4,
                channel5: item.channel5,
                channel6: item.channel6,
                channel7: item.channel7,
            }));
            setData(formattedData);
        })
        .catch((error) => {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        });

    const navigate = useNavigate();
    useEffect(() => {
        if (data.length > 0) {
            navigate(`/cgi/network/${taskId}/${sessionId}/`, {
                state: {
                    species: data[0].species,
                    identifier: data[0].identifier,
                    requiredScore: data[0].requiredScore,
                    networkType: data[0].networkType,
                    networkFlavor: data[0].networkFlavor,
                    limit: data[0].limit,
                    additionalNetworkNodes: data[0].additionalNetworkNodes,
                    channel1: data[0].channel1,
                    channel2: data[0].channel2,
                    channel3: data[0].channel3,
                    channel4: data[0].channel4,
                    channel5: data[0].channel5,
                    channel6: data[0].channel6,
                    channel7: data[0].channel7,
                    sessionId: sessionId
                }
            })
            return;
        }
    }, [data, navigate, sessionId, taskId]);

    return (
        <PollingMessage/>
    );
}

export default GetTaskDataFromSTRING;