import {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";

const TopBar = () => {
    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const {UrlSTRING,} = useContext(GlobalContext);
    const baseURL = "https://m.string-db.org"

    const [loginUrl, setLoginUrl] = useState('');
    const [logoutUrl, setLogoutUrl] = useState('');
    const location = useLocation();

    useEffect(() => {
        let script_name = window.location.href.replace("https://m.string-db.org", "")

        let loginUrl = `https://string-db.eu.auth0.com/authorize?client_id=${auth0ClientId}&response_type=code&redirect_uri=${baseURL}/cgi/login%3Fscript_name%3D${script_name.replace("%20", '%2520')}`
        let logoutUrl = `https://string-db.eu.auth0.com/v2/logout?client_id=${auth0ClientId}&returnTo=${baseURL}/cgi/logout%3Fscript_name%3F${script_name.replace("%20", '%2520')}`

        setLoginUrl(loginUrl);
        setLogoutUrl(logoutUrl);
    }, [location, auth0ClientId, UrlSTRING, baseURL]);

    let display;
    let loginUrl_;
    let displayName = document.cookie.split(';').find((cookie) => cookie.includes('string_db_display_name.12.0'))?.split('=')[1];

    // let openIdAuthentifiedId = document.cookie.split(';').find((cookie) => cookie.includes('string_login_credentials'))?.split('=')[1];
    let cookies = document.cookie.split(';');
    let stringLoginCredentialsValues = [];

    // Collect all instances of 'string_login_credentials' with values
    cookies.forEach(cookie => {
        const [name, value] = cookie.trim().split('=');
        if (name === 'm.string_login_credentials' && value !== '_unassigned' && value !== undefined) {
            stringLoginCredentialsValues.push(value);
        }
    });

    if (stringLoginCredentialsValues.length === 0) {
        display = 'Login';
        loginUrl_ = loginUrl;
    } else {
        if (displayName) {
            display = displayName;
        } else {
            display = 'USER';
        }
        loginUrl_ = "/my";
    }

    const LogoutFunction = () => {
        document.cookie = "m.string_db_display_name.12.0=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "m.string_login_credentials=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    return (
        <div className="top_bar">
            <div className="right">
                <ul className="top_bar_list">
                    <li><a id="login_display_tag"
                           href={loginUrl_}>{display}</a></li>

                    {display !== 'Login' ? (
                        <li>
                            <a id="logout_display_tag" href={logoutUrl} onClick={LogoutFunction}>
                                Logout
                            </a>
                        </li>
                    ) : null}

                    {/*<li><a id="logout_display_tag" href="https://forms.gle/hJ1Guc6N6MLU8Xgk8"*/}
                    {/*       target="#">Survey</a></li>*/}
                </ul>
            </div>
        </div>
    );
}
export default TopBar;