import React, {useContext, useEffect, useRef} from "react";
import Carousel from "../components/Carousel";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";
import {Helmet} from "react-helmet-async";

const HomePage = () => {
    document.title = "STRING: functional protein association networks";
    const {UrlSTRING} = useContext(GlobalContext);
    const ldScriptRef = useRef<HTMLScriptElement | null>(null);
    const {showBoundary} = useErrorBoundary();

    useEffect(() => {
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.textContent = `{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "STRING",
            "url": "https://m.string-db.org",
            "logo": "https://m.string-db.org/images/string_logo_2015_compact_left.png"
        }`;
        document.head.appendChild(script); // Append the script element to the head
        ldScriptRef.current = script;
        return () => {
            document.head.removeChild(script);
        };
    }, []); // Empty dependency array ensures this runs only once after the initial render


    useEffect(() => {
        const fetchScript = async () => {
            try {
                const response = await fetch(`${UrlSTRING}/cgi/api?apiMethod=get_home_semantic_schema&apiOutputType=json&caller_identity=mobileapp`);
                const data: string = await response.text(); // Fetching text instead of JSON
                const script = document.createElement("script");
                script.type = "application/ld+json";
                script.textContent = data; // Use the fetched text directly
                document.head.appendChild(script);
                ldScriptRef.current = script;
            } catch (error) {
                setTimeout(() => {
                    showBoundary(error); // Delay of 2 seconds
                }, 2000);
            }
        };

        // Call the fetchScript function
        fetchScript().catch((error) => {
            setTimeout(() => {
                showBoundary(error); // Delay of 2 seconds for any uncaught errors
            }, 2000);
        });

        return () => {
            if (ldScriptRef.current) {
                try {
                    document.head.removeChild(ldScriptRef.current);
                    ldScriptRef.current = null;
                } catch (error) {
                    // console.error("Error cleaning up script element:", error);
                }
            }
        };
    }, [UrlSTRING, showBoundary]);

    const handleClick = () => {
        window.location.href = "/SearchMenu";
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href={UrlSTRING}/>
                <title> STRING: functional protein association networks</title>
            </Helmet>

            <div className="home_container">
                <div id="string_logo_left_home"></div>
                <div className="home_title">
                    Welcome to <br/> <span style={{color: "#848ec2", fontWeight: "700"}}> STRING </span>
                </div>
                <br/>
                <Carousel/>
                <button onClick={handleClick} className="button large_submit_button"
                        style={{marginBottom: "4vh", marginTop: "4vh", height: "7vh"}}>SEARCH
                </button>
            </div>
        </>
    );
}

export default HomePage;




