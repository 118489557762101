import React from "react";
import {useNavigate} from "react-router-dom";
import updateInput from "../utils/update_input";
import PollingMessage from "../components/PollingMessage";
import SearchOrganisms from "../components/SearchOrganisms";
import {useErrorBoundary} from "react-error-boundary";
import {Helmet} from "react-helmet-async";

const SearchSingleProtein = () => {

    document.title = "STRING: functional protein association networks";

    const navigate = useNavigate();
    const [polling, setPolling] = React.useState<boolean>(false);
    const {showBoundary} = useErrorBoundary();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // make button disabled and look active
        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        if (event.currentTarget.species_text.value === "") {
            event.currentTarget.species_text.value = "auto-detect";
        }

        try {
            setPolling(true);

            const result = await updateInput(
                "SingleProtein", event.currentTarget.species_text.value, event.currentTarget.identifier.value,
                event.currentTarget.requiredScore.value, event.currentTarget.networkType.value, "evidence",
                event.currentTarget.limit.value, "0", "on", "on", "on",
                "on", "on", "on", "on", "", ""
            );
            if (result?.path === '/error') {
                navigate('/error', {state: result.newData});
                return;
            }

            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                return;
            }

            if (result) {
                navigate(result.path, {state: result.newData});
                return;
            }
        } catch (error) {
            setTimeout(() => {
                showBoundary(error);
            }, 2000); // Delay of 2 seconds
        }
        submit_button.disabled = false;
    };

    const toggleAdvancedSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let as_single_identifier_wrapper = document.getElementById("as_single_identifier_wrapper");
        let settings_button = document.getElementsByClassName("settings_button")[0];
        if (!as_single_identifier_wrapper) {
            return;
        }
        if (as_single_identifier_wrapper.style.display === "none") {
            as_single_identifier_wrapper.style.display = "block";
            settings_button.classList.add("active_button");
        } else {
            as_single_identifier_wrapper.style.display = "none";
            settings_button.classList.remove("active_button");
        }
    }

    const toggleInputExample = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {

        event.preventDefault();

        let example_button1 = document.getElementById("1");
        let example_button2 = document.getElementById("2");
        let example_button3 = document.getElementById("3");

        if (id === "1") {
            example_button1?.classList.add("active_button");
            example_button2?.classList.remove("active_button");
            example_button3?.classList.remove("active_button");

            let input = document.getElementById("primary_input:single_identifier") as HTMLInputElement | null;
            if (input) {
                input?.setAttribute("value", "trpA");
                input.innerHTML = "trpA";
                input.value = "trpA";
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", "Escherichia coli str. K-12 substr. MG1655");
                organism_input.innerHTML = "Escherichia coli str. K-12 substr. MG1655";
                organism_input.value = "Escherichia coli str. K-12 substr. MG1655";
            }
            let network_type_selector = document.getElementById('network_type_single_identifier_select');
            let network_type_selector_option = network_type_selector?.querySelector('option[value="functional"]') as HTMLOptionElement;
            if (network_type_selector_option) {
                network_type_selector_option.selected = true;
                (network_type_selector as HTMLOptionElement).value = network_type_selector_option.value;
            }

            let required_score_selector = document.getElementById('required_score_single_identifier_select');
            let required_score_option = required_score_selector?.querySelector('option[value="900"]') as HTMLOptionElement;
            if (required_score_option) {
                required_score_option.selected = true;
                (required_score_selector as HTMLOptionElement).value = required_score_option.value;
            }

            let limit_selector = document.getElementById('limit_select');
            let limit_option = limit_selector?.querySelector('option[value="10"]') as HTMLOptionElement;
            if (limit_option) {
                limit_option.selected = true;
                (limit_selector as HTMLOptionElement).value = limit_option.value;
            }

        } else if (id === "2") {
            example_button1?.classList.remove("active_button");
            example_button2?.classList.add("active_button");
            example_button3?.classList.remove("active_button");

            let input = document.getElementById("primary_input:single_identifier") as HTMLInputElement | null;
            if (input) {
                input?.setAttribute("value", "CDC15");
                input.innerHTML = "CDC15";
                input.value = "CDC15";
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", "Saccharomyces cerevisiae");
                organism_input.innerHTML = "Saccharomyces cerevisiae";
                organism_input.value = "Saccharomyces cerevisiae";
            }

            let network_type_selector = document.getElementById('network_type_single_identifier_select');
            let network_type_selector_option = network_type_selector?.querySelector('option[value="functional"]') as HTMLOptionElement;
            if (network_type_selector_option) {
                network_type_selector_option.selected = true;
                (network_type_selector as HTMLOptionElement).value = network_type_selector_option.value;
            }

            let required_score_selector = document.getElementById('required_score_single_identifier_select');
            let required_score_option = required_score_selector?.querySelector('option[value="400"]') as HTMLOptionElement;
            if (required_score_option) {
                required_score_option.selected = true;
                (required_score_selector as HTMLOptionElement).value = required_score_option.value;
            }

            let limit_selector = document.getElementById('limit_select');
            let limit_option = limit_selector?.querySelector('option[value="10"]') as HTMLOptionElement;
            if (limit_option) {
                limit_option.selected = true;
                (limit_selector as HTMLOptionElement).value = limit_option.value;
            }

        } else if (id === "3") {
            example_button1?.classList.remove("active_button");
            example_button2?.classList.remove("active_button");
            example_button3?.classList.add("active_button");

            let input = document.getElementById("primary_input:single_identifier") as HTMLInputElement | null;
            if (input) {
                input?.setAttribute("value", "smoothened");
                input.innerHTML = "smoothened";
                input.value = "smoothened";
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", "Homo sapiens");
                organism_input.innerHTML = "Homo sapiens";
                organism_input.value = "Homo sapiens";
            }

            let network_type_selector = document.getElementById('network_type_single_identifier_select');
            let network_type_selector_option = network_type_selector?.querySelector('option[value="physical"]') as HTMLOptionElement;
            if (network_type_selector_option) {
                network_type_selector_option.selected = true;
                (network_type_selector as HTMLOptionElement).value = network_type_selector_option.value;
            }

            let required_score_selector = document.getElementById('required_score_single_identifier_select');
            let required_score_option = required_score_selector?.querySelector('option[value="400"]') as HTMLOptionElement;
            if (required_score_option) {
                required_score_option.selected = true;
                (required_score_selector as HTMLOptionElement).value = required_score_option.value;
            }

            let limit_selector = document.getElementById('limit_select');
            let limit_option = limit_selector?.querySelector('option[value="10"]') as HTMLOptionElement;
            if (limit_option) {
                limit_option.selected = true;
                (limit_selector as HTMLOptionElement).value = limit_option.value;
            }
        }
    }

    return (
        <>
            <Helmet>
                <title> STRING: functional protein association networks</title>
            </Helmet>

            {polling ? (
                <PollingMessage/>) : (
                <div className="menu_content">
                    <div className="search_input_wrap">
                        <div className="search_input_box">
                            <div className="box_title">Single Protein by<br/>Name / Identifier</div>
                            <div className="box">
                                <form id="input_form_single_identifier" onSubmit={handleSubmit}
                                      method="post" spellCheck="false" encType="multipart/form-data">
                                    <div>
                                        <input type="hidden" name="sessionId" value="btoPeBXp5k4Z"/>
                                        <input type="hidden" name="have_user_input" value="2"/>
                                    </div>
                                    <div className="row_100">
                                        <div className="wrap_input_with_examples">
                                            <label className="label">Protein Name:</label>
                                            <span className="example_small">
                                                <button className="string_example small_button" id={"1"}
                                                        onClick={(e) => toggleInputExample(e, "1")}>1</button>
                                                &nbsp;&nbsp;
                                                <button className="string_example small_button" id={"2"}
                                                        onClick={(e) => toggleInputExample(e, "2")}>2</button>
                                                &nbsp;&nbsp;
                                                <button className="string_example small_button" id={"3"}
                                                        onClick={(e) => toggleInputExample(e, "3")}>3</button>
                                        </span>
                                            <div className="wrap_search_item">
                                                <input className="input submit_on_enter"
                                                       id="primary_input:single_identifier" maxLength={50}
                                                       name="identifier"
                                                       type="search" autoComplete="on"
                                                       required
                                                />
                                                <input name="targetmode" type="hidden" value="proteins"/>
                                            </div>
                                        </div>
                                    </div>
                                    <SearchOrganisms/>
                                    <div className="row_100">
                                        <div className="wrap_input_with_examples">
                                            <button className="settings_button"
                                                    onClick={toggleAdvancedSettings}>Advanced
                                                Settings
                                            </button>
                                            <div className="wrap_search_item">
                                                <div id="as_single_identifier_wrapper"
                                                     className="advanced_settings_wrapper"
                                                     style={{display: "none"}}>
                                                    <div className="advanced_settings_options"
                                                         id="as_single_identifier_options">
                                                        <div style={{display: "table"}}>
                                                            <div style={{display: "table-row"}}>
                                                                <div
                                                                    className="advanced_settings_options_labels">
                                                                    Network Type:&nbsp;&nbsp;
                                                                </div>
                                                                <div className="data_settings_select_wrap">
                                                                    <select
                                                                        name="networkType"
                                                                        id="network_type_single_identifier_select"
                                                                        className="data_settings_select"
                                                                        style={{width: "100%"}}
                                                                        autoComplete="off"
                                                                        defaultValue="functional"
                                                                    >
                                                                        <option value="functional">full STRING
                                                                            network
                                                                        </option>
                                                                        <option value="physical">physical
                                                                            subnetwork
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div style={{display: 'table-row'}}>
                                                                <div
                                                                    className="advanced_settings_options_labels">
                                                                    Required score:&nbsp;&nbsp;
                                                                </div>
                                                                <div className="data_settings_select_wrap">
                                                                    <select
                                                                        name="requiredScore"
                                                                        id="required_score_single_identifier_select"
                                                                        className="data_settings_select"
                                                                        autoComplete="off"
                                                                        defaultValue="400" // Set the value for the default selected option
                                                                    >
                                                                        <option value="900">highest confidence
                                                                            (0.900)
                                                                        </option>
                                                                        <option value="700">high confidence
                                                                            (0.700)
                                                                        </option>
                                                                        <option value="400">medium confidence
                                                                            (0.400)
                                                                        </option>
                                                                        <option value="150">low confidence
                                                                            (0.150)
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div style={{display: 'table-row'}}>
                                                                <div
                                                                    className="advanced_settings_options_labels">
                                                                    Size&nbsp;cutoff:&nbsp;
                                                                </div>
                                                                <div className="data_settings_select_wrap">
                                                                    <select
                                                                        name="limit"
                                                                        id="limit_select"
                                                                        className="data_settings_select"
                                                                        autoComplete="off"
                                                                        defaultValue="10" // Set the value for the default selected option
                                                                    >
                                                                        <option value="5">no more than 5
                                                                            interactors
                                                                        </option>
                                                                        <option value="10">no more than 10
                                                                            interactors
                                                                        </option>
                                                                        <option value="20">no more than 20
                                                                            interactors
                                                                        </option>
                                                                        <option value="50">no more than 50
                                                                            interactors
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="button large_submit_button">
                                        <div></div>
                                        &nbsp;Search&nbsp;
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"shadow"}></div>
                </div>
            )}
        </>
    );
}

export default SearchSingleProtein;