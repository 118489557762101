import StringNetwork from "../components/StringNetwork";
import EnrichmentTable from "../components/EnrichmentTable";
import PageContentNav from "../components/PageContentNav";
import NetworkStats from "../components/NetworkStats";
import Settings from "../components/Settings";
import EdgeSelector from "../components/EdgeSelector";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ViewersTable from "../components/ViewersTable";
import NodeSelector from "../components/NodeSelector";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";
import EnrichmentFigure from "../components/EnrichmentFigure";
import {Helmet} from "react-helmet-async";
import PollingMessage from "../components/PollingMessage";

interface DataObj {
    taskId: string;
    sessionId: string;
    species: string;
    inputItems: string;
    proteinCount: number;
    nodeCount: number;
    pageTitle: string;
    Error?: string;
    ErrorMessage?: string;
    channel1?: string;
    channel2?: string;
    channel3?: string;
    channel4?: string;
    channel5?: string;
    channel6?: string;
    channel7?: string;
}

const NetworkPageIndexed = () => {
    let { species: paramSpecies, identifier } = useParams();
    const [species, setSpecies] = useState(paramSpecies);
    let navigate = useNavigate();

    useEffect(() => {
        const parseIdentifier = (identifier: string): {
            parsedSpecies: string | undefined;
            parsedIdentifier: string
        } => {
            const match = identifier.match(/^(\d+)\.(.+)$/);
            if (match) {
                return {parsedSpecies: match[1], parsedIdentifier: match[2]};
            }
            return {parsedSpecies: undefined, parsedIdentifier: identifier};
        };

        if (identifier && !species) {
            const {parsedSpecies, parsedIdentifier} = parseIdentifier(identifier);
            setSpecies(parsedSpecies);
            if (!parsedSpecies) {
                let errorData = {
                    title: "404 Error ...",
                    message: "Sorry, this page cannot be found ... please check the URL."
                }
                navigate('/error', {state: errorData});
            }
        }
    }, [identifier]);

    const ldScriptRef = useRef<HTMLScriptElement | null>(null);
    let requiredScore = "400";
    const networkType = "functional";
    let networkFlavor = "evidence";
    const limit = "10";
    const additionalNetworkNodes = "0";
    let channel1 = "on";
    let channel2 = "on";
    let channel3 = "on";
    let channel4 = "on";
    let channel5 = "on";
    let channel6 = "on";
    let channel7 = "on";
    // const displayMode = "svg";
    // const colorblindEdges = "off";
    const bubbleNodeDesign = "off";
    let blockStructures = "off";
    const centerNodeLabels = "off";
    const showQueryNodeLabels = "off";
    const hideSingletons = "off";
    const hideLabels = "off";
    const dataSettingsLabel = "12";

    // optimise large network display
    if (identifier && limit && additionalNetworkNodes) {
        var node_count = identifier.split("%0d").length + parseInt(limit, 10) + parseInt(additionalNetworkNodes, 10);
        var too_large_network_count = 200;

        if (node_count > too_large_network_count) {
            networkFlavor = "confidence"
            blockStructures = "on"
            requiredScore = "900"
        }
    }

    const [proteinCount, setProteinCount] = useState<number>(0);
    const [nodeCount, setNodeCount] = useState<number>(0);
    const {UrlSTRING,} = useContext(GlobalContext);
    const {showBoundary} = useErrorBoundary();

    const location = useLocation()
    useEffect(() => {
        const createScript = (semanticschemaData: any) => {
            const script = document.createElement("script");
            script.type = "application/ld+json";
            script.innerHTML = semanticschemaData; // Use the fetched text directly
            document.head.appendChild(script);
            ldScriptRef.current = script; // Assuming ldScriptRef is already initialized
        };

        const fetchTaskId = async () => {
            if (!species){
                return
            }
            try {

                let urlAll = `${UrlSTRING}/cgi/api?apiMethod=get_api_all&apiOutputType=json&species=${species}&identifier=${identifier}&requiredScore=${requiredScore}&networkType=${networkType}&networkFlavor=${networkFlavor}&limit=${limit}&additionalNetworkNodes=${additionalNetworkNodes}&channel1=${channel1}&channel2=${channel2}&channel3=${channel3}&channel4=${channel4}&channel5=${channel5}&channel6=${channel6}&channel7=${channel7}&caller_identity=mobileapp&addSignal=1`
                let openIdAuthentifiedId = document.cookie.split(';').find((cookie) => cookie.includes('m.string_login_credentials'))?.split('=')[1];
                if (openIdAuthentifiedId) {
                    urlAll = urlAll + `&openIdAuthentifiedId=${openIdAuthentifiedId}`;
                }
                let dataAll;
                const response = await fetch(urlAll);
                dataAll = await response.json();

                if (dataAll.length > 0) {
                    createScript(dataAll[0]["semanticschema"]);
                    setProteinCount(dataAll[0]["initTaskdata"].proteinCount);
                    setNodeCount(dataAll[0]["initTaskdata"].nodeCount);

                    document.title = dataAll[0]["initTaskdata"].pageTitle ? dataAll[0]["initTaskdata"].pageTitle : "STRING interaction network"
                    location.state = {
                        species: dataAll[0]["initTaskdata"].species,
                        identifier,
                        requiredScore,
                        networkType,
                        networkFlavor,
                        limit,
                        additionalNetworkNodes,
                        channel1: dataAll[0]["initTaskdata"].channel1 || undefined,
                        channel2: dataAll[0]["initTaskdata"].channel2 || undefined,
                        channel3: dataAll[0]["initTaskdata"].channel3 || undefined,
                        channel4: dataAll[0]["initTaskdata"].channel4 || undefined,
                        channel5: dataAll[0]["initTaskdata"].channel5 || undefined,
                        channel6: dataAll[0]["initTaskdata"].channel6 || undefined,
                        channel7: dataAll[0]["initTaskdata"].channel7 || undefined,
                        bubbleNodeDesign,
                        blockStructures,
                        centerNodeLabels,
                        showQueryNodeLabels,
                        hideSingletons,
                        hideLabels,
                        dataSettingsLabel,
                        dataAll
                    };
                }
            } catch (error) {
                setTimeout(() => {
                    showBoundary(error);
                }, 5000); // Delay of 2 seconds
            }
        }
        fetchTaskId();
    }, [species, identifier, blockStructures, networkFlavor, requiredScore]);

    return (
        <>
            <Helmet>
                <link rel="canonical" href={UrlSTRING + window.location.pathname}/>
            </Helmet>

            <div>
                {species && identifier && location.state ?
                    (
                        <>
                            <StringNetwork/>
                            <PageContentNav/>
                            <ViewersTable taskId={""} sessionId={""}/>
                            <Settings taskId={""}
                                      sessionId={""}
                                      species={species}
                                      identifier={identifier}
                                      requiredScore={requiredScore}
                                      networkType={networkType ? networkType : "functional"}
                                      networkFlavor={networkFlavor ? networkFlavor : "evidence"}
                                      limit={limit ? limit : "400"}
                                      additionalNetworkNodes={additionalNetworkNodes ? additionalNetworkNodes : "0"}
                                      channel1={channel1 ? channel1 : "on"}
                                      channel2={channel2 ? channel2 : "on"}
                                      channel3={channel3 ? channel3 : "on"}
                                      channel4={channel4 ? channel4 : "on"}
                                      channel5={channel5 ? channel5 : "on"}
                                      channel6={channel6 ? channel6 : "on"}
                                      channel7={channel7 ? channel7 : "on"}
                                // displayMode={displayMode ? displayMode : "svg"}
                                // colorblindEdges={colorblindEdges ? colorblindEdges : "off"}
                                      bubbleNodeDesign={bubbleNodeDesign ? bubbleNodeDesign : "off"}
                                      blockStructures={blockStructures ? blockStructures : "off"}
                                      centerNodeLabels={centerNodeLabels ? centerNodeLabels : "off"}
                                      showQueryNodeLabels={showQueryNodeLabels ? showQueryNodeLabels : "off"}
                                      hideSingletons={hideSingletons ? hideSingletons : "off"}
                                      hideLabels={hideLabels ? hideLabels : "off"}
                                      dataSettingsLabel={dataSettingsLabel ? dataSettingsLabel : "12"}
                            />
                            <div id="bottom_page_selector_analysis_container"
                                 style={localStorage.getItem('activeView') === "bottom_page_selector_analysis" || !localStorage.getItem('activeView') ? {display: "block"} : {display: "none"}}>
                                <NetworkStats taskId={""} sessionId={""}/>
                                <EnrichmentTable taskId={""} sessionId={""} species={species}
                                                 proteinCount={proteinCount} nodeCount={nodeCount}/>
                            </div>
                            <EdgeSelector taskId={""} sessionId={""} requiredScore={requiredScore}/>
                            <NodeSelector taskId={""} sessionId={""} requiredScore={requiredScore}/>
                            <EnrichmentFigure taskId={""} sessionId={""}/>
                        </>
                    ) : <PollingMessage/>}
            </div>
        </>
    );
}

export default NetworkPageIndexed

