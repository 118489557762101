import React, {useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";

interface NetworkNode {
    item: string
    preferredName: string
    identifier: string
    externalId: string
    annotation: string
    color: string
}

const NodeSelector = ({
                          taskId,
                          sessionId,
                          requiredScore
                      }: { taskId: string, sessionId: string, requiredScore: string }) => {
    const [uniqueNodesDict, setUniqueNodes] = useState({} as any);
    const [originalNodes, setOriginalNodes] = useState({} as any);
    const [edgesDataSet, setEdgesDataSet] = useState(false);
    const [svgLoaded, setsvg_loaded] = useState(false);
    const [organism, setOrganism] = useState("");
    const navigate = useNavigate()
    const {showBoundary} = useErrorBoundary();

    const {UrlSTRING,} = useContext(GlobalContext);

    const location = useLocation()
    const {
        dataAll
    } = location.state;

    useEffect(() => {
        const checkSvg = () => {
            const svg = document.getElementById("svg_network_image");
            if (svg) {
                setsvg_loaded(true);
            }
        };
        checkSvg();

        const observer = new MutationObserver(checkSvg);
        observer.observe(document, {subtree: true, childList: true});
        return () => {
            observer.disconnect();
        };
    }, [taskId, sessionId]);

    useEffect(() => {
        // if (!svgLoaded) return;
        const fetchNodeData = async () => {
            try {
                const linkWrapperElements = document.querySelectorAll(".nwnodecontainer");
                if (linkWrapperElements !== null) {
                    var url;
                    let data
                    if (taskId) {
                        url = `${UrlSTRING}/cgi/api?apiMethod=all_nodes_data&apiOutputType=json&taskId=${taskId}&sessionId=${sessionId}&requiredScore=${requiredScore}&caller_identity=mobileapp`
                        const response = await fetch(url);
                        data = await response.json();
                    } else {
                        data = dataAll[0]["allNodesData"];
                    }

                    if (!data) {
                        return;
                    }
                    setOrganism(data[0]['species']);
                    data = data[0]['nodes'];
                    let newUniqueNodesDict = {} as any;
                    for (const node in data) {
                        newUniqueNodesDict[node] = {} as NetworkNode;
                        newUniqueNodesDict[node].item = node;
                        newUniqueNodesDict[node].identifier = data[node].identifier;
                        newUniqueNodesDict[node].nameToShow = data[node].nameToShow;
                        newUniqueNodesDict[node].annotation = data[node].annotation;
                    }
                    // Get the keys of newUniqueNodesDict
                    const keys = Object.keys(newUniqueNodesDict);
                    keys.sort((a, b) => newUniqueNodesDict[a].nameToShow.localeCompare(newUniqueNodesDict[b].nameToShow));
                    let newUniqueNodesDictSorted = {} as any;
                    keys.forEach(key => {
                        newUniqueNodesDictSorted[key] = newUniqueNodesDict[key];
                    });
                    setUniqueNodes(newUniqueNodesDictSorted);
                    setOriginalNodes(newUniqueNodesDictSorted);

                    // initialize setIsExpanded to false for each node
                    let newIsExpanded = {} as any;
                    for (const node in newUniqueNodesDictSorted) {
                        newIsExpanded[node] = false;
                    }
                    setIsExpanded(newIsExpanded);
                }
            } catch (error) {
                setTimeout(() => {
                    showBoundary(error);
                }, 2000); // Delay of 2 seconds
            }
        }

        fetchNodeData();
        setEdgesDataSet(true);
    }, [taskId, sessionId, svgLoaded, requiredScore, navigate, UrlSTRING, showBoundary]);


    const OpenNodePage = (identifier: string) => {
        localStorage.setItem('activeView', "bottom_page_selector_nodes");
        localStorage.setItem('scrollPosition', JSON.stringify(window.scrollY));
        // let organism = document.getElementsByName("species")[0].getAttribute("value");
        // navigate to node page
        let url = `/node/${organism}/${identifier}`;
        navigate(url)
        return;
    }

    const searchForNode = (searchValue: string) => {
        // look for the nodes that start with this search in originalNodes value and replace displayed nodes with them in uniqueNodesDict

        if (searchValue === "") {
            setUniqueNodes(originalNodes)
        }

        let newUniqueNodesDict = {} as any;

        for (const node in originalNodes) {
            if (originalNodes[node].nameToShow.toLowerCase().includes(searchValue.toLowerCase()) || originalNodes[node].identifier.toLowerCase().includes(searchValue.toLowerCase())
                || (originalNodes[node].nameToShow + " [" + originalNodes[node].identifier + "]").toLowerCase().includes(searchValue.toLowerCase())
            ) {
                newUniqueNodesDict[node] = originalNodes[node];
            }
        }
        setUniqueNodes(newUniqueNodesDict);
    }

    const [isExpanded, setIsExpanded] = useState({} as any);

    const toggleExpanded = (node: string) => {
        setIsExpanded((prevIsExpanded: Record<string, boolean>) => ({
            ...prevIsExpanded,
            [node]: !prevIsExpanded[node] // Toggle the value for the specified node
        }));
    };

    const wrapText = (text: string, node: string, maxLines: number, container: HTMLElement): {
        truncatedTextItem: string;
        truncatedItem: boolean
    } => {
        if (isExpanded[node]) {
            return {truncatedTextItem: text, truncatedItem: false}; // Return the original text if the node is expanded
        }

        // Create a temporary span to measure the rendered text
        const tempSpan = document.createElement("span");

        // give span the same width as the container
        tempSpan.style.width = `${container.offsetWidth}px`;

        document.body.appendChild(tempSpan);

        let truncatedText = text;
        tempSpan.innerText = text;

        // Truncate until the height fits within the maxLines
        let truncated = false;
        while (tempSpan.offsetHeight > maxLines * parseFloat(getComputedStyle(tempSpan).lineHeight || "0")) {
            // remove 1 word from the end of the text
            truncatedText = truncatedText.replace(/\s+\S*$/, "");
            tempSpan.innerText = truncatedText + "...(More)";
            truncated = true;
        }

        if (truncated) {
            tempSpan.innerText = truncatedText + "...";
        }

        document.body.removeChild(tempSpan);
        return {truncatedTextItem: truncatedText, truncatedItem: truncated};
    };

    const containerRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const [truncatedText, setTruncatedText] = useState<{ [key: string]: string }>({});
    const [truncatedTextB, setTruncatedTextBoolean] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const updatedTruncatedText: { [key: string]: string } = {};
        const updatedTruncatedBoolean: { [key: string]: boolean } = {};

        Object.keys(uniqueNodesDict).forEach((item) => {
            const container = containerRefs.current[item];
            if (container) {
                const {
                    truncatedTextItem,
                    truncatedItem
                } = wrapText(uniqueNodesDict[item].annotation, item, 3, container);
                updatedTruncatedText[item] = truncatedTextItem;
                updatedTruncatedBoolean[item] = truncatedItem;
            }
        });

        setTruncatedText(updatedTruncatedText);
        setTruncatedTextBoolean(updatedTruncatedBoolean);

    }, [uniqueNodesDict]); // Recalculate on data change

    return (
        <div className="main_page_content_container" id="bottom_page_selector_nodes_container"
             style={localStorage.getItem('activeView') === "bottom_page_selector_nodes" ? {display: "block"} : {display: "none"}}>
            {edgesDataSet ? (
                <div className="settings_wrap" style={{
                    display: 'inline-block',
                    paddingRight: "2%",
                    paddingLeft: "2%",
                    width: "100%"
                }}>
                    <div className="settings_box_wrap">
                        <div className="data_settings_wrap">
                            <div className="error_info_box">
                                <div className="box_body data_settings_body">
                                    <input
                                        id="search_nodes_input"
                                        className="data_settings_search_large"
                                        style={{width: "100%", height: "40px"}}
                                        autoComplete="off"
                                        placeholder={"Search for nodes ..."}
                                        onChange={(e) => {
                                            let searchValue = e.target.value;
                                            searchForNode(searchValue);
                                        }}
                                    >
                                    </input>
                                    <div className="interactors_table">
                                        <div className={"interactor_header node_rounded_header"}
                                             style={{height: "40px"}}>
                                            List of all nodes
                                        </div>
                                        {Object.keys(uniqueNodesDict).length > 0 ? (
                                            <>
                                                {Object.keys(uniqueNodesDict)
                                                    .sort((a, b) => uniqueNodesDict[a].nameToShow.localeCompare(uniqueNodesDict[b].nameToShow))
                                                    .map((item: any, index, array) => (
                                                        <div
                                                            className={(index === array.length - 1 ? "last_row_wrap" : "row_wrap")}
                                                            key={item}>
                                                            <div className={"cell_large"}
                                                                 style={{width: "100%", padding: "3vw"}}>
                                                                <div className={"interactor_title"}
                                                                     style={{
                                                                         display: "flex",
                                                                         flexWrap: "wrap",
                                                                         overflowWrap: "break-word",
                                                                         wordWrap: "break-word",
                                                                         whiteSpace: "normal"
                                                                     }}
                                                                >
                                                                <span style={{fontWeight: "bold"}}>
                                                                    {uniqueNodesDict[item].nameToShow}&nbsp;
                                                                </span>
                                                                    <i>[{uniqueNodesDict[item].identifier}]</i>
                                                                    <button
                                                                        className={"show_button"}
                                                                        style={{
                                                                            marginLeft: "auto",
                                                                            whiteSpace: "nowrap" // Prevents button text from breaking
                                                                        }}
                                                                        onClick={() => {
                                                                            OpenNodePage(uniqueNodesDict[item].identifier);
                                                                        }}
                                                                    >
                                                                        Node details
                                                                    </button>
                                                                </div>

                                                                <div style={{fontSize: "0.9rem", overflow: "hidden"}}>
                                                                    <div
                                                                        ref={(el) => (containerRefs.current[item] = el)}>
                                                                        {isExpanded[item]
                                                                            ? uniqueNodesDict[item].annotation
                                                                            : truncatedText[item] || uniqueNodesDict[item].annotation}&nbsp;

                                                                        {/* Show (More) or (Less) only if the text is truncated */}
                                                                        {truncatedTextB[item] ? (
                                                                            <span onClick={() => toggleExpanded(item)}
                                                                                  style={{
                                                                                      fontWeight: "bold",
                                                                                      cursor: "pointer"
                                                                                  }}>
                                                                                {isExpanded[item] ? "(Less)" : "(More)"}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                            </>) : (
                                            <div className={"last_row_wrap"}>
                                                <div className={"cell_large"}>
                                                    <div className={"interactor_title"}>
                                                        Sorry, the searched node was not found in the current network
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </div>
    );
};

export default NodeSelector;
