import React, {ChangeEventHandler, useContext, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import updateInput from "../utils/update_input";
import SearchOrganisms from "../components/SearchOrganisms";
import PollingMessage from "../components/PollingMessage";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";
import {Helmet} from "react-helmet-async";

interface DataObj {
    rank: number;
    termId: string;
    description: string;
    category: string;
    categoryDisplay: string;
    member_count: number;
    termLinkout: string;
    Error?: string;
    ErrorMessage?: string;
}

interface TermsDataObj {
    description: string;
    termIdCompact: string;
    categoryText: string;
}

const SearchTerm = () => {

    document.title = "STRING: functional protein association networks";
    const navigate = useNavigate();

    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const {UrlSTRING,} = useContext(GlobalContext);
    const {showBoundary} = useErrorBoundary();

    async function fetchInfo(url1: string, url2: string, network_term_text: string, species_text: string) {

        try {
            if (species_text === undefined || network_term_text === undefined) {
                return;
            }

            if (url1 !== '') {
                const res = await fetch(url1);
                const d: DataObj[] = await res.json();
                const formattedData = d.map((item) => ({
                    termId: item.termId,
                    rank: item.rank,
                    description: item.description,
                    category: item.category,
                    categoryDisplay: item.categoryDisplay,
                    member_count: item.member_count,
                    termLinkout: item.termLinkout,
                    Error: item.Error,
                    ErrorMessage: item.ErrorMessage
                }));

                if (formattedData[0].Error) {
                    let errorData = {
                        title: formattedData[0].Error,
                        message: formattedData[0].ErrorMessage
                    }
                    navigate('/error', {state: errorData});
                    return;
                }

                if (formattedData.length === 1 && formattedData[0].termId === network_term_text) {
                    const result = await updateInput(
                        "term", species_text, formattedData[0].termId, "400",
                        "functional", "evidence", "0", "0",
                        "on", "on", "on", "on", "on", "on",
                        "on", "", ""
                    );
                    if (result?.path === 'Not found') {
                        navigate('/error', {state: result.newData});
                        return;
                    }

                    if (result) {
                        navigate(result.path, {state: result.newData});
                        return;
                    }
                }

            }
        } catch (error) {
            setTimeout(() => {
                showBoundary(error);
            }, 2000); // Delay of 2 seconds
        }
        navigate(url2);
        return;
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitting(true);
        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        if (event.currentTarget.species_text.value === '') {

            // jump to error page saying you should prove the species name
            let errorData = {
                title: "Error ...",
                message: "Please provide a species name before submitting."
            }
            navigate('/error', {state: errorData});
            return;

        }

        let url1 = `${UrlSTRING}/cgi/api?apiMethod=get_network_terms&apiOutputType=json&network_term_text=${event.currentTarget.network_term_text.value}&species=${event.currentTarget.species_text.value}&caller_identity=mobileapp`
        let url2 = `/SearchTermDisambiguation/${event.currentTarget.species_text.value}/${event.currentTarget.network_term_text.value}`
        fetchInfo(url1, url2, event.currentTarget.network_term_text.value, event.currentTarget.species_text.value);

        submit_button.disabled = false;
    }

    const toggleInputExample = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {

        event.preventDefault();

        let example_button1 = document.getElementById("1");
        let example_button2 = document.getElementById("2");
        let example_button3 = document.getElementById("3");
        let example_button4 = document.getElementById("4");

        if (id === "1") {
            example_button1?.classList.add("active_button");
            example_button2?.classList.remove("active_button");
            example_button3?.classList.remove("active_button");
            example_button4?.classList.remove("active_button");

            let input = document.getElementById("primary_input:term") as HTMLInputElement | null;
            if (input) {
                input?.setAttribute("value", "Melanoma");
                input.innerHTML = "Melanoma";
                input.value = "Melanoma";
            }

            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", "Homo sapiens");
                organism_input.innerHTML = "Homo sapiens";
                organism_input.value = "Homo sapiens";
            }

        } else if (id === "2") {
            example_button1?.classList.remove("active_button");
            example_button2?.classList.add("active_button");
            example_button3?.classList.remove("active_button");
            example_button4?.classList.remove("active_button");

            let input = document.getElementById("primary_input:term") as HTMLInputElement | null;
            if (input) {
                input?.setAttribute("value", "Carbon fixation");
                input.innerHTML = "Carbon fixation";
                input.value = "Carbon fixation";
            }
            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", "Arabidopsis thaliana");
                organism_input.innerHTML = "Arabidopsis thaliana";
                organism_input.value = "Arabidopsis thaliana";
            }

        } else if (id === "3") {
            example_button1?.classList.remove("active_button");
            example_button2?.classList.remove("active_button");
            example_button3?.classList.add("active_button");
            example_button4?.classList.remove("active_button");

            let input = document.getElementById("primary_input:term") as HTMLInputElement | null;
            if (input) {
                input?.setAttribute("value", "PMID:33743212");
                input.innerHTML = "PMID:33743212";
                input.value = "PMID:33743212";
            }
            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", "Homo sapiens");
                organism_input.innerHTML = "Homo sapiens";
                organism_input.value = "Homo sapiens";
            }

        } else if (id === "4") {
            example_button1?.classList.remove("active_button");
            example_button2?.classList.remove("active_button");
            example_button3?.classList.remove("active_button");
            example_button4?.classList.add("active_button");

            let input = document.getElementById("primary_input:term") as HTMLInputElement | null;
            if (input) {
                input?.setAttribute("value", "Cell cycle arrest");
                input.innerHTML = "Cell cycle arrest";
                input.value = "Cell cycle arrest";
            }
            let organism_input = document.getElementById("species_text") as HTMLInputElement | null;
            if (organism_input) {
                organism_input?.setAttribute("value", "Homo sapiens");
                organism_input.innerHTML = "Homo sapiens";
                organism_input.value = "Homo sapiens";
            }
        }
    }

    const [suggestionsVisible, setSuggestionsVisible] = useState<boolean>(false);
    const [termsSuggestions, setSpeciesSuggestions] = useState<TermsDataObj[]>([]);

    const prevRequest = useRef<AbortController | null>(null);

    const queryTermsNames: ChangeEventHandler<HTMLInputElement> = async (event) => {
        // Create an abort controller
        const controller = new AbortController();
        const signal = controller.signal;

        // Cancel previous requests
        if (prevRequest.current) {
            prevRequest.current.abort();
        }
        prevRequest.current = controller;

        if (event.currentTarget.value.trim() === "") {
            setSuggestionsVisible(false)
            setSpeciesSuggestions([])
            return
        }

        let url = `${UrlSTRING}/cgi/api?apiMethod=query_terms_names&apiOutputType=json&terms_text=${event.currentTarget.value}&caller_identity=mobileapp`;
        try {
            const response = await fetch(url, {signal});
            const dataObj: TermsDataObj[] = await response.json();
            if (!dataObj) {
                return;
            }
            // Check if the request is still relevant
            if (controller.signal.aborted) {
                return;
            }
            const data = dataObj.map((item) => ({
                description: item.description,
                termIdCompact: item.termIdCompact,
                categoryText: item.categoryText
            }));
            setSpeciesSuggestions(data)
            setSuggestionsVisible(true)
        } catch (error) {
            // Handle errors
            setSpeciesSuggestions([])
            setSuggestionsVisible(false)
        }
    };

    return (
        <>
            <Helmet>
                <title> STRING: functional protein association networks</title>
            </Helmet>

            {!submitting ? (
                    <div className="menu_content">
                        <div className="search_input_wrap">
                            <div className="search_input_box">
                                <div className="box_title">Geneset by<br/> Pathway / Process / Disease / Publication</div>
                                <div className="box">
                                    <form id="input_form_term" onSubmit={handleSubmit}
                                          method="post" spellCheck="false" encType="multipart/form-data">
                                        <div>
                                            <input type="hidden" name="sessionId" value="btoPeBXp5k4Z"/>
                                            <input type="hidden" name="have_user_input" value="2"/>
                                        </div>
                                        <div className="row_100">
                                            <div className="wrap_input_with_examples">
                                                <label className="label">Search term:</label>
                                                <span className="example_small">
                                        <button className="string_example small_button" id={"1"}
                                                onClick={(e) => toggleInputExample(e, "1")}>1</button>
                                                    &nbsp;&nbsp;
                                                    <button className="string_example small_button" id={"2"}
                                                            onClick={(e) => toggleInputExample(e, "2")}>2</button>
                                                    &nbsp;&nbsp;
                                                    <button className="string_example small_button" id={"3"}
                                                            onClick={(e) => toggleInputExample(e, "3")}>3</button>
                                                    &nbsp;&nbsp;
                                                    <button className="string_example small_button" id={"4"}
                                                            onClick={(e) => toggleInputExample(e, "4")}>4</button>
                                        </span>
                                                <div className="wrap_search_item">
                                                    <input className="input submit_on_enter"
                                                           id="primary_input:term" maxLength={50}
                                                           name="network_term_text"
                                                           type="search" autoComplete="on"
                                                           onChange={queryTermsNames}
                                                           onBlur={() => {
                                                               setTimeout(() => {
                                                                   setSuggestionsVisible(false)
                                                               }, 100)
                                                           }}
                                                           required
                                                    />
                                                    {termsSuggestions.length > 0 ? (
                                                        <div
                                                            className="filter__dropdown"
                                                            style={{
                                                                marginTop: "7.5vh",
                                                                display: suggestionsVisible ? "block" : "none"
                                                            }}
                                                        >
                                                            {Object.keys(termsSuggestions).map((item: string, index: number) => (
                                                                <div
                                                                    key={index} // Adding a unique key prop for each iterated element is recommended in React
                                                                    className="filter__dropdown_button"
                                                                    id={item}
                                                                    onClick={() => {
                                                                        const termsText = document.getElementById("primary_input:term") as HTMLInputElement | null;
                                                                        if (termsText) {
                                                                            termsText.value = termsSuggestions[item as any].termIdCompact; // Assuming speciesSuggestions is an object mapping species names to values
                                                                            setSuggestionsVisible(false)
                                                                        }
                                                                    }}
                                                                >
                                                                    {termsSuggestions[item as any].description}<br/>
                                                                    <span
                                                                        className={"enrichment_table_small_title"}> {termsSuggestions[item as any].termIdCompact} ({termsSuggestions[item as any].categoryText})</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap_input">
                                            <div style={{height: 3, verticalAlign: "top"}}>
                                                <div id="organism_text_input_term">
                                                    <SearchOrganisms/>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="button large_submit_button">
                                            <div></div>
                                            &nbsp;Search&nbsp;
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className={"shadow"}></div>
                    </div>) :
                (<PollingMessage/>)}
        </>
    );
}
export default SearchTerm;