import StringNetwork from "../components/StringNetwork";
import EnrichmentTable from "../components/EnrichmentTable";
import PageContentNav from "../components/PageContentNav";
import NetworkStats from "../components/NetworkStats";
import Settings from "../components/Settings";
import EdgeSelector from "../components/EdgeSelector";
import React from "react";
import {useLocation, useParams} from "react-router-dom";
import ViewersTable from "../components/ViewersTable";
import NodeSelector from "../components/NodeSelector";
import EnrichmentFigure from "../components/EnrichmentFigure";


const NetworkPage = () => {
    const {taskId, sessionId} = useParams();
    const location = useLocation()
    if (!location.state) {
        // get data from string then reload this page
        window.location.href = `/GetTaskDataFromSTRING/${taskId}/${sessionId}/`
        return null
    } else {

        // optimise large network display
        var node_count = location.state.identifier.split("%0d").length + parseInt(location.state.limit, 10) + parseInt(location.state.additionalNetworkNodes, 10);
        var too_large_network_count = 200;

        if (node_count > too_large_network_count) {
            location.state.networkFlavor = "confidence"
            location.state.blockStructures = "on"
            location.state.requiredScore = "900"
        }

        const {
            species,
            identifier,
            requiredScore,
            networkType,
            networkFlavor,
            limit,
            additionalNetworkNodes,
            channel1,
            channel2,
            channel3,
            channel4,
            channel5,
            channel6,
            channel7,
            proteinCount,
            nodeCount,
            pageTitle,
            sessionId,
            // displayMode,
            // colorblindEdges,
            bubbleNodeDesign,
            blockStructures,
            centerNodeLabels,
            showQueryNodeLabels,
            hideSingletons,
            hideLabels,
            dataSettingsLabel,
        } = location.state;

        document.title = pageTitle ? pageTitle : "STRING interaction network"

        return (
            <div>
                <StringNetwork/>
                <PageContentNav/>
                {taskId && sessionId && species && requiredScore ?
                    (
                        <>
                            <ViewersTable taskId={taskId} sessionId={sessionId}/>
                            <Settings taskId={taskId}
                                      sessionId={sessionId}
                                      species={species}
                                      identifier={identifier}
                                      requiredScore={requiredScore}
                                      networkType={networkType ? networkType : "functional"}
                                      networkFlavor={networkFlavor ? networkFlavor : "evidence"}
                                      limit={limit ? limit : 400}
                                      additionalNetworkNodes={additionalNetworkNodes ? additionalNetworkNodes : 0}
                                      channel1={channel1 ? channel1 : "on"}
                                      channel2={channel2 ? channel2 : "on"}
                                      channel3={channel3 ? channel3 : "on"}
                                      channel4={channel4 ? channel4 : "on"}
                                      channel5={channel5 ? channel5 : "on"}
                                      channel6={channel6 ? channel6 : "on"}
                                      channel7={channel7 ? channel7 : "on"}
                                      // displayMode={displayMode? displayMode : "svg"}
                                      // colorblindEdges={colorblindEdges ? colorblindEdges : "off"}
                                      bubbleNodeDesign={bubbleNodeDesign ? bubbleNodeDesign: "off"}
                                      blockStructures={blockStructures ? blockStructures: "off"}
                                      centerNodeLabels={centerNodeLabels ? centerNodeLabels: "off"}
                                      showQueryNodeLabels={showQueryNodeLabels ? showQueryNodeLabels: "off"}
                                      hideSingletons={hideSingletons ? hideSingletons: "off"}
                                      hideLabels={hideLabels ? hideLabels: "off"}
                                      dataSettingsLabel={dataSettingsLabel ? dataSettingsLabel: "12"}
                            />
                            <div id="bottom_page_selector_analysis_container"
                                 style={localStorage.getItem('activeView') === "bottom_page_selector_analysis" || !localStorage.getItem('activeView') ? {display: "block"} : {display: "none"}}>
                                <NetworkStats taskId={taskId} sessionId={sessionId}/>
                                <EnrichmentTable taskId={taskId} sessionId={sessionId} species={species}
                                                 proteinCount={proteinCount} nodeCount={nodeCount}/>
                            </div>
                            <EdgeSelector taskId={taskId} sessionId={sessionId} requiredScore={requiredScore}/>
                            <NodeSelector taskId={taskId} sessionId={sessionId} requiredScore={requiredScore}/>
                            <EnrichmentFigure taskId={taskId} sessionId={sessionId}/>
                        </>
                    ) : null}
            </div>
        );

    }
}

export default NetworkPage

