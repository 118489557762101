import React, {useCallback, useContext, useEffect} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";

const StringNetwork = () => {

    let {taskId, sessionId} = useParams();

    const location = useLocation()
    const {
        species,
        identifier,
        requiredScore,
        networkType,
        networkFlavor,
        limit,
        additionalNetworkNodes,
        channel1,
        channel2,
        channel3,
        channel4,
        channel5,
        channel6,
        channel7,
        taskIdGI,
        sessionIdGI,
        // displayMode,
        // colorblindEdges,
        bubbleNodeDesign,
        blockStructures,
        centerNodeLabels,
        showQueryNodeLabels,
        hideSingletons,
        hideLabels,
        dataSettingsLabel,
    } = location.state;

    const {UrlSTRING,} = useContext(GlobalContext);

    if (taskId === undefined && sessionId === undefined) { // coming from networkPageIndexed
        taskId = taskIdGI;
        sessionId = sessionIdGI;
    }
    // Define a state variable to track whether the script has been loaded

    const forEachNode = (array: any[], callback: (item: any) => void, scope?: any) => {
        for (let i = 0; i < array.length; i++) {
            callback.call(scope, array[i]);
        }
    };

    interface JsCommGlobals {
        session_id: string;
        task_id: string;
        web_cgi_dir?: string;
        web_images_dir?: string;
    }

    interface SVGMetaInfoNode {
        elm: HTMLElement;
        x: number;
        y: number;
        radius: number;
        ix: number;
        iy: number;
        links: any[];
        arrows: any[];
    }

    const initNetworkInteractiveFunctionalities = useCallback(() => {

        const svgMetainfoNodes: { [key: string]: SVGMetaInfoNode } = {};

        const svgelm = document.getElementById("svg_network_image");

        if (!svgelm) return;

        const nodeWrapperElements = Array.from(document.querySelectorAll(".nwnodecontainer"));
        if (nodeWrapperElements !== null) {
            forEachNode(nodeWrapperElements, (elm) => {
                const thisId = elm.id;
                svgMetainfoNodes[thisId] = {
                    elm,
                    x: Number(elm.getAttribute("data-x_pos")),
                    y: Number(elm.getAttribute("data-y_pos")),
                    radius: Number(elm.getAttribute("data-radius")),
                    ix: Number(elm.getAttribute("data-x_pos")),
                    iy: Number(elm.getAttribute("data-y_pos")),
                    links: [],
                    arrows: []
                };
            });
        }

        const edgeElements = document.getElementsByClassName("nwnodecontainer");
        if (edgeElements.length > 0) {
            // Converting HTMLCollection to array
            Array.from(edgeElements).forEach((edge) => {
                edge.addEventListener("click", handle_network_node_click);
            });
        }
    }, []);

    function handle_network_node_click(event: Event) {

        // select the node in edge view
        let node_list = document.getElementById("node_list") as HTMLSelectElement;
        if (!node_list) return;
        // get node.id
        let nodeId = (event.currentTarget as HTMLElement).id.split(".")[1];

        let option;
        let nodeFullName
        for (let i = 0; i < node_list.options.length; i++) {
            if (node_list.options[i].value === nodeId) {
                option = node_list.options[i];
                nodeFullName = option.innerHTML
                option.selected = true;
                break;
            }
        }
        // Trigger the 'change' event on the <select> element
        node_list.dispatchEvent(new Event('change', {bubbles: true}));

        // select the node in search nodes view
        let search_nodes_input = document.getElementById("search_nodes_input") as HTMLInputElement;
        // let circle = (event.currentTarget as HTMLElement).getElementsByTagName("text")[0];

        // Get the native input value setter
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;

        // Call the native input value setter to set the new value
        nativeInputValueSetter?.call(search_nodes_input, nodeFullName);

        // Create a new input event
        const event_ = new Event('input', {bubbles: true});

        // Dispatch the input event on the input element
        search_nodes_input.dispatchEvent(event_);

        // workaround to make input field change visible
        // make search_nodes_input read-only
        search_nodes_input.readOnly = true;
        // focus-visible on the search nodes input
        search_nodes_input.focus()
        // make search_nodes_input read-only
        search_nodes_input.readOnly = false;

        // remove focus after 3 seconds
        setTimeout(() => {
            search_nodes_input.blur();
        }, 500);

        // select nodes views
        const bottomPageContentSettings = document.getElementById('bottom_page_selector_settings_container');
        const bottomPageContentAnalysis = document.getElementById('bottom_page_selector_analysis_container');
        const bottomPageContentEdges = document.getElementById('bottom_page_selector_edges_container');
        const bottomPageContentNodes = document.getElementById('bottom_page_selector_nodes_container');
        const bottomPageContentViewers = document.getElementById('bottom_page_selector_viewers_container');
        const bottomPageContentFigure = document.getElementById('bottom_page_enrichment_figure_container');

        // show the button to be active (add class active_button)
        const analysisButton = document.getElementById('bottom_page_selector_analysis');
        const edgesButton = document.getElementById('bottom_page_selector_edges');
        const nodesButton = document.getElementById('bottom_page_selector_nodes');
        const settingsButton = document.getElementById('bottom_page_selector_settings');
        const viewersButton = document.getElementById('bottom_page_selector_viewers');
        const figureButton = document.getElementById('bottom_page_selector_figure');

        if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {

            // if the user is not on the edge view, switch to the node view otherwise leave him in the edge view
            if (bottomPageContentEdges.style.display === 'none') {
                bottomPageContentSettings.style.display = 'none';
                bottomPageContentAnalysis.style.display = 'none';
                bottomPageContentEdges.style.display = 'none';
                bottomPageContentNodes.style.display = 'block';
                bottomPageContentViewers.style.display = 'none';
                bottomPageContentFigure.style.display = 'none';

                settingsButton?.classList.remove('active_button');
                edgesButton?.classList.remove('active_button');
                nodesButton?.classList.add('active_button');
                analysisButton?.classList.remove('active_button');
                viewersButton?.classList.remove('active_button');
                figureButton?.classList.remove('active_button');
            }
        }
    }

    // Initialize event handlers
    window.addEventListener("load", initNetworkInteractiveFunctionalities);

    useEffect(() => {
        const jsCommGlobals: JsCommGlobals = {
            session_id: "",
            task_id: ""
        };
        // const getSTRING = (root_url: string, params: any) => {
        //     jsCommGlobals.web_cgi_dir = `${root_url}/cgi/`;
        //     jsCommGlobals.web_images_dir = `${root_url}/images/`;
        //
        //     const stringDiv = document.getElementById('stringEmbedded');
        //
        //     if (!stringDiv) return;
        //
        //     const form = new FormData();
        //     for (const key in params) {
        //         const value = params[key];
        //         if (key === 'identifiers') {
        //             const proteins_str = value.join('%0d');
        //             form.append(key, proteins_str);
        //         } else {
        //             form.append(key, value);
        //         }
        //     }
        //
        //     const xhr = new XMLHttpRequest();
        //     xhr.onreadystatechange = function () {
        //         if (xhr.readyState === XMLHttpRequest.DONE) {
        //             stringDiv.innerHTML = xhr.responseText;
        //             initNetworkInteractiveFunctionalities();
        //         }
        //     };
        //
        //     xhr.open('POST', `${UrlSTRING}/api/interactive_svg/network`, true);
        //
        //     // long running queries management
        //
        //     // Set timeout to a high value (in milliseconds)
        //     xhr.timeout = 600000; // 10 minutes
        //
        //     xhr.ontimeout = function () {
        //         console.error("The request timed out.");
        //     };
        //
        //     xhr.onerror = function () {
        //         console.error("An error occurred during the transaction");
        //     };
        //
        //     xhr.onload = function () {
        //         if (xhr.status === 200) {
        //             console.log("Request successful", xhr.responseText);
        //         } else {
        //             console.error("Error:", xhr.status, xhr.statusText);
        //         }
        //     };
        //
        //     xhr.send(form);
        // };
        const getSTRING = async (root_url: string, params: any) => {
            jsCommGlobals.web_cgi_dir = `${root_url}/cgi/`;
            jsCommGlobals.web_images_dir = `${root_url}/images/`;

            const stringDiv = document.getElementById('stringEmbedded');

            if (!stringDiv) return;

            const form = new FormData();
            for (const key in params) {
                const value = params[key];
                if (value) {
                    if (key === 'identifiers') {
                        const proteins_str = value.join('%0d');
                        form.append(key, proteins_str);
                    } else {
                        form.append(key, value);
                    }
                }
            }

            const fetchWithTimeoutAndRetry = async (url: any, options: any, retries = 3, timeout = 6000000) => {
                const controller = new AbortController();
                const {signal} = controller;
                const fetchOptions = {...options, signal};

                return new Promise((resolve, reject) => {
                    const timeoutId = setTimeout(() => {
                        controller.abort();
                        reject(new Error('Request timed out'));
                    }, timeout);

                    fetch(url, fetchOptions)
                        .then(response => {
                            clearTimeout(timeoutId);
                            if (!response.ok) {
                                if (retries > 0) {
                                    return fetchWithTimeoutAndRetry(url, options, retries - 1, timeout);
                                }
                                reject(new Error('Request failed'));
                            }
                            resolve(response);
                        })
                        .catch(error => {
                            clearTimeout(timeoutId);
                            if (retries > 0 && error.name !== 'AbortError') {
                                return fetchWithTimeoutAndRetry(url, options, retries - 1, timeout);
                            }
                            reject(error);
                        });
                });
            };

            try {
                const response: Response = await fetchWithTimeoutAndRetry(`${UrlSTRING}/api/interactive_svg/network`, {
                    method: 'POST',
                    body: form,
                    headers: {
                        'Accept': 'application/json',
                    },
                }) as Response;

                // const responseText =
                stringDiv.innerHTML = await response.text();
                initNetworkInteractiveFunctionalities();

            } catch (error) {
                console.error('Error:', error);
            }
        };

        getSTRING(`${UrlSTRING}`, {
            'taskId': taskId,
            'sessionId': sessionId,
            'species': species,
            'identifier': identifier,
            'networkFlavor': networkFlavor,
            'embedLogoImage': 'False',
            'limit': limit,
            'additionalNetworkNodes': additionalNetworkNodes,
            'networkType': networkType,
            'requiredScore': requiredScore,
            'channel1': channel1,
            'channel2': channel2,
            'channel3': channel3,
            'channel4': channel4,
            'channel5': channel5,
            'channel6': channel6,
            'channel7': channel7,
            'includeViewBox': 'True',
            'caller_identity': 'mobileapp',
            'openIdAuthentifiedId': document.cookie.split(';').find((cookie) => cookie.includes('m.string_login_credentials'))?.split('=')[1],
            // 'network_display_mode': displayMode? displayMode: 'svg',
            // 'colorblind_edges': colorblindEdges? colorblindEdges: 'off',
            'bubble_node_design': bubbleNodeDesign ? bubbleNodeDesign : 'off',
            'block_structure_pics_in_bubbles': blockStructures,
            'center_node_labels': centerNodeLabels ? centerNodeLabels : 'off',
            'show_query_node_labels': showQueryNodeLabels ? showQueryNodeLabels : 'off',
            'hide_disconnected_nodes': hideSingletons ? hideSingletons : 'off',
            'hide_node_labels': hideLabels ? hideLabels : 'off',
            'custom_label_font_size': dataSettingsLabel ? dataSettingsLabel : '12',
        });

    }, [species, identifier, taskId, sessionId, networkFlavor, limit, additionalNetworkNodes, networkType, requiredScore, channel1,
        channel2, channel3, channel4, channel5, channel6, channel7, initNetworkInteractiveFunctionalities, UrlSTRING,
        bubbleNodeDesign, blockStructures, centerNodeLabels, showQueryNodeLabels,
        hideSingletons, hideLabels, dataSettingsLabel]); //displayMode,


    return (
        <>
            <div className="svg-container">
                <div id="stringEmbedded">
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                         className='oversized_input_wait'>
                        {/*<img src={`/images/oversized_input_spinner.gif`} alt='waiting spinner'/>*/}
                        <div className="spinner"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StringNetwork;


