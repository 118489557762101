import React, {ChangeEventHandler, useContext, useRef, useState} from "react";
import {GlobalContext} from "./GlobalProvider";

interface SpeciesDataObj {
    taxid: string;
    name: string;
}

const SearchOrganisms = () => {

    const {UrlSTRING,} = useContext(GlobalContext);
    const [suggestionsVisible, setSuggestionsVisible] = useState<boolean>(false);
    const [speciesSuggestions, setSpeciesSuggestions] = useState<SpeciesDataObj[]>([]);

    const prevRequest = useRef<AbortController | null>(null);

    const querySpeciesNames: ChangeEventHandler<HTMLInputElement> = async (event) => {
        // Create an abort controller
        const controller = new AbortController();
        const signal = controller.signal;

        // Cancel previous requests
        if (prevRequest.current) {
            prevRequest.current.abort();
        }
        prevRequest.current = controller;

        if (event.currentTarget.value.trim() === "") {
            setSpeciesSuggestions([])
            setSuggestionsVisible(false)
            return
        }

        let url = `${UrlSTRING}/cgi/api?apiMethod=query_species_names&apiOutputType=json&species_text=${event.currentTarget.value}&caller_identity=mobileapp`;

        if (window.location.href.includes("SearchTerm")) {
            url = url + "&show_clades=0";
        }
        try {
            const response = await fetch(url, {signal});
            const dataObj: SpeciesDataObj[] = await response.json();
            if (!dataObj) {
                setSpeciesSuggestions([])
                setSuggestionsVisible(false)
                return;
            }
            // Check if the request is still relevant
            if (controller.signal.aborted) {
                return;
            }
            const data = dataObj.map((item) => ({
                taxid: item.taxid,
                name: item.name
            }));

            setSpeciesSuggestions(data)
            setSuggestionsVisible(true)

        } catch (error) {
            setSpeciesSuggestions([])
            setSuggestionsVisible(false)
        }
    };

    return (
        <>
            <div className="wrap_search_item">
                <label className="label">Organisms:</label>
                <input
                    className="input stringdb_organism_input"
                    type="search"
                    name="species_text"
                    style={{background: "white"}}
                    id="species_text"
                    autoComplete="off"
                    placeholder="auto-detect"
                    onChange={querySpeciesNames}
                    onBlur={() => {
                        setTimeout(() => {
                            setSuggestionsVisible(false)
                        }, 100)
                    }}
                    // if on organism page, make this field required
                    required={window.location.href.includes("SearchOrganism")}
                />
                {speciesSuggestions.length > 0 ? (
                    <div className="filter__dropdown"
                         style={{display: suggestionsVisible ? "block" : "none"}}
                    >
                        {Object.keys(speciesSuggestions).map((item: string, index: number) => (
                            <div
                                key={index} // Adding a unique key prop for each iterated element is recommended in React
                                className="filter__dropdown_button"
                                id={item}
                                onClick={() => {
                                    const speciesText = document.getElementById("species_text") as HTMLInputElement | null;
                                    if (speciesText) {
                                        speciesText.value = speciesSuggestions[item as any].name; // Assuming speciesSuggestions is an object mapping species names to values
                                        setSuggestionsVisible(false)
                                    }
                                }}
                            >
                                {speciesSuggestions[item as any].taxid}<br/>
                                <span
                                    className={"enrichment_table_small_title"}> {speciesSuggestions[item as any].name}</span>
                            </div>
                        ))}
                    </div>) : null}
            </div>
        </>
    );
}

export default SearchOrganisms;
