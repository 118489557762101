import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import updateInput from "../utils/update_input";
import {useErrorBoundary} from "react-error-boundary";
import Notification from "./Notification";
interface Object {
    [key: string]: any; // Define an index signature
}

const PageContentNav = () => {
    const location = useLocation()
    const {
        species,
        identifier,
        requiredScore,
        networkType,
        networkFlavor,
        limit,
        additionalNetworkNodes,
        channel1,
        channel2,
        channel3,
        channel4,
        channel5,
        channel6,
        channel7,
        sessionId
    } = location.state;

    let limitInt = parseInt(limit ?? ''); // Use optional chaining to handle undefined or empty string
    let additionalNetworkNodesInt = parseInt(additionalNetworkNodes ?? '0');

    const [moreFirstShellLimit, setMoreFirstShellLimit] = useState(0);
    const [moreSecondShellLimit, setMoreSecondShellLimit] = useState(0);
    const [lessFirstShellLimit, setLessFirstShellLimit] = useState(0);
    const [lessSecondShellLimit, setLessSecondShellLimit] = useState(0);
    const [isProcessingMore, setIsProcessingMore] = useState(false);
    const [isProcessingLess, setIsProcessingLess] = useState(false);

    const [svgLoaded, setsvg_loaded] = useState(false); // State to track if SVG is loaded

    const {showBoundary} = useErrorBoundary();

    useEffect(() => {
        // Function to check the presence of the SVG element in the DOM
        const checkSvg = () => {
            const svg = document.getElementById("svg_network_image"); // Finding the SVG element by ID
            if (svg) { // If SVG is found
                // Counting how many nodes are in the SVG
                const nrOfInputNodes = identifier.replaceAll('\r', '%0d').replaceAll('\n', '%0d').split('%0d').length;

                // from STRING code
                let moreFirstShellLimit = limitInt;
                let lessFirstShellLimit = limitInt;

                let moreSecondShellLimit = additionalNetworkNodesInt;
                let lessSecondShellLimit = additionalNetworkNodesInt;

                let smallestShrinkTarget = 0
                if (nrOfInputNodes === 1) {
                    smallestShrinkTarget = 2
                }
                if (nrOfInputNodes === 2) {
                    smallestShrinkTarget = 1
                }

                if (moreFirstShellLimit < 5) {
                    moreFirstShellLimit = 5
                } else if (moreFirstShellLimit < 10) {
                    moreFirstShellLimit = 10
                } else {
                    moreSecondShellLimit += 10
                }

                if (lessSecondShellLimit >= 10) {
                    lessSecondShellLimit -= 10
                } else if (lessSecondShellLimit > 0) {
                    lessSecondShellLimit = 0
                } else if (lessFirstShellLimit > 10) {
                    lessFirstShellLimit -= 10
                } else if (lessFirstShellLimit > 5) {
                    lessFirstShellLimit = 5
                } else if (lessFirstShellLimit > smallestShrinkTarget) {
                    lessFirstShellLimit = smallestShrinkTarget
                }

                setMoreFirstShellLimit(moreFirstShellLimit);
                setMoreSecondShellLimit(moreSecondShellLimit);
                setLessFirstShellLimit(lessFirstShellLimit);
                setLessSecondShellLimit(lessSecondShellLimit);

                setsvg_loaded(true); // Updating state to indicate SVG has finished loading
            }
        };

        // Initial check for the presence of SVG
        checkSvg();

        // Setting up a MutationObserver to watch for changes in the DOM (addition of SVG)
        const observer = new MutationObserver(checkSvg);
        observer.observe(document, {subtree: true, childList: true});

        // Cleanup function: Disconnecting the observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [identifier, additionalNetworkNodesInt, limitInt, networkType, requiredScore, species]);

    const handleSelectedBottomPageContent = (bottomPageContentId: string) => () => {

        const bottomPageContentSettings = document.getElementById('bottom_page_selector_settings_container');
        const bottomPageContentAnalysis = document.getElementById('bottom_page_selector_analysis_container');
        const bottomPageContentEdges = document.getElementById('bottom_page_selector_edges_container');
        const bottomPageContentNodes = document.getElementById('bottom_page_selector_nodes_container');
        const bottomPageContentViewers = document.getElementById('bottom_page_selector_viewers_container');
        const bottomPageContentFigure = document.getElementById('bottom_page_enrichment_figure_container');

        // show the button to be active (add class active_button)
        const analysisButton = document.getElementById('bottom_page_selector_analysis');
        const edgesButton = document.getElementById('bottom_page_selector_edges');
        const nodesButton = document.getElementById('bottom_page_selector_nodes');
        const settingsButton = document.getElementById('bottom_page_selector_settings');
        const viewersButton = document.getElementById('bottom_page_selector_viewers');
        const figureButton = document.getElementById('bottom_page_selector_figure');


        if (bottomPageContentId === 'bottom_page_selector_settings') {
            if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {
                bottomPageContentSettings.style.display = 'block';
                bottomPageContentAnalysis.style.display = 'none';
                bottomPageContentEdges.style.display = 'none';
                bottomPageContentViewers.style.display = 'none';
                bottomPageContentNodes.style.display = 'none';
                bottomPageContentFigure.style.display = 'none';

                analysisButton?.classList.remove('active_button');
                edgesButton?.classList.remove('active_button');
                nodesButton?.classList.remove('active_button');
                settingsButton?.classList.add('active_button');
                viewersButton?.classList.remove('active_button');
                figureButton?.classList.remove('active_button');
            }
        } else if (bottomPageContentId === 'bottom_page_selector_analysis') {
            if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {
                bottomPageContentSettings.style.display = 'none';
                bottomPageContentAnalysis.style.display = 'block';
                bottomPageContentEdges.style.display = 'none';
                bottomPageContentNodes.style.display = 'none';
                bottomPageContentViewers.style.display = 'none';
                bottomPageContentFigure.style.display = 'none';

                settingsButton?.classList.remove('active_button');
                edgesButton?.classList.remove('active_button');
                nodesButton?.classList.remove('active_button');
                analysisButton?.classList.add('active_button');
                viewersButton?.classList.remove('active_button');
                figureButton?.classList.remove('active_button');
            }
        } else if (bottomPageContentId === 'bottom_page_selector_edges') {
            if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {
                bottomPageContentSettings.style.display = 'none';
                bottomPageContentAnalysis.style.display = 'none';
                bottomPageContentEdges.style.display = 'block';
                bottomPageContentNodes.style.display = 'none';
                bottomPageContentViewers.style.display = 'none';
                bottomPageContentFigure.style.display = 'none';

                settingsButton?.classList.remove('active_button');
                edgesButton?.classList.add('active_button');
                nodesButton?.classList.remove('active_button');
                analysisButton?.classList.remove('active_button');
                viewersButton?.classList.remove('active_button');
                figureButton?.classList.remove('active_button');
            }
        // } else if (bottomPageContentId === 'bottom_page_selector_legend') {
        //     if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {
        //         bottomPageContentSettings.style.display = 'none';
        //         bottomPageContentAnalysis.style.display = 'none';
        //         bottomPageContentEdges.style.display = 'none';
        //         bottomPageContentNodes.style.display = 'none';
        //         bottomPageContentViewers.style.display = 'none';
        //         bottomPageContentFigure.style.display = 'block';
        //
        //         settingsButton?.classList.remove('active_button');
        //         edgesButton?.classList.remove('active_button');
        //         nodesButton?.classList.remove('active_button');
        //         analysisButton?.classList.remove('active_button');
        //         viewersButton?.classList.remove('active_button');
        //         figureButton?.classList.add('active_button');
        //     }

        } else if (bottomPageContentId === 'bottom_page_selector_viewers') {
            if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {
                bottomPageContentSettings.style.display = 'none';
                bottomPageContentAnalysis.style.display = 'none';
                bottomPageContentEdges.style.display = 'none';
                bottomPageContentNodes.style.display = 'none';
                bottomPageContentViewers.style.display = 'block';
                bottomPageContentFigure.style.display = 'none';

                settingsButton?.classList.remove('active_button');
                edgesButton?.classList.remove('active_button');
                nodesButton?.classList.remove('active_button');
                analysisButton?.classList.remove('active_button');
                viewersButton?.classList.add('active_button');
                figureButton?.classList.remove('active_button');
            }
        } else if (bottomPageContentId === 'bottom_page_selector_nodes') {
            if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {
                bottomPageContentSettings.style.display = 'none';
                bottomPageContentAnalysis.style.display = 'none';
                bottomPageContentEdges.style.display = 'none';
                bottomPageContentNodes.style.display = 'block';
                bottomPageContentViewers.style.display = 'none';
                bottomPageContentFigure.style.display = 'none';

                settingsButton?.classList.remove('active_button');
                edgesButton?.classList.remove('active_button');
                nodesButton?.classList.add('active_button');
                analysisButton?.classList.remove('active_button');
                viewersButton?.classList.remove('active_button');
                figureButton?.classList.remove('active_button');
            }
        }
        else if (bottomPageContentId === 'bottom_page_selector_figure'){
            if (bottomPageContentSettings && bottomPageContentAnalysis && bottomPageContentEdges && bottomPageContentViewers && bottomPageContentNodes && bottomPageContentFigure) {
                bottomPageContentSettings.style.display = 'none';
                bottomPageContentAnalysis.style.display = 'none';
                bottomPageContentEdges.style.display = 'none';
                bottomPageContentNodes.style.display = 'none';
                bottomPageContentViewers.style.display = 'none';
                bottomPageContentFigure.style.display = 'block';

                settingsButton?.classList.remove('active_button');
                edgesButton?.classList.remove('active_button');
                nodesButton?.classList.remove('active_button');
                analysisButton?.classList.remove('active_button');
                viewersButton?.classList.remove('active_button');
                figureButton?.classList.add('active_button')
            }
        }

        const activeButton = document.getElementsByClassName('active_button')[0] as HTMLElement;
        if (activeButton) {
            localStorage.setItem('activeView', activeButton.id);
            if (activeButton.id === 'bottom_page_selector_more' || activeButton.id === 'bottom_page_selector_less' || activeButton.id === 'bottom_page_selector_settings') {
                localStorage.setItem('scrollPosition', '0');
            }
        }
        setActiveView(bottomPageContentId);
    };

    const navigate = useNavigate()

    const handleAddMoreNodes = async () => {

        setIsProcessingMore(true);
        // replace svg with a spinner
        let networkImage = document.getElementById('stringEmbedded');
        if (networkImage) {
            networkImage.innerHTML = `
                        <div style="display: flex; justify-content: center; align-items: center;" class='oversized_input_wait'>
                            <div class='spinner'></div>
                        </div>
                    `;
        }

        // let colorblindEdges = document.getElementById("colorblind") as HTMLInputElement | null;
        let bubbleNodeDesign = document.getElementById("bubbleNodeDesign") as HTMLInputElement | null;
        let blockStructures = document.getElementById("blockStructures") as HTMLInputElement | null;
        let centerNodeLabels = document.getElementById("centerNodeLabels") as HTMLInputElement | null;
        let showQueryNodeLabels = document.getElementById("showQueryNodeLabels") as HTMLInputElement | null;
        let hideSingletons = document.getElementById("hideSingletons") as HTMLInputElement | null;
        let hideLabels = document.getElementById("hideLabels") as HTMLInputElement | null;
        let dataSettingsLabel = document.getElementById("dataSettingsLabel") as HTMLInputElement | null;

        const additionalData = {
            // colorblindEdges: colorblindEdges?.checked ? "on" : "off",
            bubbleNodeDesign: bubbleNodeDesign?.checked ? "on" : "off",
            blockStructures: blockStructures?.checked ? "on" : "off",
            centerNodeLabels: centerNodeLabels?.checked ? "on" : "off",
            showQueryNodeLabels: showQueryNodeLabels?.checked ? "on" : "off",
            hideSingletons: hideSingletons?.checked ? "on" : "off",
            hideLabels: hideLabels?.checked ? "on" : "off",
            dataSettingsLabel: dataSettingsLabel?.value || ""
        };

        try {
            const result = await updateInput(
                "SingleProtein", species, identifier.replaceAll('\r', '%0d'), requiredScore, networkType,
                networkFlavor, `${moreFirstShellLimit}`, `${moreSecondShellLimit}`,
                channel1, channel2, channel3, channel4, channel5, channel6, channel7, "", sessionId
            );

            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                setIsProcessingMore(false);
                return;
            }

            if (result) {
                navigate(result.path, {
                    state: {
                        ...result.newData,  // Spread existing data
                        ...additionalData   // Add new fields
                    }
                });
                setIsProcessingMore(false);
                return;
            }
        } catch (error) {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        }
        setIsProcessingMore(false);
    }

    const handleShowLessNodes = async () => {

        setIsProcessingLess(true);

        let networkImage = document.getElementById('stringEmbedded');
        let savedNetworkImage;
        if (networkImage) {
            savedNetworkImage = networkImage.innerHTML;
            networkImage.innerHTML = `
                        <div style="display: flex; justify-content: center; align-items: center;" class='oversized_input_wait'>
                            <div class='spinner'></div>
                        </div>
                    `;
        }

        // let colorblindEdges = document.getElementById("colorblind") as HTMLInputElement | null;
        let bubbleNodeDesign = document.getElementById("bubbleNodeDesign") as HTMLInputElement | null;
        let blockStructures = document.getElementById("blockStructures") as HTMLInputElement | null;
        let centerNodeLabels = document.getElementById("centerNodeLabels") as HTMLInputElement | null;
        let showQueryNodeLabels = document.getElementById("showQueryNodeLabels") as HTMLInputElement | null;
        let hideSingletons = document.getElementById("hideSingletons") as HTMLInputElement | null;
        let hideLabels = document.getElementById("hideLabels") as HTMLInputElement | null;
        let dataSettingsLabel = document.getElementById("dataSettingsLabel") as HTMLInputElement | null;

        const additionalData = {
            // colorblindEdges: colorblindEdges?.checked ? "on" : "off",
            bubbleNodeDesign: bubbleNodeDesign?.checked ? "on" : "off",
            blockStructures: blockStructures?.checked ? "on" : "off",
            centerNodeLabels: centerNodeLabels?.checked ? "on" : "off",
            showQueryNodeLabels: showQueryNodeLabels?.checked ? "on" : "off",
            hideSingletons: hideSingletons?.checked ? "on" : "off",
            hideLabels: hideLabels?.checked ? "on" : "off",
            dataSettingsLabel: dataSettingsLabel?.value || ""
        };

        try {
            const result = await updateInput(
                "SingleProtein", species, identifier.replaceAll('\r', '%0d'), requiredScore, networkType,
                networkFlavor, `${lessFirstShellLimit}`, `${lessSecondShellLimit}`, channel1,
                channel2, channel3, channel4, channel5, channel6, channel7, "", sessionId
            );

            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                setIsProcessingLess(false);
                return;
            }

            if (result) {
                // check if nothing will change :
                let equal = true;
                for (let key of Object.keys(result.newData)) {
                    if (!Object.keys(location.state).includes(key) || location.state[key] !== (result.newData as Object)[key]) {
                        equal = false;
                    }
                }
                if (result.path === window.location.pathname && equal) {
                    if (networkImage && savedNetworkImage) {
                        networkImage.innerHTML = savedNetworkImage;
                    }
                } else {
                    // navigate(result.path, {state: result.newData});
                    navigate(result.path, {
                        state: {
                            ...result.newData,  // Spread existing data
                            ...additionalData   // Add new fields
                        }
                    });
                    setIsProcessingLess(false);
                    return;
                }
                setIsProcessingLess(false);
                return;
            }
        } catch (error) {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        }
        setIsProcessingLess(false);
    }
    const [activeView, setActiveView] = useState(localStorage.getItem('activeView'));

    const [showNotification, setShowNotification] = useState(false);
    const handleShareLinkCopied = () => () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(() => {
            setShowNotification(true); // Show the notification

            // Hide the notification after 4 seconds
            setTimeout(() => {
                setShowNotification(false);
            }, 2000);
        });
    }
    useEffect(() => {
        if (!svgLoaded) return;
        const bottomPageContentScrollMenu = document.getElementsByClassName('bottom_page_content_scrollmenu')[0] as HTMLElement;
        const fadingMenuLeft = document.getElementsByClassName('fading_menu_left')[0] as HTMLElement;
        const fadingMenuRight = document.getElementsByClassName('fading_menu_right')[0] as HTMLElement;
        bottomPageContentScrollMenu?.addEventListener('scroll', () => {
            const container = bottomPageContentScrollMenu;
            const contentWidth = container.scrollWidth;
            const containerWidth = container.clientWidth;
            const scrollLeft = container.scrollLeft;

            // Optimized logic for fadingMenuLeft
            fadingMenuLeft.style.display = scrollLeft <= 10 ? 'none' : 'block';

            // Optimized logic for fadingMenuRight
            fadingMenuRight.style.display = scrollLeft + containerWidth >= contentWidth - 20 ? 'none' : 'block';
        });
    }, [svgLoaded]);

    // Rendering based on whether the SVG has loaded or not
    return (
        <>
            {/*{svgLoaded ? ( // If SVG is loaded, render the 'more!' link*/}
            <div className="bottom_page_content_scrollmenu"
            >
                <button id="bottom_page_selector_analysis"
                        className={activeView === 'bottom_page_selector_analysis' || activeView === null ? 'active_button' : ''}
                        style={{marginLeft: "0"}}
                        onClick={handleSelectedBottomPageContent("bottom_page_selector_analysis")}>
                    <span className="analysis_icon"></span>
                    Analysis
                </button>
                <button id="bottom_page_selector_figure"
                        className={activeView === 'bottom_page_selector_figure' ? 'active_button' : ''}
                        onClick={handleSelectedBottomPageContent("bottom_page_selector_figure")}>
                    <span className="figure_icon"></span>
                    Figure
                </button>

                <button id="bottom_page_selector_nodes"
                        className={activeView === 'bottom_page_selector_nodes' ? 'active_button' : ''}
                        onClick={handleSelectedBottomPageContent("bottom_page_selector_nodes")}>
                    <span className="nodes_icon"></span>
                    Nodes
                </button>
                <button id="bottom_page_selector_edges"
                        className={activeView === 'bottom_page_selector_edges' ? 'active_button' : ''}
                        onClick={handleSelectedBottomPageContent("bottom_page_selector_edges")}>
                    <span className="edges_icon"></span>
                    Edges
                </button>
                <button id="bottom_page_selector_viewers"
                        className={activeView === 'bottom_page_selector_viewers' ? 'active_button' : ''}
                        onClick={handleSelectedBottomPageContent("bottom_page_selector_viewers")}>
                    <span className="view_icon"></span>
                    Viewers
                </button>
                <button id="bottom_page_selector_more"
                        onClick={handleAddMoreNodes}
                        disabled={isProcessingMore}
                        className="bottom_page_selector_tag">
                    <span className="more_icon"></span>
                    More
                </button>
                <button id="bottom_page_selector_less"
                        onClick={handleShowLessNodes}
                        disabled={isProcessingLess}
                        className="bottom_page_selector_tag">
                    <span className="less_icon"></span>
                    Less
                </button>
                <button id="bottom_page_selector_settings"
                        className={activeView === 'bottom_page_selector_settings' ? 'active_button' : ''}
                        onClick={handleSelectedBottomPageContent("bottom_page_selector_settings")}>
                    <span className="settings_icon"></span>
                    Settings
                </button>

                <button id="bottom_page_selector_share"
                        onClick={handleShareLinkCopied()}>
                    <span className="share_icon"></span>
                    Share
                </button>
                {showNotification && (
                    <Notification
                        message="Link copied to clipboard"
                        onClose={() => setShowNotification(false)}
                    />
                )}

                <div className="fading_menu_right" style={{display: "block"}}></div>
                <div className="fading_menu_left" style={{display: "none"}}></div>
            </div>

        </>
    );
};

export default PageContentNav;

