import React, {useEffect, ReactNode} from 'react';

interface ScrollRestorationComponentProps {
    children: ReactNode;
}

const ScrollRestorationComponent: React.FC<ScrollRestorationComponentProps> = ({children}) => {
    useEffect(() => {
        const handleScrollRestore = () => {
            // wait 10sec before moving to previous scroll position
            setTimeout(() => {
                const url = window.location.href;

                // check url has SearchTermDisambiguation
                if (url.includes('SearchTermDisambiguation')) {
                    const savedScrollPosition = localStorage.getItem('scrollPosition');
                    if (savedScrollPosition) {
                        window.scrollTo({top: JSON.parse(savedScrollPosition)});
                    }
                    // expand the category if it was expanded
                    const expandedViews = localStorage.getItem('expandedViews');
                    if (expandedViews) {
                        const views = expandedViews.split(',');
                        views.forEach((view: string) => {
                            var table = document.getElementById("table" + view);
                            if (!table) {
                                return;
                            }
                            let div = document.getElementById("toggleTriangle" + view);
                            if (!div) {
                                return;
                            }
                            div.classList.add('fully_expanded');

                            // change border randius depending on the state
                            let headerDiv = document.getElementById("interactorHeader" + view);
                            if (!headerDiv) {
                                return;
                            }
                            headerDiv.style.borderBottomLeftRadius = "0px";
                            headerDiv.style.borderBottomRightRadius = "0px";
                            headerDiv.style.borderBottom = "1px solid #A0A0A0";

                            div.classList.remove("not_expanded");
                            div.classList.remove("fully_expanded");
                            div.classList.add("fully_expanded");

                            // show or hide the corresponding rows:
                            var table_rows = table.getElementsByClassName("overflow_row");
                            var i;
                            for (i = 0; i < table_rows.length; i++) {
                                var table_row = table_rows[i] as HTMLElement;
                                table_row.style.display = 'block';
                            }
                        });
                    }
                }
            }, 3000);
        };
        window.addEventListener('popstate', handleScrollRestore);
    }, []);
    return <>{children}</>;
};

export default ScrollRestorationComponent;
