import React from "react";
import {useNavigate} from "react-router-dom";
import SearchOrganisms from "../components/SearchOrganisms";
import {Helmet} from "react-helmet-async";


const SearchOrganism = () => {

    document.title = "STRING: functional protein association networks";

    const navigate = useNavigate();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        let url = `/organism/${event.currentTarget.species_text.value}`;
        navigate(url)
        submit_button.disabled = false;
        return;
    }

    return (
        <>
            <Helmet>
                <title> STRING: functional protein association networks</title>
            </Helmet>

            <div className="menu_content">
                <div className="search_input_wrap">
                    <div className="search_input_box">
                        <div className="box_title">Organisms and Clades</div>
                        <div className="box">
                            <form id="input_form_organism" onSubmit={handleSubmit}
                                  method="post" spellCheck="false" encType="multipart/form-data">
                                <SearchOrganisms/>
                                <button type="submit" className="button large_submit_button">
                                    <div></div>
                                    &nbsp;Search&nbsp;
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"shadow"}></div>
            </div>
        </>
    );
}

export default SearchOrganism;