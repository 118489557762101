import React from "react";

const NotFoundPage = () => {
    return (
        <div className="menu_content">
            <div className="search_input_wrap">
                <div className="search_input_box">
                    <div className="box_title">404 Error ...</div>
                    <div className="box">
                        <div className="error_info_message" style={{paddingBottom: "25px"}}>
                            Sorry, this page cannot be found ... please check the URL.
                        </div>
                        <button onClick={() => window.location.href = '/SearchMenu'}
                                className="button large_submit_button"
                                style={{width: "70%"}}>
                            Start Over
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;