import React, {useContext, useEffect, useState} from "react";
import updateInput from "../utils/update_input";
import {useLocation, useNavigate} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";

interface LinkoutDictionary {
    [key: string]: string;
}

interface DataObj {
    category: string;
    term: string;
    number_of_genes: number;
    number_of_genes_in_background: number;
    ncbiTaxonId: number;
    inputGenes: string[];
    preferredNames: string[];
    pValue: number;
    fdr: number;
    signal: number;
    strength: number;
    description: string;
    color: string;
    selected: boolean;
}

interface OrderOfEnrichmentCategory {
    [key: string]: number;
}

interface fullNamesOfEnrichmentCategory {
    [key: string]: string;
}

interface sortOptions {
    [key: string]: number;
}

const OrderOfEnrichmentCategoryData: OrderOfEnrichmentCategory = {
    "Process": 1,
    "PMID": 4,
    "Function": 2,
    "Component": 3,
    "NetworkNeighborAL": 6,
    "KEGG": 7,
    "RCTM": 8,
    "WikiPathways": 9,
    "HPO": 13,
    "MPO": 13,
    "DPO": 13,
    "WPO": 13,
    "ZPO": 13,
    "DISEASES": 10,
    "TISSUES": 11,
    "COMPARTMENTS": 12,
    "Keyword": 14,
    "Pfam": 15,
    "InterPro": 16,
    "SMART": 17,
}
const fullNameOfEnrichmentCategoryData: fullNamesOfEnrichmentCategory = {
    "Process": "Biological Process (Gene Ontology)",
    "Function": "Molecular Function (Gene Ontology)",
    "Component": "Cellular Component (Gene Ontology)",
    "Keyword": "Annotated Keywords (UniProt)",
    "KEGG": "KEGG Pathways",
    "RCTM": "Reactome Pathways",
    "HPO": "Human Phenotype (Monarch)",
    "MPO": "The Mammalian Phenotype Ontology (Monarch)",
    "DPO": "Drosophila Phenotype (Monarch)",
    "WPO": "C. elegans Phenotype Ontology (Monarch)",
    "ZPO": "Zebrafish Phenotype Ontology (Monarch)",
    "Pfam": "Protein Domains (Pfam)",
    "SMART": "Protein Domains (SMART)",
    "InterPro": "Protein Domains and Features (InterPro)",
    "PMID": "Reference publications (PubMed)",
    "NetworkNeighborAL": "Local network cluster (STRING)",
    "COMPARTMENTS": "Subcellular localization (COMPARTMENTS)",
    "TISSUES": "Tissue expression (TISSUES)",
    "DISEASES": "Disease-gene associations (DISEASES)",
    "WikiPathways": "WikiPathways"
}
const sortOptionsData: sortOptions = {
    "Signal": 0,
    "Strength": 1,
    "FDR": 2,
    "Count in network": 3,
    "Term name": 4,
    "Description": 5,
}

const EnrichmentTable = ({
                             taskId,
                             sessionId,
                             species,
                             proteinCount,
                             nodeCount
                         }: {
    taskId: string,
    sessionId: string,
    species: string,
    proteinCount: number,
    nodeCount: number
}) => {
    const linkoutOfEnrichmentCategory: LinkoutDictionary = {
        "WikiPathways": "https://www.wikipathways.org/index.php/Pathway:",
        "DISEASES": `https://diseases.jensenlab.org/Entity?order=textmining,knowledge,experiments&textmining=10&knowledge=10&experiments=10&type1=-26&type2=${species}&id1=`,
        "TISSUES": `https://tissues.jensenlab.org/Entity?order=textmining,knowledge,experiments&knowledge=10&experiments=10&textmining=10&type1=-25&type2=${species}&id1=`,
        "COMPARTMENTS": `https://compartments.jensenlab.org/Entity?order=textmining,knowledge,predictions&knowledge=10&textmining=10&predictions=10&type1=-22&type2=${species}&id1=`,
        "Process": "http://amigo.geneontology.org/amigo/term/",
        "Function": "http://amigo.geneontology.org/amigo/term/",
        "Component": "http://amigo.geneontology.org/amigo/term/",
        "Keyword": "https://www.uniprot.org/keywords/",
        "KEGG": "https://www.kegg.jp/kegg-bin/show_pathway?",
        "RCTM": "https://reactome.org/content/detail/R-",
        "RCTM-MAP": "https://reactome.org/content/query?q=",
        "Pfam": "https://pfam.xfam.org/family/",
        "SMART": "http://smart.embl-heidelberg.de/smart/do_annotation.pl?DOMAIN=",
        "InterPro": "https://www.ebi.ac.uk/interpro/entry/",
        "PMID": "https://www.ncbi.nlm.nih.gov/pubmed/",
        // "NetworkNeighborAL" : f"{strglobals.webCgiPath}/network?network_term_id=%s&input_query_species=%s&sessionId=%s",
        "HPO": "https://monarchinitiative.org/phenotype/",
        "MPO": "https://monarchinitiative.org/phenotype/",
        "DPO": "https://monarchinitiative.org/phenotype/",
        "WPO": "https://monarchinitiative.org/phenotype/",
        "ZPO": "https://monarchinitiative.org/phenotype/",
    }

    const {UrlSTRING,} = useContext(GlobalContext);

    const location = useLocation()
    const {
        dataAll
    } = location.state;

    const [data, setData] = useState<DataObj[]>([]);
    const [svgLoaded, setsvg_loaded] = useState(false); // State to track if SVG is loaded
    const [groupedData, setGroupedData] = useState<{ [key: string]: DataObj[] }>({});
    const [SortingFilter, setSortingFilter] = useState("Signal");
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(0);
    const [dataFetched, setDataFetched] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const {showBoundary} = useErrorBoundary();

    useEffect(() => {
        const fetchInfo = async () => {
            var url = '';
            let data;
            if (taskId) {
                url = `${UrlSTRING}/api/json/enrichment?taskId=${taskId}&sessionId=${sessionId}&caller_identity=mobileapp&addSignal=1`
                const response = await fetch(url);
                data = await response.json();
            } else {
                data = dataAll[0]["enrichment"];
            }

            try {
                setData([]);
                if (data[0].error === "communication_error") {
                    setErrorMessage(data[0].message);
                    // set everything to empty
                    setData([]);
                    setGroupedData({});
                } else {
                    setData(data || []);
                }
            } catch (error: any) {
                if (error.message.includes("SyntaxError: Unexpected end of JSON input")) {
                    setData([]);
                }
            } finally {
                setDataFetched(true); // Set data fetched to true only after fetching completes
            }
        };

        fetchInfo();

        // Function to check the presence of the SVG element in the DOM
        const checkSvg = () => {
            const svg = document.getElementById("svg_network_image"); // Finding the SVG element by ID
            if (svg) { // If SVG is found
                setsvg_loaded(true); // Updating state to indicate SVG has finished loading
            }
        };

        // Initial check for the presence of SVG
        checkSvg();

        // Setting up a MutationObserver to watch for changes in the DOM (addition of SVG)
        const observer = new MutationObserver(checkSvg);
        observer.observe(document, {subtree: true, childList: true});

        // Cleanup function: Disconnecting the observer on component unmount
        return () => {
            observer.disconnect();
        };

    }, [taskId, sessionId]);

    useEffect(() => {
        setSortingFilter("Signal")
        setGroupedData({});
        // groupdata handling
        const sortedData = () => {
            if (data.length > 0) {
                const dataArray = Object.values(data).flat();
                // Sort the array based on the order specified in the dictionary
                dataArray.sort((a, b) => OrderOfEnrichmentCategoryData[a.category] - OrderOfEnrichmentCategoryData[b.category]);
                let groupedDataValue: { [key: string]: DataObj[] } = {};

                // Group data by category
                dataArray.forEach((item) => {
                    item.color = "#A0A0A0" //"#EFEFEF"
                    item.selected = false
                    // }
                    if (!groupedDataValue[item.category]) {
                        groupedDataValue[item.category] = [];
                    }

                    groupedDataValue[item.category].push(item);
                });
                Object.keys(groupedDataValue).map((category) => (
                    groupedDataValue[category].sort((a, b) => b.signal - a.signal)
                ))
                setGroupedData(groupedDataValue);
            }
        }
        sortedData();

    }, [taskId, sessionId, data, svgLoaded, nodeCount, proteinCount]);

    function deepClone(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(item => deepClone(item));
        }

        const clonedObj: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                clonedObj[key] = deepClone(obj[key]);
            }
        }

        return clonedObj;
    }

    // expand and collapse table
    function toggleEnrichmentTableExpansion(index: number) {
        // we can read the current expansion state from the class list of the headline
        var table = document.getElementById("table" + index);
        if (!table) {
            return;
        }
        // get the div child of the th element and change the class
        let div = document.getElementById("toggleTriangle" + index);
        if (!div) {
            return;
        }
        let new_state;
        if (div.classList.contains("fully_expanded")) {
            new_state = 'not_expanded';
        } else {
            new_state = 'fully_expanded';
        }

        // change border randius depending on the state
        let headerDiv = document.getElementById("interactorHeader" + index);
        if (!headerDiv) {
            return;
        }
        if (new_state === 'not_expanded') {
            headerDiv.style.borderBottomLeftRadius = "5px";
            headerDiv.style.borderBottomRightRadius = "5px";
            headerDiv.style.borderBottom = "none";
        } else {
            headerDiv.style.borderBottomLeftRadius = "0px";
            headerDiv.style.borderBottomRightRadius = "0px";
            headerDiv.style.borderBottom = "1px solid #A0A0A0";
        }

        div.classList.remove("not_expanded");
        div.classList.remove("fully_expanded");
        div.classList.add(new_state);

        var row_overflow_display = 'none';

        if (new_state === 'not_expanded') {
            row_overflow_display = 'none';
        }

        if (new_state === 'fully_expanded') {
            row_overflow_display = 'block';
        }

        // show or hide the corresponding rows:
        var table_rows = table.getElementsByClassName("overflow_row");
        var i;
        for (i = 0; i < table_rows.length; i++) {
            var table_row = table_rows[i] as HTMLElement;
            table_row.style.display = row_overflow_display;
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////// network coloring functions
    ////////////////////////////////////////////////////////////////////////////

    function getXYForDegree(x0: number, y0: number, r: number, degree: number): [number, number] {
        const t: number = (Math.PI / 180) * degree; // convert to radians

        const x: number = r * Math.cos(t) + x0;
        const y: number = r * Math.sin(t) + y0;

        return [x, y];
    }

    function drawEnrichmentSlices(nodeName: string, colors: string[]): void {
        let networkDocument: Document = document;
        let networkObject: HTMLObjectElement | null = document.getElementById("network_object") as HTMLObjectElement | null;

        if (networkObject) {
            networkDocument = networkObject.contentDocument as Document;
        }

        if (!networkDocument) {
            return;
        }

        const groupElement = document.querySelector(`[data-safe_div_label=${nodeName}]`);

        if (!groupElement) {
            return;
        }

        const circles: HTMLCollectionOf<SVGCircleElement> = groupElement.getElementsByTagName('circle');
        const circle: SVGCircleElement | undefined = circles[circles.length - 1];

        if (!circle) {
            return;
        }

        const x0: number = circle.cx.baseVal.value;
        const y0: number = circle.cy.baseVal.value;
        const r: number = circle.r.baseVal.value;

        const step: number = 360 / colors.length;
        let fullArc: number = 1;


        if (colors.length > 1) {
            fullArc = 0;
        }

        for (let i = 0; i < colors.length; i++) {
            const color: string = colors[i];

            // get position of the 1st point on the circumference
            let degree: number = i * step;
            degree = (degree + 270) % 360;
            const coords1: [number, number] = getXYForDegree(x0, y0, r, degree);
            const x1: number = coords1[0];
            const y1: number = coords1[1];

            // get position of the 2nd point on the circumference
            degree = (i + 1) * step; // end degree
            degree = (degree + 270) % 360;
            const coords2: [number, number] = getXYForDegree(x0, y0, r, degree);
            const x2: number = coords2[0];
            const y2: number = coords2[1];

            const pathElements: (string | number)[] = ["M", x1, y1, "A", r, r, 0, fullArc, 1, x2 - 0.001, y2, "L", x0, y0];
            let path: string = "";

            for (let j = 0; j < pathElements.length; j++) {
                path += pathElements[j] + ' ';
            }

            const shape: SVGPathElement = networkDocument.createElementNS("http://www.w3.org/2000/svg", "path");
            shape.setAttributeNS(null, "d", path);
            shape.setAttributeNS(null, "fill", color);
            shape.setAttributeNS(null, "opacity", "0.4");
            groupElement.insertBefore(shape, circle);

            circle.setAttributeNS(null, 'display', 'none'); // hide white circle
        }
    }

    function removeSlices(nodeContainers: NodeListOf<Element> | undefined, isEnrichmentSelected: boolean): void {
        if (nodeContainers === undefined) {
            let networkDocument: Document = document;
            let networkObject: HTMLObjectElement | null = document.getElementById("network_object") as HTMLObjectElement | null;

            if (networkObject) {
                networkDocument = networkObject.contentDocument as Document;
            }

            if (!networkDocument) {
                return;
            }

            const svgImage = networkDocument.getElementById("svg_network_image");

            if (svgImage === null) {
                return; // we are in png mode, apparently
            }

            nodeContainers = networkDocument.querySelectorAll('.nwnodecontainer'); // network nodes
        }

        // Remove all the slices from all nodes
        for (let i = 0; i < nodeContainers.length; i++) {
            const paths: HTMLCollectionOf<Element> = nodeContainers[i].getElementsByTagName('path');

            // If the slices were on the node, show back the color/white circle.
            if (paths.length > 0) {
                const circles: HTMLCollectionOf<SVGCircleElement> = nodeContainers[i].getElementsByTagName('circle'); // get circles
                const circle: SVGCircleElement | undefined = circles[circles.length - 1]; // get white circle

                if (circle) {
                    circle.removeAttribute('display'); // unhide the white circle
                }
            }

            while (paths[0]) {
                // Remove the slices
                nodeContainers[i].removeChild(paths[0]);
            }
        }

        // Reset the colors if none is selected
        if (!isEnrichmentSelected) {
            for (let i = 0; i < nodeContainers.length; i++) {
                nodeContainers[i].setAttribute('class', 'nwnodecontainer');
            }
        }
    }

    function toggleEnrichmentHighlight(trId: string): void {
        // get the parent div of this element
        let elm: HTMLElement | null = document.getElementById(trId);

        if (!elm) {
            return;
        }

        if (elm.classList.contains('inactive_wait')) {
            return;
        }

        let networkDocument: Document = document;
        let networkObject: HTMLObjectElement | null = document.getElementById("network_object") as HTMLObjectElement | null;

        if (networkObject) {
            networkDocument = networkObject.contentDocument as Document;
        }

        if (!networkDocument) {
            return;
        }

        const svgImage = networkDocument.getElementById("svg_network_image");

        if (svgImage === null) {
            return; // we are in png mode, apparently
        }

        const enrichmentContainers: HTMLCollectionOf<Element> = document.getElementsByClassName('enrichment_table_content_container'); // tables
        const nodeContainers: NodeListOf<Element> = networkDocument.querySelectorAll('.nwnodecontainer'); // network nodes

        const availableColors: string[] = ['khaki', 'lightblue', 'pink', 'lightgreen',
            'black', 'grey', 'maroon', 'purple', 'orange', 'cyan', 'darkgreen',
            'magenta', 'yellow', 'limegreen', 'blue', 'red']; // in reverse order

        const totalNrColors: number = availableColors.length;

        let deselect: number = 0;

        if (elm.classList.contains('enrichment_table_selected')) {
            deselect = 1;

            const colorTerm: string | undefined = elm.classList[3];
            if (colorTerm) {
                elm.className = elm.className.replace(' enrichment_table_selected ' + colorTerm, '');

                const colorContainer: HTMLElement | null = elm.getElementsByClassName("enrichment_color_container")[0] as HTMLElement | null;

                if (colorContainer) {
                    colorContainer.style.background = ''; // reset the small bubble color
                }

                // find the element in groupedData and change the color
                let category = trId.split("_")[0];
                let dataIndex = parseInt(trId.split("_")[1]);
                groupedData[category][dataIndex].color = "#EFEFEF";
                groupedData[category][dataIndex].selected = false;
            }
        }

        // Check which slice colors are in use till now
        for (let i = 0; i < enrichmentContainers.length; i++) {
            if (enrichmentContainers[i].classList.contains('enrichment_table_selected')) {
                const colorTerm: string | undefined = enrichmentContainers[i].classList[3]
                if (colorTerm) {
                    const colorName: string = colorTerm.split("_").slice(0, -1).join("_"); // in case of underscore :)
                    const colorIndex: number = availableColors.indexOf(colorName);

                    if (colorIndex !== -1) {
                        availableColors.splice(colorIndex, 1);
                    } else {
                        throw new Error(colorName + " color doesn't exist!");
                    }
                }
            }
        }

        // If we are in selection mode and there is a color available
        // Select the row, remove all the colors
        if (deselect !== 1 && availableColors.length > 0) {

            const colorName: string | undefined = availableColors.pop(); // get the next available color
            if (colorName) {
                const colorTerm: string = colorName + '_term';

                elm.className = elm.className + ' enrichment_table_selected ' + colorTerm; // select the table node !!!!!!!!!!!!!!!!!!!!!!!
                const colorContainer: HTMLElement | null = elm.getElementsByClassName("enrichment_color_container")[0] as HTMLElement | null;

                if (colorContainer) {
                    colorContainer.style.background = colorName; // set the small bubble color
                    // colorContainer.style.border = `1px solid ${colorName}`; // set the border color
                }

                for (let i = 0; i < nodeContainers.length; i++) {
                    nodeContainers[i].setAttribute('class', 'nwnodecontainer nwnodecontainerwhiteout'); // remove color
                }

                // find the element in groupedData and change the color
                let category = trId.split("_")[0];
                let dataIndex = parseInt(trId.split("_")[1]);
                groupedData[category][dataIndex].color = colorName;
                groupedData[category][dataIndex].selected = true;

            }
        }

        let isEnrichmentSelected: boolean = true;

        if (availableColors.length === totalNrColors) {
            isEnrichmentSelected = false;
        }

        removeSlices(nodeContainers, isEnrichmentSelected);

        // Now we get all the information needed to color the nodes
        const nodesInfo: { [key: string]: string[] } = {};
        for (let i = 0; i < enrichmentContainers.length; i++) {
            if (enrichmentContainers[i].classList.contains('enrichment_table_selected')) {
                const colorTerm: string | undefined = enrichmentContainers[i].className.split(' ').pop();
                if (colorTerm) {
                    const colorName: string = colorTerm.split("_").slice(0, -1).join("_"); // in case of underscore
                    const nodes: string[] = enrichmentContainers[i].getAttribute('data-nodes')?.split(",") || [];

                    for (let j = 0; j < nodes.length; j++) {
                        const nodeName: string = nodes[j];

                        if (!(nodeName in nodesInfo)) {
                            nodesInfo[nodeName] = [];
                        }
                        nodesInfo[nodeName].push(colorName);
                    }
                }
            }
        }

        for (const nodeName in nodesInfo) {
            // print the slices
            drawEnrichmentSlices(nodeName, nodesInfo[nodeName]);
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////// enrichment linkout functions
    ////////////////////////////////////////////////////////////////////////////

    function getEnrichmentLinkout(termExternalId: string, categoryId: string) {
        let linkout: string = "";

        if (categoryId === 'RCTM') {
            if (termExternalId.startsWith('MAP-')) {
                categoryId = 'RCTM-MAP';
                termExternalId = termExternalId.split("-")[1];
            }
        }

        if (categoryId in linkoutOfEnrichmentCategory) {
            let linkoutEnrichedTerm: string = termExternalId;
            const res = /PMID:(\d+)/.exec(linkoutEnrichedTerm);
            if (res) linkoutEnrichedTerm = res[1];

            if (['DISEASES', 'TISSUES', 'COMPARTMENTS'].includes(categoryId)) {
                const resGOCC = /GOCC:(\d+)/.exec(linkoutEnrichedTerm);
                if (resGOCC) linkoutEnrichedTerm = 'GO:' + resGOCC[1];

                linkout = linkoutOfEnrichmentCategory[categoryId] + linkoutEnrichedTerm;
            } else if (categoryId === 'NetworkNeighborAL') {
                linkout = "#"//linkoutOfEnrichmentCategory[categoryId] + linkoutEnrichedTerm;
            } else {
                linkout = linkoutOfEnrichmentCategory[categoryId] + linkoutEnrichedTerm;
            }
        }
        return linkout;
    }

    const navigate = useNavigate();

    async function queryWithInternalFromEnrichmentSet(nodeNames: string[]) {

        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: animated scrolling
        });
        let networkImage = document.getElementById('stringEmbedded');
        if (networkImage) {
            networkImage.innerHTML = `
                        <div style="display: flex; justify-content: center; align-items: center;" class='oversized_input_wait'>
                            <div class='spinner'></div>
                        </div>
                    `;
        }

        try {
            let requiredScore = "400";
            let networkFlavor = "evidence"
            var too_large_network_count = 200;
            if (nodeNames.length > too_large_network_count) {
                requiredScore = "900";
                networkFlavor = "confidence";
            }
            const result = await updateInput(
                "identifiers", species, nodeNames.join("%0d"), requiredScore, "functional", networkFlavor,
                "0", "0", "on", "on", "on", "on",
                "on", "on", "on", "", sessionId
            );

            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                return;
            }

            // let colorblindEdges = document.getElementById("colorblind") as HTMLInputElement | null;
            let bubbleNodeDesign = document.getElementById("bubbleNodeDesign") as HTMLInputElement | null;
            let blockStructures = document.getElementById("blockStructures") as HTMLInputElement | null;
            let centerNodeLabels = document.getElementById("centerNodeLabels") as HTMLInputElement | null;
            let showQueryNodeLabels = document.getElementById("showQueryNodeLabels") as HTMLInputElement | null;
            let hideSingletons = document.getElementById("hideSingletons") as HTMLInputElement | null;
            let hideLabels = document.getElementById("hideLabels") as HTMLInputElement | null;
            let dataSettingsLabel = document.getElementById("dataSettingsLabel") as HTMLInputElement | null;

            const additionalData = {
                // colorblindEdges: colorblindEdges?.checked ? "on" : "off",
                bubbleNodeDesign: bubbleNodeDesign?.checked ? "on" : "off",
                blockStructures: blockStructures?.checked ? "on" : "off",
                centerNodeLabels: centerNodeLabels?.checked ? "on" : "off",
                showQueryNodeLabels: showQueryNodeLabels?.checked ? "on" : "off",
                hideSingletons: hideSingletons?.checked ? "on" : "off",
                hideLabels: hideLabels?.checked ? "on" : "off",
                dataSettingsLabel: dataSettingsLabel?.value || ""
            };

            if (result) {
                navigate(result.path, {
                    state: {
                        ...result.newData,  // Spread existing data
                        ...additionalData   // Add new fields
                    }
                });
                window.location.href = result.path;
                return;
            }
        } catch (error) {
            setTimeout(() => {
                showBoundary(error);
            }, 2000); // Delay of 2 seconds
        }

    }

    async function queryWithTerm(term: string, termSize: number) {

        try {
            let requiredScore = "400"
            let networkFlavor = "evidence"
            var too_large_network_count = 200;
            if (termSize > too_large_network_count) {
                requiredScore = "900";
                networkFlavor = "confidence";
            }
            const result = await updateInput(
                "term", species, term, requiredScore, "functional", networkFlavor,
                "0", "0", "on", "on", "on", "on",
                "on", "on", "on", "", sessionId
            );
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional: animated scrolling
            });
            let networkImage = document.getElementById('stringEmbedded');
            if (networkImage) {
                networkImage.innerHTML = `
                        <div style="display: flex; justify-content: center; align-items: center;" class='oversized_input_wait'>
                            <div class='spinner'></div>
                        </div>
                    `;
            }

            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                return;
            }

            // let colorblindEdges = document.getElementById("colorblind") as HTMLInputElement | null;
            let bubbleNodeDesign = document.getElementById("bubbleNodeDesign") as HTMLInputElement | null;
            let blockStructures = document.getElementById("blockStructures") as HTMLInputElement | null;
            let centerNodeLabels = document.getElementById("centerNodeLabels") as HTMLInputElement | null;
            let showQueryNodeLabels = document.getElementById("showQueryNodeLabels") as HTMLInputElement | null;
            let hideSingletons = document.getElementById("hideSingletons") as HTMLInputElement | null;
            let hideLabels = document.getElementById("hideLabels") as HTMLInputElement | null;
            let dataSettingsLabel = document.getElementById("dataSettingsLabel") as HTMLInputElement | null;

            const additionalData = {
                // colorblindEdges: colorblindEdges?.checked ? "on" : "off",
                bubbleNodeDesign: bubbleNodeDesign?.checked ? "on" : "off",
                blockStructures: blockStructures?.checked ? "on" : "off",
                centerNodeLabels: centerNodeLabels?.checked ? "on" : "off",
                showQueryNodeLabels: showQueryNodeLabels?.checked ? "on" : "off",
                hideSingletons: hideSingletons?.checked ? "on" : "off",
                hideLabels: hideLabels?.checked ? "on" : "off",
                dataSettingsLabel: dataSettingsLabel?.value || ""
            };


            if (result) {
                navigate(result.path, {
                    state: {
                        ...result.newData,  // Spread existing data
                        ...additionalData   // Add new fields
                    }
                });
                window.location.href = result.path;
                return;
            }
        } catch (error) {
            setTimeout(() => {
                showBoundary(error);
            }, 2000); // Delay of 2 seconds
        }
        window.location.reload();
    }


    ////////////////////////////////////////////////////////////////////////////
    ////////////////////// enrichment sorting functions
    ////////////////////////////////////////////////////////////////////////////

    function handleSortChange(elm: any) {

        const groupDataValue: { [key: string]: DataObj[] } = deepClone(groupedData);

        if (elm !== "null") {
            switch (elm.id) {
                case '0':
                    // sort groupdData by signal
                    Object.keys(groupDataValue).map((category) => (
                        groupDataValue[category].sort((a, b) => {
                            // Primary sort: by signal in descending order
                            const primarySort = b.signal - a.signal;

                            // Secondary sort: if signal is equal, sort by fdr
                            const secondarySort = primarySort === 0 ? a.fdr - b.fdr : 0;

                            // Tertiary sort: if fdr is equal, sort by term
                            const tertiarySort = secondarySort === 0 ? a.term.localeCompare(b.term) : 0;

                            return primarySort || secondarySort || tertiarySort;
                        })
                    ))
                    break;

                case '1':
                    // sort groupdData by strength descending order
                    Object.keys(groupDataValue).map((category) => (
                        groupDataValue[category].sort((a, b) => {
                            // Primary sort: by strength in descending order
                            const primarySort = b.strength - a.strength;

                            // Secondary sort: if signal is equal, sort by fdr
                            const secondarySort = primarySort === 0 ? a.fdr - b.fdr : 0;

                            // Tertiary sort: if fdr is equal, sort by term
                            const tertiarySort = secondarySort === 0 ? a.term.localeCompare(b.term) : 0;

                            return primarySort || secondarySort || tertiarySort;
                        })
                    ))
                    break;

                case '2':
                    // sort groupdData by fdr
                    Object.keys(groupDataValue).map((category) => (
                        groupDataValue[category].sort((a, b) => {

                            // Primary sort: by fdr in ascending order
                            const primarySort = a.fdr - b.fdr;

                            // Secondary sort: if fdr is equal, sort by signal
                            const secondarySort = primarySort === 0 ? b.signal - a.signal : 0;

                            // Tertiary sort: if signal is equal, sort by term
                            const tertiarySort = secondarySort === 0 ? a.term.localeCompare(b.term) : 0;

                            return primarySort || secondarySort || tertiarySort;
                        })
                    ))
                    break;

                case '3':
                    Object.keys(groupDataValue).forEach((category) => {
                        groupDataValue[category].sort((a, b) => {
                            // Primary sort: by number_of_genes in descending order
                            const primarySort = b.number_of_genes - a.number_of_genes;

                            // Secondary sort: if number_of_genes is equal, sort by signal
                            const secondarySort = primarySort === 0 ? b.signal - a.signal : 0;

                            // Tertiary sort: if fdr is equal, sort by term
                            const tertiarySort = secondarySort === 0 ? a.term.localeCompare(b.term) : 0;

                            return primarySort || secondarySort || tertiarySort;

                        });
                    });
                    break;

                case '4':
                    // sort groupData by term
                    Object.keys(groupDataValue).map((category) => (
                        groupDataValue[category].sort((a, b) => a.term.localeCompare(b.term))
                    ))
                    break;

                case '5':
                    // sort groupData by description
                    Object.keys(groupDataValue).map((category) => (
                        groupDataValue[category].sort((a, b) => {
                                // Primary sort: by description in ascending order
                                const primarySort = a.description.localeCompare(b.description);

                                // Secondary sort: if description is equal, sort by term
                                const secondarySort = primarySort === 0 ? a.term.localeCompare(b.term) : 0;

                                return primarySort || secondarySort;
                            }
                        )
                    ));

                    break;

                default:
                    break;
            }
            setGroupedData(groupDataValue);
            setSortingFilter(elm.innerText);
        }
    }

    const handleApply = () => {

        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        // get name="radio-group"
        const selectedOption = document.querySelector('input[name="radio-group"]:checked');
        if (!selectedOption) {
            return;
        }

        let id = selectedOption.id.slice(-1)
        let elm;
        const labels = document.querySelectorAll('.filter-modal__content label');

        labels.forEach(label => {
            if (label.id === id) {
                elm = label;
            }
        });

        handleSortChange(elm)
        setIsOpen(false);
        submit_button.disabled = false;
    };

    const handleRadioClick = (index: number) => {
        setSelected(index);
    };


    return (
        <>
            <svg width="0" height="0"
                 style={{visibility: "hidden", position: "absolute"}}>
                <defs dangerouslySetInnerHTML={{
                    __html: `
                        <radialGradient cx="50%" cy="50%" id="bubble_gradientFbbb" r="50%">
                            <stop offset="0%" style="stop-color:#FFFFFF" />
                            <stop offset="84%" style="stop-color:#FFFFFF" />
                            <stop offset="88%" style="stop-color:#000000" />
                            <stop offset="100%" style="stop-color:#000000" />
                        </radialGradient>
                    `
                }}/>
            </svg>
            {(Object.keys(groupedData).length > 0) ? (
                <>
                    <div id={"enrichmentTable"}>
                        <div className="legend_headline"> Functional enrichments in your network</div>
                        <div className="filter">
                            <button className="filter_button" onClick={() => setIsOpen(!isOpen)}>Sort by
                                : {SortingFilter}</button>
                        </div>

                        {isOpen ? (
                            // small screen case
                            <div className="filter-modal">
                                <div className="filter-modal__wrapper" aria-label="modal window">
                                    <div className="filter-modal__header">
                                        <div>
                                            <div className="movable_div_title_bar"
                                                 data-parent_div_to_move_id="fpWindowDiv">Enrichment table sorting
                                            </div>
                                            <div className="movable_div_closelink_wrapper">
                                                <button className="floatingdivcloselink " title="click to close"
                                                        onClick={() => setIsOpen(false)}>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="filter-modal__content">
                                        {Object.keys(sortOptionsData).map((filter, index) => (
                                            <div key={index} onClick={() => handleRadioClick(index)}>
                                                <input
                                                    type="radio"
                                                    id={`radio${index}`}
                                                    name="radio-group"
                                                    className="radio-input"
                                                    checked={selected === index}
                                                    onChange={() => handleRadioClick(index)}
                                                />
                                                <label id={`${index}`}>{filter}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="filter-modal__actions">
                                        <button onClick={handleApply} className="large_submit_button"
                                                style={{marginBottom: "20px"}}>
                                            &nbsp;Apply&nbsp;
                                        </button>
                                    </div>

                                </div>
                            </div>

                        ) : null}

                        {Object.keys(groupedData).map((category, index, array) => (

                            <div className="table-container" key={index}>
                                <div id={`table${index}`} className="enrichment_table">
                                    <div id={`interactorHeader${index}`}
                                         className={(index === array.length - 1 ? "interactor_header rounded-header" : "interactor_header rounded_header_last")}
                                         style={{height: "40px"}}
                                         onClick={() => toggleEnrichmentTableExpansion(index)}
                                    >
                                        <div className="not_expanded" id={`toggleTriangle${index}`}></div>
                                        {fullNameOfEnrichmentCategoryData[category]}
                                    </div>
                                    <div className="enrichment_table_body">
                                        {groupedData[category].map((dataObj, dataIndex, dataArray) => (
                                            <div key={`${category}_${dataIndex}`} id={`${category}_${dataIndex}`}
                                                 style={dataIndex === dataArray.length - 1 ? {display: "none"} : {
                                                     display: "none",
                                                     borderBottom: "1px solid #A0A0A0"
                                                 }}
                                                 className={`overflow_row enrichment_table_content_container ` + (dataObj.selected ? ` enrichment_table_selected ${dataObj.color}_term` : "")}
                                                 data-nodes={dataObj.preferredNames.join(",")}>

                                                <div className="term_enrichment_cell_separator">
                                                    <a href={getEnrichmentLinkout(dataObj.term, category)}
                                                       rel="noopener noreferrer" onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}>{dataObj.term}</a>
                                                </div>

                                                <div
                                                    className="description_enrichment_cell">
                                                    {dataObj.description}
                                                </div>

                                                <div className="term_enrichment_cell">
                                                    <div className={"cell_large"} style={{fontSize: "0.9rem"}}>

                                                        <span
                                                            className="enrichment_table_small_title">Strength: </span>
                                                        <span
                                                            style={{fontWeight: "bold"}}>{dataObj.strength}</span>
                                                        <span>&nbsp;&nbsp;</span>
                                                        <span
                                                            className="enrichment_table_small_title">Signal: </span>
                                                        <span
                                                            style={{fontWeight: "bold"}}>{dataObj.signal}</span>
                                                        <span>&nbsp;&nbsp;</span>
                                                        <span
                                                            className="enrichment_table_small_title">FDR: </span><span
                                                        style={{fontWeight: "bold"}}>{dataObj.fdr.toExponential()}</span>
                                                        <br/>

                                                        <span
                                                            className="enrichment_table_small_title">Count in network </span>

                                                        <a
                                                            href="#"
                                                            className="show_button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                queryWithInternalFromEnrichmentSet(dataObj.preferredNames);
                                                            }}
                                                            style={{
                                                                pointerEvents: dataObj.number_of_genes > 1000 ? 'none' : 'auto',
                                                                color: dataObj.number_of_genes > 1000 ? 'grey' : 'inherit'
                                                            }}
                                                        >
                                                            {`${dataObj.number_of_genes}`}
                                                        </a>
                                                        &nbsp;of&nbsp;
                                                        <a
                                                            href="#"
                                                            className="show_button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                queryWithTerm(dataObj.term, dataObj.number_of_genes_in_background);
                                                            }}
                                                            style={{
                                                                pointerEvents: dataObj.number_of_genes_in_background > 1000 ? 'none' : 'auto',
                                                                color: dataObj.number_of_genes_in_background > 1000 ? 'grey' : 'inherit'
                                                            }}
                                                        >
                                                            {`${dataObj.number_of_genes_in_background}`}
                                                        </a>

                                                    </div>
                                                    <div className=" cell_small">
                                                        <div style={{
                                                            position: 'relative',
                                                            width: 'max(7vh, 7vw)',
                                                            height: 'max(7vh, 7vw)'
                                                        }}>
                                                            <div
                                                                onClick={() => toggleEnrichmentHighlight(`${category}_${dataIndex}`)}
                                                                className="enrichment_color_container flat_bubble"
                                                                style={{
                                                                    backgroundColor: dataObj.color,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0
                                                                }}
                                                            ></div>
                                                            <div
                                                                className="enrichment_color_container inner_flat_bubble"
                                                                onClick={() => toggleEnrichmentHighlight(`${category}_${dataIndex}`)}
                                                                style={{
                                                                    fill: 'url(#bubble_gradientFbbb)',
                                                                    // width: '100%',
                                                                    // height: '100%',
                                                                    position: 'absolute',
                                                                    top: '0.5vh',
                                                                    left: '0.5vh'
                                                                }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>) : (
                dataFetched ? (
                    <div id={"enrichmentTable"}>
                        <div className="legend_headline"> Functional enrichments in your network</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5vh 2vh 20vh 2vh"
                        }}>
                            {errorMessage ? (
                                <span style={{
                                    color: "red",
                                    alignItems: "center",
                                    textAlign: "center"
                                }}> {errorMessage} </span>
                            ) : (
                                "- no significant enrichment detected -"
                            )}
                        </div>
                    </div>
                ) : (
                    <div style={{display: "flex", justifyContent: "center", textAlign: "center"}}
                         className='oversized_input_wait'>
                        {/*<img src={`/images/oversized_input_spinner.gif`} alt='waiting spinner'/>*/}
                        <div className="spinner"></div>
                    </div>)
            )}
        </>
    );

}

export default EnrichmentTable;

