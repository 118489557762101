import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import PollingMessage from "../components/PollingMessage";
import updateInput from "../utils/update_input";
import HighlightMatches from "../utils/HighlightMatches";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";
import {Helmet} from "react-helmet-async";

interface DataObj {
    rank: number;
    termId: string;
    description: string;
    category: string;
    categoryDisplay: string;
    member_count: number;
    termLinkout: string;
}

interface OrderOfEnrichmentCategory {
    [key: string]: number;
}

interface fullNamesOfEnrichmentCategory {
    [key: string]: string;
}

interface agotoolCategories {
    [key: string]: string;
}

const OrderOfEnrichmentCategoryData: OrderOfEnrichmentCategory = {
    "Process": 1,
    "PMID": 4,
    "Function": 2,
    "Component": 3,
    "NetworkNeighborAL": 6,
    "KEGG": 7,
    "RCTM": 8,
    "WikiPathways": 9,
    "HPO": 13,
    "MPO": 13,
    "DPO": 13,
    "WPO": 13,
    "ZPO": 13,
    "DISEASES": 10,
    "TISSUES": 11,
    "COMPARTMENTS": 12,
    "Keyword": 14,
    "Pfam": 15,
    "InterPro": 16,
    "SMART": 17,
};

const SearchTermDisambiguation = () => {

    document.title = "searching... STRING: functional protein association networks";

    const {species_text_term, network_term_text} = useParams();

    const navigate = useNavigate();
    const [data, setData] = React.useState<DataObj[]>([]);
    const [dataIsSet, setDataIsSet] = React.useState<boolean>(false);
    const [groupedData, setGroupedData] = useState<{ [key: string]: DataObj[] }>({});
    const { showBoundary } = useErrorBoundary();

    const fullNameOfEnrichmentCategoryData: fullNamesOfEnrichmentCategory = {
        "Process": "Biological Process (Gene Ontology)",
        "Function": "Molecular Function (Gene Ontology)",
        "Component": "Cellular Component (Gene Ontology)",
        "Keyword": "Annotated Keywords (UniProt)",
        "KEGG": "KEGG Pathways",
        "RCTM": "Reactome Pathways",
        "HPO": "Human Phenotype (Monarch)",
        "MPO": "The Mammalian Phenotype Ontology (Monarch)",
        "DPO": "Drosophila Phenotype (Monarch)",
        "WPO": "C. elegans Phenotype Ontology (Monarch)",
        "ZPO": "Zebrafish Phenotype Ontology (Monarch)",
        "Pfam": "Protein Domains (Pfam)",
        "SMART": "Protein Domains (SMART)",
        "InterPro": "Protein Domains and Features (InterPro)",
        "PMID": "Reference publications (PubMed)",
        "NetworkNeighborAL": "Local network cluster (STRING)",
        "COMPARTMENTS": "Subcellular localization (COMPARTMENTS)",
        "TISSUES": "Tissue expression (TISSUES)",
        "DISEASES": "Disease-gene associations (DISEASES)",
        "WikiPathways": "WikiPathways"
    };

    const agotoolCategories: agotoolCategories = {
        "-20": "COMPARTMENTS",
        "-25": "TISSUES",
        "-26": "DISEASES",
        "-58": "WikiPathways",
        "-21": "Process",
        "-22": "Component",
        "-23": "Function",
        "-51": "Keyword",
        "-52": "KEGG",
        "-53": "SMART",
        "-54": "InterPro",
        "-55": "Pfam",
        "-56": "PMID",
        "-78": "NetworkNeighborAL",
        "-57": "RCTM",
        "-59": "HPO",
        "-60": "MPO",
        "-61": "DPO",
        "-62": "WPO",
        "-63": "ZPO",
    }
    const {UrlSTRING,} = useContext(GlobalContext);
    let url = `${UrlSTRING}/cgi/api?apiMethod=get_network_terms&apiOutputType=json&network_term_text=${network_term_text}&species=${species_text_term}&caller_identity=mobileapp`;
    useEffect(() => {
        async function fetchInfo(url: string) {
            try {
                if (species_text_term === undefined || network_term_text === undefined) {
                    return;
                }
                if (url !== '') {
                    const res = await fetch(url);
                    const d: DataObj[] = await res.json();
                    const formattedData = d.map((item) => ({
                        termId: item.termId,
                        rank: item.rank,
                        description: item.description,
                        category: item.category,
                        categoryDisplay: item.categoryDisplay,
                        member_count: item.member_count,
                        termLinkout: item.termLinkout
                    }));

                    if (formattedData.length === 0) {
                        let errorData = {
                            title: 'Not found',
                            message: 'Sorry, STRING did not find any matches for your input.\n\nGenerally, STRING understands a number of different names/symbols for proteins.\nHere is a selection of typical names: \'YEL036C\', \'TRPB_ECOLI\', \'trpB\', \'ENSP00000249373\', \'BRCA1\', \'CG11561\', \'daf-3\', ...\n\nTry to identify your proteins with different names/symbols that you might know. Alternatively, you can provide the raw amino-acid sequences of your proteins as input, and STRING will try to identify these via similarity searches.'
                        }
                        navigate('/error', {state: errorData});
                        return;
                    } else if (formattedData.length === 1 && formattedData[0].termId === network_term_text) {
                        const result = await updateInput(
                            "term", species_text_term, formattedData[0].termId, "400",
                            "functional", "evidence", "0", "0",
                            "on", "on", "on", "on", "on", "on",
                            "on", "", ""
                        );
                        if (result?.path === 'Not found') {
                            navigate('/error', {state: result.newData});
                            return;
                        }
                        if (result) {
                            navigate(result.path, {state: result.newData});
                            return;
                        }
                    }
                    setData(formattedData);
                    setDataIsSet(true);
                }
            } catch (error) {
                setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
            }
        }

        fetchInfo(url);
    }, [species_text_term, network_term_text, url, navigate, showBoundary]);


    useEffect(() => {
        // groupdata handling
        const sortedData = () => {
            if (data) {
                const dataArray = Object.values(data).flat();
                // Sort the array based on the order specified in the dictionary
                dataArray.sort((a, b) => OrderOfEnrichmentCategoryData[a.category] - OrderOfEnrichmentCategoryData[b.category]);
                let groupedDataValue: { [key: string]: DataObj[] } = {};

                // Group data by category
                dataArray.forEach((item) => {
                    if (!groupedDataValue[item.category]) {
                        groupedDataValue[item.category] = [];
                    }
                    groupedDataValue[item.category].push(item);
                });
                Object.keys(groupedDataValue).map((category) => (
                    groupedDataValue[category].sort((a, b) => a.rank - b.rank)
                ))
                setGroupedData(groupedDataValue);
            }
        }
        sortedData();
    }, [data]);

    // expand and collapse table
    function toggleEnrichmentTableExpansion(index: number) {
        // we can read the current expansion state from the class list of the headline
        var table = document.getElementById(`table${index}`);
        if (!table) {
            return;
        }
        // get the div child of the th element and change the class
        let div = document.getElementById(`toggleTriangle${index}`);
        if (!div) {
            return;
        }
        let new_state;
        if (div.classList.contains("fully_expanded")) {
            new_state = 'not_expanded';
        } else {
            new_state = 'fully_expanded';
        }
        // change border randius depending on the state
        let headerDiv = document.getElementById(`interactorHeader${index}`);
        if (!headerDiv) {
            return;
        }
        if (new_state === 'not_expanded') {
            headerDiv.style.borderBottomLeftRadius = "5px";
            headerDiv.style.borderBottomRightRadius = "5px";
            headerDiv.style.borderBottom = "none";
        } else {
            headerDiv.style.borderBottomLeftRadius = "0px";
            headerDiv.style.borderBottomRightRadius = "0px";
            headerDiv.style.borderBottom = "1px solid #A0A0A0";
        }
        div.classList.remove("not_expanded");
        div.classList.remove("fully_expanded");
        div.classList.add(new_state);

        var row_overflow_display = 'none';
        if (new_state === 'not_expanded') {
            row_overflow_display = 'none';
        }
        if (new_state === 'fully_expanded') {
            row_overflow_display = 'block';
        }
        // show or hide the corresponding rows:
        var table_rows = table.getElementsByClassName("overflow_row");
        var i;
        for (i = 0; i < table_rows.length; i++) {
            var table_row = table_rows[i] as HTMLElement;
            table_row.style.display = row_overflow_display;
        }
    }

    const handleClick = async (termId: string, member_count: number) => {

        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        if (species_text_term === undefined) {
            return;
        }
        try {

            let requiredScore = "400";
            let networkFlavor = "evidence"
            var too_large_network_count = 200;
            if (member_count > too_large_network_count){
                requiredScore = "900";
                networkFlavor = "confidence";
            }
            const result = await updateInput(
                "term", species_text_term, termId, requiredScore, "functional",
                networkFlavor, "0", "0", "on", "on",
                "on", "on", "on", "on", "on", "", ""
            );
            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                return;
            }
            if (result) {
                let expandedViews = "";
                Object.keys(groupedData).forEach((category, index) => {
                    let div = document.getElementById(`toggleTriangle${index}`);
                    if (!div) {
                        return;
                    }
                    if (div.classList.contains("fully_expanded")) {
                        expandedViews = `${expandedViews}${index},`;
                    }
                });

                localStorage.setItem('expandedViews', expandedViews);
                localStorage.setItem('scrollPosition', JSON.stringify(window.scrollY));

                navigate(result.path, {state: result.newData});
                return;
            }
        } catch (error) {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        }
        submit_button.disabled = false;
    };

    return (
        <>
            <Helmet>
                <title> searching ... STRING: functional protein association networks</title>
            </Helmet>

            {dataIsSet ? (
                    <div className="menu_content">
                        <div className="search_input_wrap">
                            <div className="search_input_box">
                                <div className="box_title">Geneset by<br/> Pathway / Process / Disease / Publication
                                </div>
                                <div className="error_info_message">There are multiple matches
                                    for <b>'{network_term_text}'</b> filtered for genesets found in
                                    <b> '{species_text_term}'</b>. Please proceed with one from the list below.
                                </div>
                                {Object.keys(groupedData).map((category, index, array) => (
                                    <div className="table-container" key={index}>
                                        <div id={`table${index}`} className="enrichment_table">
                                            <div id={`interactorHeader${index}`}
                                                 className={(index === array.length - 1 ? "interactor_header rounded-header" : "interactor_header rounded_header_last")}
                                                 style={{height: "40px"}}
                                                 onClick={() => toggleEnrichmentTableExpansion(index)}
                                            >
                                                <div className="not_expanded" id={`toggleTriangle${index}`}></div>
                                                {fullNameOfEnrichmentCategoryData[agotoolCategories[category]]}
                                            </div>
                                            <div className="enrichment_table_body">
                                                {groupedData[category].map((dataObj, dataIndex, dataArray) => (
                                                    <div key={`${category}_${dataIndex}`} id={`${category}_${dataIndex}`}
                                                         style={dataIndex === dataArray.length - 1 ? {display: "none"} : {
                                                             display: "none",
                                                             borderBottom: "1px solid #A0A0A0"
                                                         }}
                                                         className={`overflow_row enrichment_table_content_container ${dataIndex === groupedData[category].length - 1 ? 'last-row' : ''}`}
                                                    >
                                                        <div className="term_enrichment_cell">
                                                            <div className={"cell_large"}>
                                                                <a href={dataObj.termLinkout}
                                                                   rel="noopener noreferrer" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}>{dataObj.termId} </a><br/>
                                                                <div className="disambiguation_enrichment_cell">
                                                                    <HighlightMatches text={dataObj.description}
                                                                                      queryText={network_term_text as string}/>
                                                                    <span className="enrichment_table_small_title">Protein count: </span>
                                                                    {dataObj.member_count}
                                                                </div>
                                                            </div>
                                                            <div className=" cell_small">
                                                                <button className="large_submit_button"
                                                                        style={{height: "30px", width: "10vw"}}
                                                                        onClick={() => handleClick(dataObj.termId, dataObj.member_count)}
                                                                >→
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>) :
                (<PollingMessage/>)}
        </>
    );
}
export default SearchTermDisambiguation;
