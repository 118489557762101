interface DataObj {
    taskId: string;
    sessionId: string;
    species: string;
    inputItems: string;
    proteinCount: number;
    nodeCount: number;
    pageTitle: string;
    Error?: string;
    ErrorMessage?: string;
    channel1?: string;
    channel2?: string;
    channel3?: string;
    channel4?: string;
    channel5?: string;
    channel6?: string;
    channel7?: string;
}

const updateInput = async (callOrigin: string,
                           species: string,
                           identifier: string,
                           requiredScore: string,
                           networkType: string,
                           networkFlavor: string,
                           limit: string,
                           additionalNetworkNodes: string,
                           channel1: string,
                           channel2: string,
                           channel3: string,
                           channel4: string,
                           channel5: string,
                           channel6: string,
                           channel7: string,
                           netFdr: string,
                           sessionId: string
) => {

    const UrlSTRING = 'https://string-db.org';
    let url = '';
    let inputName = "identifiers";
    if (callOrigin === 'term') {
        inputName = 'network_term_text'
    } else if (callOrigin === 'geneset') {
        inputName = 'network_internal_ids'
    } else if (callOrigin === 'ProteinSequences') {
        inputName = 'sequences'
    } else if (identifier.trim().toUpperCase() === "RANDOM"){
        inputName = 'identifier'
    }


    let openIdAuthentifiedId = document.cookie.split(';').find((cookie) => cookie.includes('m.string_login_credentials'))?.split('=')[1];
    if (sessionId === "") {
        let id = document.cookie.split(';').find((cookie) => cookie.includes('string_session_id'))?.split('=')[1];
        if (id !== undefined) {
            sessionId = id;
        }
    }

    if (species && identifier && requiredScore && networkType && networkFlavor && limit && additionalNetworkNodes && channel1 && channel2 && channel3 && channel4 && channel5 && channel6 && channel7) {
        url = `${UrlSTRING}/cgi/api?apiMethod=init_taskdata&apiOutputType=json&${inputName}=${identifier.trim().replaceAll('\r',
            '%0d')}&species=${species}&networkType=${networkType}&requiredScore=${requiredScore}&limit=${limit}&networkFlavor=${networkFlavor}&additionalNetworkNodes=${additionalNetworkNodes}&channel1=${channel1}&channel2=${channel2}&channel3=${channel3}&channel4=${channel4}&channel5=${channel5}&channel6=${channel6}&channel7=${channel7}`;
    }
    if (openIdAuthentifiedId) {
        url = url + `&openIdAuthentifiedId=${openIdAuthentifiedId}`;
    }
    if (callOrigin === 'MultipleProteins' && url !== '' && netFdr) {
        url = url + `&FDR_cutoff=${netFdr}`;
    }
    if (sessionId) {
        url = url + `&sessionId=${sessionId}`;
    }

    url += '&caller_identity=mobileapp';

    const randomParam = new Date().getTime();
    url += `&nocache=${randomParam}`;

    if (url !== '') {
        let d: DataObj[];
        try {
            const res = await fetch(url);
            d = await res.json();
        } catch (error: any) {
            let logurl = `${UrlSTRING}/cgi/api?apiOutputType=json&apiMethod=log_mobile_error&caller_identity=mobileapp`;
            try {
                await fetch(`${logurl}&mobile_error_message=update_input:${error.stack}%0A%0Aurl:${url}%0A`.replaceAll('  ', '%0A'));
            } catch (error) {
                //
            }
            return ({
                path: 'Not found',
                newData: {
                    title: 'Error',
                    message: 'Something went wrong! -- please contact STRING maintainers if the issue persists.'
                }
            });
        }
        const data = d.map((item) => ({
            taskId: item.taskId,
            sessionId: item.sessionId,
            species: item.species,
            inputItems: item.inputItems,
            proteinCount: item.proteinCount,
            nodeCount: item.nodeCount,
            pageTitle: item.pageTitle,
            Error: item.Error,
            ErrorMessage: item.ErrorMessage,
            channel1: item.channel1,
            channel2: item.channel2,
            channel3: item.channel3,
            channel4: item.channel4,
            channel5: item.channel5,
            channel6: item.channel6,
            channel7: item.channel7,
        }));
        if (data[0].Error) {
            let errorData = {
                title: data[0].Error,
                message: data[0].ErrorMessage
            }
            return {
                path: '/error',
                newData: errorData
            };
        }
        if (data.length > 0) {
            let newIdentifiers;
            if (callOrigin === 'term' || identifier.includes(">") || identifier.toLowerCase().includes("random")) {
                newIdentifiers = data[0].inputItems;
            } else {
                newIdentifiers = identifier;
            }
            // Construct the new path
            const newPath = `/cgi/network/${data[0].taskId}/${data[0].sessionId}`;

            // update sessionId in cookie
            document.cookie = `string_session_id=${data[0].sessionId};`;

            // Return the new path along with the data
            return {
                path: newPath,
                newData: {
                    species: data[0].species,
                    identifier: newIdentifiers,
                    requiredScore: requiredScore,
                    networkType: networkType,
                    networkFlavor: networkFlavor,
                    limit: limit,
                    additionalNetworkNodes: additionalNetworkNodes,
                    channel1: data[0].channel1,
                    channel2: data[0].channel2,
                    channel3: data[0].channel3,
                    channel4: data[0].channel4,
                    channel5: data[0].channel5,
                    channel6: data[0].channel6,
                    channel7: data[0].channel7,
                    netFdr: netFdr,
                    proteinCount: data[0].proteinCount,
                    nodeCount: data[0].nodeCount,
                    pageTitle: data[0].pageTitle,
                    sessionId: data[0].sessionId,
                }
            };
        }

    }
    return null;
}

export default updateInput;