import {useContext, useEffect, useState} from "react";
// import {useNavigate} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";
import {useLocation} from "react-router-dom";

interface DataObj {
    number_of_nodes: number;
    number_of_edges: number;
    average_node_degree: number;
    local_clustering_coefficient: number;
    expected_number_of_edges: number;
    p_value: any;
    networkHasMoreInteractions: boolean;
}

function formatNumberToScientific(num: number): string {
    const numAsString = num.toFixed(3); // Convert number to a string with 3 decimal places
    // Check if the last 3 characters after the decimal point are zeros
    const areZeros = numAsString.slice(-3) === '000';
    // If the last 3 characters are zeros, convert to scientific notation; otherwise, return the number as is
    return areZeros ? num.toExponential() : numAsString;
}

function doesNetworkHaveMoreInteractions(p_value: number): boolean {
    return (p_value < 0.05)
}

const NetworkStats = ({taskId, sessionId}: { taskId: string, sessionId: string }) => {
    const {UrlSTRING,} = useContext(GlobalContext);

    const location = useLocation()
    const {
        dataAll
    } = location.state;

    const {showBoundary} = useErrorBoundary();

    const [data, setData] = useState<DataObj[]>([]);
    // const navigate = useNavigate();
    useEffect(() => {
        // Define the function correctly
        const formatPPIData = (respData: DataObj[]) => {
            respData = Array.isArray(respData) ? respData : [respData];
            return respData.map((item) => ({
                number_of_nodes: item.number_of_nodes,
                number_of_edges: item.number_of_edges,
                average_node_degree: Number(item.average_node_degree.toFixed(3)),
                local_clustering_coefficient: Number(item.local_clustering_coefficient.toFixed(3)),
                expected_number_of_edges: Number(item.expected_number_of_edges.toFixed(3)),
                p_value: formatNumberToScientific(item.p_value),
                networkHasMoreInteractions: doesNetworkHaveMoreInteractions(item.p_value)
            }));
        };

        const fetchPPIEnrichmentData = async () => {
            if (taskId) {
                const url = `${UrlSTRING}/api/json/ppi_enrichment?taskId=${taskId}&sessionId=${sessionId}&caller_identity=mobileapp`;
                try {
                    const res = await fetch(url);
                    const respData: DataObj[] = await res.json();
                    const formattedData = formatPPIData(respData);  // Capture the returned formatted data
                    setData(formattedData);  // Use the formatted respData
                } catch (error) {
                    setTimeout(() => showBoundary(error), 2000);
                }
            } else {
                setData(formatPPIData(dataAll[0]["ppiEnrichment"]));  // Use formatted data
            }
        };

        fetchPPIEnrichmentData();
    }, [sessionId, showBoundary, taskId]);



    return (
        <div>
            <div className="legend_headline"> Network Stats</div>

            <table className="netstat_table" style={{margin: '0 auto'}}>
                <tbody>
                <tr>
                    <td className="left_cell">number of nodes:</td>
                    <td className="right_cell">{data[0]?.number_of_nodes} </td>
                </tr>
                <tr>
                    <td className="left_cell">number of edges:</td>
                    <td className="right_cell">{data[0]?.number_of_edges} </td>
                </tr>
                <tr>
                    <td className="left_cell">average node degree:</td>
                    <td className="right_cell">{data[0]?.average_node_degree} </td>
                </tr>
                <tr>
                    <td className="left_cell">avg. local clustering coefficient:</td>
                    <td className="right_cell">{data[0]?.local_clustering_coefficient} </td>
                </tr>

                <tr>
                    <td className="left_cell">expected number of edges:</td>
                    <td className="right_cell">{data[0]?.expected_number_of_edges} </td>
                </tr>
                <tr>
                    <td className="left_cell">PPI enrichment p-value:</td>
                    {data[0]?.p_value < 1.0e-16 ? (
                        <td className="right_cell"> {`< 1.0e-16`}  </td>
                    ) : (
                        <td className="right_cell">{data[0]?.p_value} </td>
                    )}
                </tr>
                <tr style={{borderBottom: "none"}}>
                    {data[0] ? data[0].networkHasMoreInteractions ? (
                        <td className="ppi_enrichment_hint" colSpan={2}>your network has significantly
                            more <br/> interactions
                            than expected
                        </td>
                    ) : (
                        <td className="ppi_enrichment_hint" colSpan={2}>your network does <span
                            className='emphasis'>not</span> have significantly <br/> more
                            interactions than expected
                        </td>
                    ) : null}
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default NetworkStats;