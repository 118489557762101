import React, { useEffect } from 'react';

type NotificationProps = {
    message: string;
    duration?: number;
    onClose: () => void;
};

const Notification: React.FC<NotificationProps> = ({ message, duration = 4000, onClose }) => {
    useEffect(() => {
        // Set a timer to close the notification after `duration` milliseconds
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        // Cleanup the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div className="notification">
            {message}
        </div>
    );
};

export default Notification;
