import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import PollingMessage from "../components/PollingMessage";
import updateInput from "../utils/update_input";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";
import {Helmet} from "react-helmet-async";

interface OrganismData {
    organismName: string;
    allSynonymsText: string;
    organismTaxon: string;
    organismType: string;
    organismProteinCount: number;
    organismExampleIdentifiers: string;
    permanentLink: string;
    downloadPageLink: string;
    Error?: string;
    ErrorMessage?: string;
}

const OrganismPage = () => {

    const {organism} = useParams()
    // get organism data
    const [data, setData] = React.useState<OrganismData[]>([]);
    const navigate = useNavigate();
    const {UrlSTRING,} = useContext(GlobalContext);
    const {showBoundary} = useErrorBoundary();

    const getRandUrl = async (event: any) => {
        event.preventDefault();
        if (!organism) {
            return;
        }
        try {
            const result = await updateInput(
                "SingleProtein", organism, "random", "400", "functional",
                "evidence", "10", "0", "on", "on", "on",
                "on", "on", "on", "on", "", ""
            )
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

            if (result?.path === 'Not found') {
                navigate('/error', {state: result.newData});
                return;
            }

            if (result) {
                navigate(result.path, {state: result.newData});
                return;
            }
        } catch (error) {
            setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
        }
    }

    useEffect(() => {
        const url = `${UrlSTRING}/cgi/api?apiOutputType=json&apiMethod=get_organism_data&species=${organism}&caller_identity=mobileapp`
        fetch(url)
            .then((res) => res.json())
            .then((d) => {
                const formattedData = d.map((item: any) => ({
                    organismName: item.organismName,
                    allSynonymsText: item.allSynonymsText,
                    organismTaxon: item.organismTaxon,
                    organismType: item.organismType,
                    organismProteinCount: item.organismProteinCount,
                    organismExampleIdentifiers: item.organismExampleIdentifiers,
                    permanentLink: item.permanentLink,
                    downloadPageLink: `${UrlSTRING}${item.downloadPageLink}`,
                    Error: item.Error,
                    ErrorMessage: item.ErrorMessage
                }));
                setData(formattedData);
                if (formattedData[0].Error) {
                    let errorData = {
                        title: formattedData[0].Error,
                        message: formattedData[0].ErrorMessage
                    }
                    navigate('/error', {state: errorData});
                    return;
                }
            })
            .catch((error) => {
                setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
                }
            );
        document.title = data.length === 0 ? "STRING: functional protein association networks" : `STRING: ${data[0].organismName}`;
    }, [UrlSTRING, navigate, organism, showBoundary]);

    return (
        <>
            <Helmet>
                <link rel="canonical" href={UrlSTRING + window.location.pathname}/>
            </Helmet>

            {data.length === 0 ? (
                <PollingMessage/>
            ) : (
                <div className="menu_content">
                    <div className="edge_page_wrap">
                        <h1>ORGANISM</h1>
                        <div className="edge_function_data">
                            <div className={"organism_table"}>
                                <div>
                                    <div className="interaction_column_title">Organism display name</div>
                                    <div className="organism_column_data">{data[0].organismName}</div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">Synonyms</div>
                                    <div className="organism_column_data">{data[0].allSynonymsText}</div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">Taxon identifier</div>
                                    <div className="organism_column_data">{data[0].organismTaxon}</div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">STRING species type</div>
                                    <div className="organism_column_data">{data[0].organismType}</div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">Number of proteins</div>
                                    <div className="organism_column_data">{data[0].organismProteinCount}</div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">Example sequence identifiers</div>
                                    <div className="organism_column_data">{data[0].organismExampleIdentifiers}</div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">Random protein network</div>
                                    <div className="link_wrap">
                                        <button style={{padding: "3px"}} onClick={e => getRandUrl(e)}>SHOW RANDOM
                                            NETWORK
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">Download files</div>
                                    <div className="link_wrap"><a
                                        href={data[0].downloadPageLink}>{data[0].downloadPageLink}</a></div>
                                </div>
                                <div>
                                    <div className="interaction_column_title">Shareable link (citable)</div>
                                    <div className="link_wrap" style={{borderBottom: "none"}}><a
                                        href={data[0].permanentLink}>{data[0].permanentLink}</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OrganismPage;