import React, {useState, useEffect, useRef} from 'react';
import {useSwipeable} from 'react-swipeable';


const Carousel: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const totalItems = 4;
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const startInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % totalItems);
        }, 5000);
    };

    useEffect(() => {
        startInterval();
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const goToSlide = (index: number) => {
        setActiveIndex(index);
        startInterval();
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % totalItems);
            startInterval();
        },
        onSwipedRight: () => {
            setActiveIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
            startInterval();
        },
        trackMouse: true
    });

    return (
        <div className="carousel" {...handlers}>
            <div className="carousel-inner">
                <div key={0} className={` site_info carousel-item ${0 === activeIndex ? 'active' : ''}`}>
                    Protein-Protein Interaction Networks, Functional Enrichment Analysis
                </div>

                <div key={1} className={` site_info carousel-item ${1 === activeIndex ? 'active' : ''}`}>
                    Featuring detailed information on <span
                    style={{color: "#848ec2", fontWeight: "700"}}>12,535</span> organisms
                </div>

                <div key={2} className={` site_info carousel-item ${2 === activeIndex ? 'active' : ''}`}>
                    Includes <span style={{color: "#848ec2", fontWeight: "700"}}>59.3</span> million proteins with over
                    <span style={{color: "#848ec2", fontWeight: "700"}}> 20</span> billion interactions
                </div>

                <div key={3} className={` site_info carousel-item ${3 === activeIndex ? 'active' : ''}`}>
                    A Core Data Resource as designated by <div id="GBC_logo"></div>
                </div>

            </div>
            <div className="carousel-indicators">
                {Array.from({length: totalItems}).map((_, index) => (
                    <div
                        key={index}
                        aria-label={`Go to slide ${index}`}
                        className={`indicator ${index === activeIndex ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Carousel;
