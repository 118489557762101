import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";

interface NetworkNode {
    item: string
    preferredName: string
    identifier: string
    externalId: string
    annotation: string
    color: string
}

interface sortOptions {
    [key: number]: string;
}

const sortOptionsData: sortOptions = {
    0: "Node name",
    1: "Confidence (High to Low)",
    2: "Confidence (Low to High)"
}

const EdgeSelector = ({
                          taskId,
                          sessionId,
                          requiredScore
                      }: { taskId: string, sessionId: string, requiredScore: string }) => {

    const [uniqueNodesDict, setUniqueNodes] = useState({} as any);
    const [edgeDict, setEdgeDict] = useState({} as any);
    const [selectedNode, setSelectedNode] = useState<NetworkNode>();
    const [selectedNodeInteractorsDict, setSelectedNodeInteractorsDict] = useState({} as any);
    const [sortedNodeKeys, setSortedNodeKeys] = useState([] as any);
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(1);
    const [SortingFilter, setSortingFilter] = useState(1);
    const [dataFetched, setDataFetched] = useState(false)
    const [organism, setOrganism] = useState("");

    const {showBoundary} = useErrorBoundary();

    const navigate = useNavigate()

    const {UrlSTRING,} = useContext(GlobalContext);

    const location = useLocation()
    const {
        dataAll
    } = location.state;

    useEffect(() => {
        setSortingFilter(1);
        const fetchEdgeData = async () => {
            try {
                const edgesDict = {} as any;
                var url;
                let data;
                if (taskId) {
                    url = `${UrlSTRING}/cgi/api?apiMethod=all_interactions&apiOutputType=json&taskId=${taskId}&sessionId=${sessionId}&requiredScore=${requiredScore}&caller_identity=mobileapp`
                    const response = await fetch(url);
                    data = await response.json();
                } else {
                    data = dataAll[0]["allInteractions"];
                }
                if (!data) {
                    return;
                }

                setOrganism(data[0].species);

                let resultData;
                if (data[0].physical !== undefined) {
                    resultData = data[0].physical
                }
                if (data[0].functional !== undefined) {
                    resultData = data[0].functional
                }
                for (const edge in resultData) {
                    edgesDict[edge] = resultData[edge].combinedScoreFraction;
                }
                setEdgeDict(edgesDict);

            } catch (error) {
                setTimeout(() => {
                    showBoundary(error);
                }, 2000); // Delay of 2 seconds
            }
        }

        const fetchNodeData = async () => {
            try {
                var url;
                var data;
                if (taskId) {
                    url = `${UrlSTRING}/cgi/api?apiMethod=all_nodes_data&apiOutputType=json&taskId=${taskId}&sessionId=${sessionId}&requiredScore=${requiredScore}&caller_identity=mobileapp`
                    const response = await fetch(url);
                    data = await response.json();
                } else {
                    data = dataAll[0]["allNodesData"]
                }
                if (!data) {
                    return;
                }
                data = data[0]['nodes'];
                let newUniqueNodesDict = {} as any;
                for (const node in data) {
                    newUniqueNodesDict[node] = {} as NetworkNode;
                    newUniqueNodesDict[node].item = node;
                    newUniqueNodesDict[node].identifier = data[node].identifier;
                    newUniqueNodesDict[node].nameToShow = data[node].nameToShow;
                    newUniqueNodesDict[node].annotation = data[node].annotation;
                }
                // Get the keys of newUniqueNodesDict
                const keys = Object.keys(newUniqueNodesDict);
                keys.sort((a, b) => newUniqueNodesDict[a].nameToShow.localeCompare(newUniqueNodesDict[b].nameToShow));
                let newUniqueNodesDictSorted = {} as any;
                keys.forEach(key => {
                    newUniqueNodesDictSorted[key] = newUniqueNodesDict[key];
                });
                setUniqueNodes(newUniqueNodesDictSorted);
            } catch (error) {
                setTimeout(() => {
                    showBoundary(error);
                }, 2000); // Delay of 2 seconds
            }
        }

        try {
            fetchEdgeData();
            fetchNodeData();
        } finally {
            setDataFetched(true);
        }

    }, [taskId, sessionId, requiredScore, navigate, UrlSTRING, showBoundary]);

    const handleNodeChange = useCallback((e: any) => {
        const selectedNodeValue = e.target.value;
        if (uniqueNodesDict[selectedNodeValue] === undefined) {
            return;
        }
        setSelectedNode(uniqueNodesDict[selectedNodeValue]);
        let newDictOfNodes = {} as any;
        for (const elm in edgeDict) {
            let [node1, node2] = elm.split(".");
            if (node1 === selectedNodeValue) {
                newDictOfNodes[node2] = uniqueNodesDict[node2];
            }
            if (node2 === selectedNodeValue) {
                newDictOfNodes[node1] = (uniqueNodesDict[node1]);
            }
        }

        // sort nodes by sorting option
        let sortedKeys = Object.keys(newDictOfNodes);
        // check if SortingFilter is a string
        switch (SortingFilter) {
            case 0:
                sortedKeys.sort((a: any, b: any) => newDictOfNodes[a].term.localeCompare(newDictOfNodes[b].term));
                break;
            case 1:
                sortedKeys.sort((a: any, b: any) => {
                    let [item1A, item2A] = [selectedNodeValue, a].sort();
                    let [item1B, item2B] = [selectedNodeValue, b].sort();
                    let edgeKeyA = `${item1A}.${item2A}`;
                    let edgeKeyB = `${item1B}.${item2B}`;
                    return edgeDict[edgeKeyB] - edgeDict[edgeKeyA];
                })
                break;
            case 2:
                sortedKeys.sort((a: any, b: any) => {
                    let [item1A, item2A] = [selectedNodeValue, a].sort();
                    let [item1B, item2B] = [selectedNodeValue, b].sort();
                    let edgeKeyA = `${item1A}.${item2A}`;
                    let edgeKeyB = `${item1B}.${item2B}`;
                    return edgeDict[edgeKeyA] - edgeDict[edgeKeyB];
                })
                break;
            default:
                sortedKeys.sort((a: any, b: any) => {
                    let [item1A, item2A] = [selectedNodeValue, a].sort();
                    let [item1B, item2B] = [selectedNodeValue, b].sort();
                    let edgeKeyA = `${item1A}.${item2A}`;
                    let edgeKeyB = `${item1B}.${item2B}`;
                    return edgeDict[edgeKeyB] - edgeDict[edgeKeyA];
                })
                break;
        }

        setSortedNodeKeys(sortedKeys);
        setSelectedNodeInteractorsDict(newDictOfNodes);

        localStorage.setItem('selectedNode', JSON.stringify(selectedNodeValue));
    }, [uniqueNodesDict, edgeDict, SortingFilter]);

    // select the first node by default
    useEffect(() => {
        if (Object.keys(uniqueNodesDict).length === 0 || Object.keys(edgeDict).length === 0) {

            return
        }
        if (sortedNodeKeys && selectedNode && selectedNodeInteractorsDict) {
            return
        }
        let sortedKeys = Object.keys(uniqueNodesDict);
        sortedKeys.sort((a, b) => uniqueNodesDict[a].nameToShow.localeCompare(uniqueNodesDict[b].nameToShow));
        let firstKey = sortedKeys[0];
        if (localStorage.getItem('selectedNode') !== null) {
            let previous = JSON.parse(localStorage.getItem('selectedNode') as string);
            if (uniqueNodesDict[previous] !== undefined) {
                firstKey = previous;
            }
        }
        handleNodeChange({target: {value: firstKey}});

    }, [uniqueNodesDict, edgeDict, taskId, sessionId, requiredScore, selectedNode, selectedNodeInteractorsDict, sortedNodeKeys, handleNodeChange]);


    const OpenEdgePage = (interactor: string) => {
        localStorage.setItem('activeView', "bottom_page_selector_edges");
        localStorage.setItem('scrollPosition', JSON.stringify(window.scrollY));
        localStorage.setItem('selectedNode', JSON.stringify(selectedNode?.item));

        // let organism = document.getElementsByName("species")[0].getAttribute("value");
        // get option and option2 values
        let internalId1 = selectedNode?.identifier;
        // navigate to edge page
        let url = `/interaction/${organism}.${internalId1}/${organism}.${interactor}`;
        navigate(url)
        return;
    }

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////// edges sorting functions
    ////////////////////////////////////////////////////////////////////////////

    function handleSortChange(elm: any) {
        let sortedKeys = sortedNodeKeys

        if (elm !== "null") {
            switch (elm.id) {
                case '0':
                    // sort by term
                    sortedKeys.sort((a: any, b: any) => selectedNodeInteractorsDict[a].nameToShow.localeCompare(selectedNodeInteractorsDict[b].nameToShow));
                    break;
                case '1':
                    // sort by confidence (high to low)
                    sortedKeys.sort((a: any, b: any) => {
                        let [item1A, item2A] = [selectedNode?.item, a].sort();
                        let [item1B, item2B] = [selectedNode?.item, b].sort();
                        let edgeKeyA = `${item1A}.${item2A}`;
                        let edgeKeyB = `${item1B}.${item2B}`;
                        return edgeDict[edgeKeyB] - edgeDict[edgeKeyA];
                    })
                    break;
                case '2':
                    // sort by confidence
                    sortedKeys.sort((a: any, b: any) => {
                        let [item1A, item2A] = [selectedNode?.item, a].sort();
                        let [item1B, item2B] = [selectedNode?.item, b].sort();
                        let edgeKeyA = `${item1A}.${item2A}`;
                        let edgeKeyB = `${item1B}.${item2B}`;
                        return edgeDict[edgeKeyA] - edgeDict[edgeKeyB];
                    })
                    break;
                default:
                    sortedKeys.sort((a: any, b: any) => {
                        let [item1A, item2A] = [selectedNode?.item, a].sort();
                        let [item1B, item2B] = [selectedNode?.item, b].sort();
                        let edgeKeyA = `${item1A}.${item2A}`;
                        let edgeKeyB = `${item1B}.${item2B}`;
                        return edgeDict[edgeKeyB] - edgeDict[edgeKeyA];
                    })
                    break;
            }
            setSortedNodeKeys(sortedKeys);
            setSortingFilter(elm.id);
        }
    }

    const handleApply = () => {
        let submit_button = document.getElementsByClassName("large_submit_button")[0] as HTMLButtonElement;
        submit_button.disabled = true;
        submit_button.classList.add("large_submit_button_active");

        // get name="radio-group"
        const selectedOption = document.querySelector('input[name="radio-group"]:checked');
        if (!selectedOption) {
            return;
        }

        let id = selectedOption.id.slice(-1)
        let elm;
        const labels = document.querySelectorAll('.filter-modal__content label');

        labels.forEach(label => {
            if (label.id === id) {
                elm = label;
            }
        });

        handleSortChange(elm)
        setIsOpen(false);
        submit_button.disabled = false;

    };


    const handleRadioClick = (index: number) => {
        setSelected(index);
    };

    // more and less functions

    const [isExpanded, setIsExpanded] = useState({} as any);

    const toggleExpanded = (node: string) => {
        setIsExpanded((prevIsExpanded: Record<string, boolean>) => ({
            ...prevIsExpanded,
            [node]: !prevIsExpanded[node] // Toggle the value for the specified node
        }));
    };

    const wrapText = (text: string, node: string, maxLines: number, container: HTMLElement): {
        truncatedTextItem: string;
        truncatedItem: boolean
    } => {
        if (isExpanded[node]) {
            return {truncatedTextItem: text, truncatedItem: false}; // Return the original text if the node is expanded
        }

        // Create a temporary span to measure the rendered text
        const tempSpan = document.createElement("span");

        // give span the same width as the container
        tempSpan.style.width = `${container.offsetWidth}px`;

        document.body.appendChild(tempSpan);

        let truncatedText = text;
        tempSpan.innerText = text;

        // Truncate until the height fits within the maxLines
        let truncated = false;
        while (tempSpan.offsetHeight > maxLines * parseFloat(getComputedStyle(tempSpan).lineHeight || "0")) {
            // remove 1 word from the end of the text
            truncatedText = truncatedText.replace(/\s+\S*$/, "");
            tempSpan.innerText = truncatedText + "...(More)";
            truncated = true;
        }

        if (truncated) {
            tempSpan.innerText = truncatedText + "...";
        }

        document.body.removeChild(tempSpan);
        return {truncatedTextItem: truncatedText, truncatedItem: truncated};
    };

    const containerRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const [truncatedText, setTruncatedText] = useState<{ [key: string]: string }>({});
    const [truncatedTextB, setTruncatedTextBoolean] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const updatedTruncatedText: { [key: string]: string } = {};
        const updatedTruncatedBoolean: { [key: string]: boolean } = {};

        Object.keys(selectedNodeInteractorsDict).forEach((item) => {
            const container = containerRefs.current[item];
            if (container) {
                const {
                    truncatedTextItem,
                    truncatedItem
                } = wrapText(selectedNodeInteractorsDict[item].annotation, item, 3, container);
                updatedTruncatedText[item] = truncatedTextItem;
                updatedTruncatedBoolean[item] = truncatedItem;
            }
        });

        setTruncatedText(updatedTruncatedText);
        setTruncatedTextBoolean(updatedTruncatedBoolean);

    }, [selectedNodeInteractorsDict]); // Recalculate on data change


    return (
        <div className="main_page_content_container" id="bottom_page_selector_edges_container"
             style={localStorage.getItem('activeView') === "bottom_page_selector_edges" ? {display: "block"} : {display: "none"}}
        >
            {(Object.keys(uniqueNodesDict).length > 0 && selectedNode !== undefined && Object.keys(edgeDict).length) //&& Object.keys(selectedNodeInteractorsDict).length && Object.keys(sortedNodeKeys).length
                ? (
                    <div className="settings_wrap" style={{
                        display: 'inline-block',
                        paddingRight: "2%",
                        paddingLeft: "2%",
                        width: "100%"
                    }}>
                        <div className="settings_box_wrap">
                            <div className="data_settings_wrap">
                                {/*error_info_wrap*/}
                                <div className="error_info_box">
                                    <div className="box_body data_settings_body">
                                        <select id="node_list" onChange={handleNodeChange}
                                                className="data_settings_select_large"
                                                style={{width: "100%", height: "40px"}}
                                                autoComplete="off"
                                                value={selectedNode.item}
                                        >
                                            {Object.keys(uniqueNodesDict)
                                                .sort((a, b) => uniqueNodesDict[a].nameToShow.localeCompare(uniqueNodesDict[b].nameToShow))
                                                .map((node) => (
                                                    <option value={node} key={node}>
                                                        {uniqueNodesDict[node].nameToShow} [{uniqueNodesDict[node].identifier}]
                                                    </option>
                                                ))}
                                        </select>

                                        {isOpen ? (
                                            // small screen case
                                            <div className="filter-modal">
                                                <div className="filter-modal__wrapper"
                                                     aria-label="modal window">
                                                    <div className="filter-modal__header">
                                                        <div>
                                                            <div className="movable_div_title_bar"
                                                                 data-parent_div_to_move_id="fpWindowDiv">Node
                                                                Interactors Sorting
                                                            </div>
                                                            <div className="movable_div_closelink_wrapper">
                                                                <button className="floatingdivcloselink "
                                                                        title="click to close"
                                                                        onClick={() => setIsOpen(false)}>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="filter-modal__content">
                                                        {Object.keys(sortOptionsData).map((filter, index) => (
                                                            <div key={index}
                                                                 onClick={() => handleRadioClick(index)}>
                                                                <input
                                                                    type="radio"
                                                                    id={`radio${index}`}
                                                                    name="radio-group"
                                                                    className="radio-input"
                                                                    checked={selected === index}
                                                                    onChange={() => handleRadioClick(index)}
                                                                />
                                                                <label
                                                                    id={`${index}`}>{sortOptionsData[index]}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="filter-modal__actions">
                                                        <button onClick={handleApply}
                                                                className="large_submit_button"
                                                                style={{marginBottom: "10px"}}
                                                        >
                                                            &nbsp;Apply&nbsp;
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                        ) : null}

                                        <div className="filter" style={{width: "100%"}}>
                                            <button className="filter_button"
                                                    onClick={() => setIsOpen(!isOpen)}>Sort by
                                                : {sortOptionsData[SortingFilter]}</button>
                                        </div>
                                        {Object.keys(selectedNodeInteractorsDict).length ?
                                            (
                                                <div className="interactors_table">
                                                    <div className={"interactor_header node_rounded_header"}
                                                         style={{height: "40px"}}>
                                                        List of all selected node interactors
                                                    </div>
                                                    {sortedNodeKeys.map((item: any, index: any, array: any) => {
                                                        let [item1, item2] = [selectedNode?.item, item].sort();
                                                        let edgeKey = `${item1}.${item2}`;
                                                        return (
                                                            <div
                                                                className={(index === array.length - 1 ? "last_row_wrap" : "row_wrap")}
                                                                key={item}>
                                                                <div className={"cell_large"}
                                                                     style={{width: "100%", padding: "3vw"}}>
                                                                    <div className={"interactor_title"}
                                                                         style={{
                                                                             display: "flex",
                                                                             flexWrap: "wrap",
                                                                             overflowWrap: "break-word",
                                                                             wordWrap: "break-word",
                                                                             whiteSpace: "normal"
                                                                         }}
                                                                    >
                                                                   <span
                                                                       style={{fontWeight: "bold"}}>  {selectedNodeInteractorsDict[item].nameToShow}&nbsp;</span>
                                                                        <i> [{selectedNodeInteractorsDict[item].identifier}]</i>
                                                                        <button
                                                                            className={"show_button"}
                                                                            style={{
                                                                                marginLeft: "auto",
                                                                                whiteSpace: "nowrap" // Prevents button text from breaking
                                                                            }}
                                                                            onClick={() => {
                                                                                OpenEdgePage(selectedNodeInteractorsDict[item].identifier);
                                                                            }}
                                                                        >
                                                                            edge details
                                                                        </button>
                                                                    </div>
                                                                    <div>
                                                                        Interaction confidence: <span
                                                                        style={{fontWeight: "bold"}}>{edgeDict[edgeKey]}</span>
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.9rem",
                                                                        overflow: "hidden"
                                                                    }}>
                                                                        <div
                                                                            ref={(el) => (containerRefs.current[item] = el)}>
                                                                            {isExpanded[item]
                                                                                ? uniqueNodesDict[item].annotation
                                                                                : truncatedText[item] || uniqueNodesDict[item].annotation}&nbsp;

                                                                            {/* Show (More) or (Less) only if the text is truncated */}
                                                                            {truncatedTextB[item] ? (
                                                                                <span
                                                                                    onClick={() => toggleExpanded(item)}
                                                                                    style={{
                                                                                        fontWeight: "bold",
                                                                                        cursor: "pointer"
                                                                                    }}>
                                                                                {isExpanded[item] ? "(Less)" : "(More)"}
                                                                            </span>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "5vh 2vh 20vh 2vh"
                                                }}>
                                                    - The selected node has no interactors -
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    dataFetched ? (
                            <div id={"enrichmentTable"}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "5vh 2vh 20vh 2vh"
                                }}>
                                    - no edges detected in your network -
                                </div>
                            </div>
                        ) :
                        (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                 className='oversized_input_wait'>
                                {/*<img src={`/images/oversized_input_spinner.gif`} alt='waiting spinner'/>*/}
                                <div className="spinner"></div>
                            </div>
                        ))}
        </div>
    );
};

export default EdgeSelector;
