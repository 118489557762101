import React, {useContext, useEffect} from "react";
import "../App.css"
import {useLocation} from "react-router-dom";
import {GlobalContext} from "./GlobalProvider";

type RedirectItem = {
    url: string;
    icon: string;
};
function NavBar() {

    const {UrlSTRING,} = useContext(GlobalContext);
    const baseUrl = `${UrlSTRING}/cgi/`; // Base URL based on the route parameter

    const jumpToStringDesktop = (event: any) => {
        event.preventDefault();
        // get which page we are on
        const checkUrl = () => {
            if (window.location.pathname.includes("GetTaskDataFromSTRING")) {
                setTimeout(checkUrl, 10); // Re-check after 1 second
            }
        };
        checkUrl();

        const url = window.location.pathname;
        let urlString;
        if (url.includes("cgi/network")) {
            let taskId = url.split("/")[3];
            let sessionId = url.split("/")[4];
            urlString = `${UrlSTRING}/cgi/network?taskId=${taskId}&sessionId=${sessionId}&redirected_from_small_device=1`;
            window.location.href = urlString;
        }
        else if (url.includes("network")) {
            let species = url.split("/")[2];
            let identifier = url.split("/")[3];

            if (species && identifier){
                urlString = `${UrlSTRING}/network/${species}/${identifier}&redirected_from_small_device=1`;
            }
            else{
                // species here is actually identifier
                urlString = `${UrlSTRING}/network/${species}&redirected_from_small_device=1`;
            }

            window.location.href = urlString;
        } else if (url.includes("interaction")) {
            let identifier1 = url.split("/")[2];
            let identifier2 = url.split("/")[3];
            urlString = `${UrlSTRING}/interaction/${identifier1}/${identifier2}&redirected_from_small_device=1`;
            window.location.href = urlString;
        } else if (url.includes("organism")) {
            let species = url.split("/")[2];
            urlString = `${UrlSTRING}/organism/${species}?redirected_from_small_device=1`;
            window.location.href = urlString;
        } else if (url.includes("singleProtein")) {
            urlString = `${UrlSTRING}/cgi/input?sessionId=_nosession&input_page_active_form=single_identifier&redirected_from_small_device=1`;
            window.location.href = urlString;
        } else if (url.includes("multipleProteins")) {
            urlString = `${UrlSTRING}/cgi/input?sessionId=_nosession&input_page_active_form=multiple_identifiers&redirected_from_small_device=1`;
            window.location.href = urlString;
        } else if (url.includes("proteinSequences")) {
            urlString = `${UrlSTRING}/cgi/input?sessionId=_nosession&input_page_active_form=multiple_sequences&redirected_from_small_device=1`;
            window.location.href = urlString;
        } else if (url.includes("SearchTerm")) {
            urlString = `${UrlSTRING}/cgi/input?sessionId=_nosession&input_page_active_form=single_term&redirected_from_small_device=1`;
            window.location.href = urlString;
        } else if (url.includes("SearchOrganism")) {
            urlString = `${UrlSTRING}/cgi/input?sessionId=_nosession&organisms=off&redirected_from_small_device=1`;
            window.location.href = urlString;
        } else {
            urlString = `${UrlSTRING}/cgi/input?sessionId=_nosession&input_page_show_search=off&redirected_from_small_device=1`;
            window.location.href = urlString;
        }
    }

    const isDesktop = () => {
        const userAgent = navigator.userAgent;
        return !(/Mobile/i.test(userAgent) && !/iPad/i.test(userAgent));
    };

    // check if user is using a desktop device then we trigger jumpToStringDesktop
    useEffect(() => {
        if (isDesktop()) {
            let event = new Event('click');
            jumpToStringDesktop(event);
        }
    }, []);


    // check if user is logged in

    // Collect all instances of 'string_login_credentials' with values
    let cookies = document.cookie.split(';');
    let stringLoginCredentialsValues: Array<string> = [];
    cookies.forEach(cookie => {
        const [name, value] = cookie.trim().split('=');
        if (name === 'm.string_login_credentials' && value !== '_unassigned' && value !== undefined) {
            stringLoginCredentialsValues.push(value);
        }
    });

    const addressRedirect: Record<string, RedirectItem> = {};

    if (stringLoginCredentialsValues.length > 0) {
        addressRedirect["My History"] = {url: "/my", icon: "my_icon"};
    }
    addressRedirect["Help"] = {url: `${UrlSTRING}/help`, icon: "help_icon"};
    addressRedirect["Download"] = {url: `${baseUrl}download`, icon: "download_icon"};


    const location = useLocation()
    return (
        <>
            <div className="nav-main">
                <button className="navbar navbar-expand-md" type="button" aria-label={"menu"}
                        style={{border: "none", background: "#F7F6F2", padding: "5px"}} data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft"><span
                    className="navbar-toggler-icon"/>
                </button>
                {location.pathname !== "/" ? (<div className="left">
                    <a id="string_logo_left" className="input_switch_to_home" href="/"> STRING </a>
                    <a id="string_logo_right" href="/SearchMenu"> STRING </a>
                </div>) : null}
            </div>

            <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasLeft"
                 aria-labelledby="offcanvasLeftLabel" style={{width: "70%"}}>
                <div className="offcanvas-header">
                    <h5 id="offcanvasLeftLabel">Browse STRING</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav">

                        <li key={"Search"} className="nav-item">
                            <a className={"title nav-link"}
                               href={"/SearchMenu"}>
                                Search
                                <span className="search_icon"></span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="title nav-link" href="/" onClick={(e) => jumpToStringDesktop(e)}>
                                STRING Desktop
                                <span className="desktop_icon"></span>
                            </a>
                        </li>

                        {Object.keys(addressRedirect).map((key) => (
                            <li key={key} className="nav-item">
                                <a className={"title nav-link"}
                                   href={addressRedirect[key].url}>
                                    {key}
                                    <span className={addressRedirect[key].icon}></span>
                                </a>
                            </li>
                        ))}

                        <div className='shadow'></div>
                        <div className='shadow'></div>
                        <li className="nav-item"><a className="small_title nav-link" href={`${baseUrl}about`}>About</a></li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}about?footer_active_subpage=content`}>Content</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}about?footer_active_subpage=references`}>References</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}about?footer_active_subpage=contributors`}>People</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}about?footer_active_subpage=statistics`}>Statistics</a>
                        </li>

                        <div className='shadow'></div>
                        <li className="nav-item"><a className="small_title nav-link" href={`${baseUrl}info`}>Info</a></li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}info?footer_active_subpage=scores`}>Scores</a></li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}info?footer_active_subpage=scenarios`}>Use
                            scenarios</a></li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}info?footer_active_subpage=faqs`}>FAQs</a></li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}info?footer_active_subpage=cookies`}>Cookies/Privacy</a>
                        </li>


                        <div className='shadow'></div>
                        <li className="nav-item"><a className="small_title nav-link" href={`${baseUrl}access`}>Access</a></li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}access?footer_active_subpage=archive`}>Versions</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}access?footer_active_subpage=apis`}>APIs</a></li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}access?footer_active_subpage=licensing`}>Licensing</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}access?footer_active_subpage=usage`}>Usage</a></li>

                        <div className='shadow'></div>
                        <li className="nav-item"><a className="small_title nav-link" href={`${baseUrl}credits`}>Credits</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}credits?footer_active_subpage=funding`}>Funding</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}credits?footer_active_subpage=datasources`}>Datasources</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link"
                                                    href={`${baseUrl}credits?footer_active_subpage=partners`}>Partners</a>
                        </li>
                        <li className="nav-item"><a className="sub_title nav-link" style={{marginBottom: "10vh"}}
                                                    href={`${baseUrl}credits?footer_active_subpage=libraries`}>Software</a>
                        </li>
                    </ul>
                </div>
            </div>
            {location.pathname !== "/" ? (<div className="shadow"></div>) : null}
        </>
    );
}

export default NavBar;