import {useContext} from "react";
import {GlobalContext} from "./GlobalProvider";
import {useLocation} from "react-router-dom";

const ViewersTable = ({taskId, sessionId}: { taskId: string, sessionId: string }) => {

    const {UrlSTRING,} = useContext(GlobalContext);
    const location = useLocation()
    const {
        species,
        identifier,
        requiredScore,
        networkType,
        networkFlavor,
        limit,
        additionalNetworkNodes,
        channel1,
        channel2,
        channel3,
        channel4,
        channel5,
        channel6,
        channel7,
    } = location.state;

    const base_url = `${UrlSTRING}/cgi/`
    let url_params = ``;
    if (taskId){
        url_params = `taskId=${taskId}&sessionId=${sessionId}`;
    }
    else{
        url_params = `species=${species}&identifier=${identifier}&requiredScore=${requiredScore}&networkType=${networkType}&networkFlavor=${networkFlavor}&limit=${limit}&additionalNetworkNodes=${additionalNetworkNodes}&channel1=${channel1}&channel2=${channel2}&channel3=${channel3}&channel4=${channel4}&channel5=${channel5}&channel6=${channel6}&channel7=${channel7}&caller_identity=mobileapp`
    }

    return (
        <div className="main_page_content_wrap">
            <div className="main_page_content_container" id="bottom_page_selector_viewers_container"
                 style={localStorage.getItem('activeView') === "bottom_page_selector_viewers" ? {display: "block"} : {display: "none"}}>
                <div className="legend_headline"> Your network viewers on STRING website</div>
                <table className="evidence_view_choices_item">
                    <tbody>
                    {channel5 == "on" ? (<tr>
                        <td style={{width: "80vw"}}>
                            <span style={{fontWeight: "bold"}}>Experiments</span>
                            <div className={"enrichment_table_small_title"}>Co-purification, co-crystallization,
                                Yeast2Hybrid, Genetic Interactions, etc ... as
                                imported from primary sources.
                            </div>
                        </td>
                        <td>
                            <div className="icon_wrapper" onClick={() => {
                                window.location.href = `${base_url}setevidence?${url_params}&data_channel=experimental`
                            }}>
                                <div className="icon Experiments"></div>
                            </div>
                        </td>
                    </tr>) : null}
                    {channel6 == "on" ? (<tr>
                        <td style={{width: "80vw"}}>
                            <span style={{fontWeight: "bold"}}>Databases</span>
                            <div className={"enrichment_table_small_title"}>Known metabolic pathways, protein
                                complexes,
                                signal transduction pathways, etc ... from
                                curated
                                databases.
                            </div>
                        </td>
                        <td>
                            <div className="icon_wrapper">
                                <div className="icon Databases" onClick={() => {
                                    window.location.href = `${base_url}setevidence?${url_params}&data_channel=database`
                                }}></div>
                            </div>
                        </td>
                    </tr>) : null}
                    {channel7 == "on" ? (<tr>
                        <td style={{width: "80vw"}}>
                            <span style={{fontWeight: "bold"}}>Textmining</span>
                            <div className={"enrichment_table_small_title"}>Automated, unsupervised textmining -
                                searching for proteins that are frequently mentioned
                                together.
                            </div>
                        </td>
                        <td>
                            <div className="icon_wrapper">
                                <div className="icon Textmining" onClick={() => {
                                    window.location.href = `${base_url}textmining?${url_params}&data_channel=textmining`
                                }}></div>
                            </div>
                        </td>
                    </tr>) : null}
                    {channel3 == "on" ? (<tr>
                        <td style={{width: "80vw"}}>
                            <span style={{fontWeight: "bold"}}>Cooccurrence</span>
                            <div className={"enrichment_table_small_title"}>Gene families whose occurrence patterns
                                across genomes show similarities.
                            </div>
                        </td>
                        <td>
                            <div className="icon_wrapper">
                                <div className="icon Cooccurrence" onClick={() => {
                                    window.location.href = `${base_url}cooccurrence?${url_params}&data_channel=cooccurrence`
                                }}></div>
                            </div>
                        </td>
                    </tr>) : null}
                    {channel1 == "on" ? (<tr>
                        <td style={{width: "80vw"}}>
                            <span style={{fontWeight: "bold"}}>Neighborhood</span>
                            <div className={"enrichment_table_small_title"}>Groups of genes that are frequently observed
                                in each other's genomic neighborhood.
                            </div>
                        </td>
                        <td>
                            <div className="icon_wrapper">
                                <div className="icon Neighborhood" onClick={() => {
                                    window.location.href = `${base_url}geneneighbors?${url_params}&data_channel=neighborhood`
                                }}></div>
                            </div>
                        </td>
                    </tr>) : null}
                    {channel2 == "on" ? (<tr>
                        <td style={{width: "80vw"}}>
                            <span style={{fontWeight: "bold"}}>Fusion</span>
                            <div className={"enrichment_table_small_title"}>Genes that are sometimes fused into single
                                open reading frames.
                            </div>
                        </td>
                        <td>
                            <div className="icon_wrapper">
                                <div className="icon Fusion" onClick={() => {
                                    window.location.href = `${base_url}genefusions?${url_params}&data_channel=fusion`
                                }}></div>
                            </div>
                        </td>
                    </tr>) : null}
                    {channel4 == "on" ? (<tr style={{borderBottom: "0"}}>
                        <td style={{width: "80vw"}}>
                            <span style={{fontWeight: "bold"}}>Coexpression</span>
                            <div className={"enrichment_table_small_title"}>Proteins whose genes are observed to be
                                correlated in expression, across a large number
                                of
                                experiments.
                            </div>
                        </td>
                        <td>
                            <div className="icon_wrapper">
                                <div className="icon Coexpression" onClick={() => {
                                    window.location.href = `${base_url}coexpression?${url_params}&data_channel=coexpression`
                                }}>
                                </div>
                            </div>
                        </td>
                    </tr>) : null}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ViewersTable;
