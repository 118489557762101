import React from 'react';

interface HighlightMatchesProps {
    text: string;
    queryText: string;
}

const highlightMatches = (text: string, queryText: string): string => {
    const tagOpen = "<span class='emphasis_keyword'>";
    const tagClose = "</span>";
    const queryWords = queryText.split(/\W+/);

    for (const word of queryWords) {
        if (word.length > 1) {
            const regexWordMatch = new RegExp(`(${word})(?![^<]*>|[^<>]*</)`, 'gi');
            text = text.replace(regexWordMatch, `${tagOpen}$1${tagClose}`);
        }
    }
    return text;
};

const HighlightMatches: React.FC<HighlightMatchesProps> = ({ text, queryText }) => {
    const highlightedText = highlightMatches(text, queryText);
    return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

export default HighlightMatches;