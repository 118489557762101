import React, {useEffect} from "react";

const LogoutPage: React.FC = () => {

    useEffect(() => {
        // remove all user cookies
        document.cookie = "m.string_db_display_name.12.0=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "m.string_login_credentials=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        let pageUrl = window.location.href;
        let pageParts = pageUrl.split("?");
        let scriptName = pageParts[2];
        window.location.href = (scriptName ?? '/');
    }, []);

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className='oversized_input_wait'>
            <div className="spinner"></div>
        </div>
    );
};
export default LogoutPage;
