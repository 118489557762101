import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {GlobalContext} from "../components/GlobalProvider";
import {useErrorBoundary} from "react-error-boundary";

interface NodeRecord {
    inputSpecies: string
    inputSpeciesName: string
    identifier: string
    nameToShow: string
    preferredName: string
    annotationToShow: string
    proteinSequence: string
    Error: string
    ErrorMessage: string
}

interface ErrorObj {
    title: string;
    message: string;
}

const NodePage = () => {
    const {
        species, identifier
    } = useParams();

    const {UrlSTRING,} = useContext(GlobalContext);
    const url = `${UrlSTRING}/cgi/api?apiMethod=protein&apiOutputType=json&species=${species}&identifiers=${identifier}&caller_identity=mobileapp`;
    const organismUrl = `/organism/${species}`; //`${UrlSTRING}/organism/${species}`;
    const [data, setData] = useState<NodeRecord[]>([]);
    const [errorData,] = React.useState<ErrorObj | null>(null);
    const [proteinImage, setProteinImage] = useState<boolean>(false);

    const [proteinSequence, setproteinSequence] = useState<string>()
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();

    useEffect(() => {
        const fetchInfo = () => {
            return fetch(url)
                .then((res) => res.json())
                .then((d) => {
                    setData(d)
                    if (d[0].Error) {
                        let errorData = {
                            title: d[0].Error,
                            message: d[0].ErrorMessage
                        }
                        navigate('/error', {state: errorData});
                        return;
                    }
                })
                .catch((error) => {
                    setTimeout(() => {showBoundary(error);}, 2000); // Delay of 2 seconds
                    }
                );
        }
        fetchInfo();
        if (data.length === 0) {
            return;
        }
        document.title = `${data[0].nameToShow} STRING node (${data[0].inputSpeciesName})`

    },[navigate, showBoundary, url]);

    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        let newProteinSequence = "";
        let offset = 0;
        while (offset < data[0].proteinSequence.length) {
            newProteinSequence += data[0].proteinSequence.slice(offset, offset + 34) + "\n";
            offset += 34;
        }
        setproteinSequence(newProteinSequence);
    }, [data]);

    // get svg node element from ${UrlSTRING}/cgi/api?apiMethod=protein_image&apiOutputType=svg&species_text=9606&identifier=trpa
    useEffect(() => {
            const fetchImage = async (retries = 3, delay = 500) => {
                try {
                    const response = await fetch(`${UrlSTRING}/cgi/api?apiMethod=protein_image&apiOutputType=svg&species_text=${species}&identifier=${identifier}&includeViewBox=True&caller_identity=mobileapp`);
                    if (response.ok) {
                        setProteinImage(true);
                    }
                    let imageSvg = await response.text();
                    let protein_image = document.getElementById("protein_image");
                    // replace path to images to ${UrlSTRING}/images/
                    imageSvg = imageSvg.replace(/\/images\//g, `${UrlSTRING}/images/`);
                    protein_image!.innerHTML = imageSvg;

                } catch (error) {
                    if (retries > 0) {
                        setTimeout(() => fetchImage(retries - 1, delay * 2), delay); // Exponential backoff
                    } else {
                        setProteinImage(false);
                    }
                }
            };
            fetchImage();

            const defineStructuresBehaviour = () => {
                // first get the proteinIds
                let proteinView = document.getElementsByClassName("instanceclassparent");
                if (proteinView.length === 0) {
                    return;
                }

                // for each protein
                for (let i = 0; i < proteinView.length; i++) {
                    let proteinId = proteinView[i].id.split(".")[0];

                    // get the arrows to change the protein view
                    let leftArrow = document.getElementsByClassName(`svgcontrolarrow`)[0] as HTMLElement;
                    let rightArrow = document.getElementsByClassName(`svgcontrolarrow`)[1] as HTMLElement;

                    // add the onclick event to the arrows
                    leftArrow.onclick = () => (window as any).toggle_structures_section(-1, proteinId);
                    rightArrow.onclick = () => (window as any).toggle_structures_section(1, proteinId);
                }
            }
            defineStructuresBehaviour();
        }
        , [species, identifier, UrlSTRING]);

    (window as any).toggle_structures_section = (offset: number, proteinId: string): boolean => {

        const currentSectionNrTextId = `current.section.count.${proteinId}`;
        const currentSectionNrText = document.getElementById(currentSectionNrTextId);
        const maxSectionNrTextId = `max.section.count.${proteinId}`;
        const maxSectionNrText = document.getElementById(maxSectionNrTextId);
        if (!currentSectionNrText || !maxSectionNrText) return false;
        const currentSectionCount = currentSectionNrText.textContent;
        const maxSectionCount = maxSectionNrText.textContent;

        if (!currentSectionCount || !maxSectionCount) return false;
        const desiredNewSection = Number(currentSectionCount) + offset;

        if (desiredNewSection > 0 && desiredNewSection <= Number(maxSectionCount)) {
            currentSectionNrText.textContent = String(desiredNewSection);
            for (let i = 1; i <= Number(maxSectionCount); i++) {
                const thisSectionGroupId = `${proteinId}.protein.section.group.${i}`;
                const thisSectionGroup = document.getElementById(thisSectionGroupId);
                if (thisSectionGroup) {
                    thisSectionGroup.style.visibility = i === desiredNewSection ? 'visible' : 'hidden';
                }
            }

            const domainOffsetId = `domainoffset${proteinId}r${desiredNewSection}`;
            const domainOffsetText = document.getElementById(domainOffsetId);

            if (!domainOffsetText) return false;
            const domainOffset = domainOffsetText.textContent;
            const smartImage = document.getElementById(`${proteinId}_smart_domain_illustration_image`);

            if (smartImage && domainOffset) {
                smartImage.setAttribute('x', domainOffset);
            }
        }
        return true;
    };

    return (
        <>
            {errorData ? null : (
                <div className="menu_content">
                    <div className="edge_page_wrap">
                        <h1>PROTEIN PAGE</h1>
                        {data.map((item, index) => (
                            <div className="edge_function_data" key={index}>
                                <p className="edge_organism_wrap" key={index}>
                                    Organism
                                    <button
                                        style={{padding: "3px", marginLeft: "5px"}}
                                        onClick={() => window.location.href
                                            = organismUrl}>{item.inputSpeciesName}</button>
                                </p>
                                <div className={"data_white_table_row"}>
                                    <p>{item.preferredName} [ {item.identifier} ]</p>
                                    <p>{item.annotationToShow}</p>
                                </div>
                                {proteinImage ? (
                                    <div className="edge_function_data">
                                        <p className={"edge_organism_wrap"}>PROTEIN STRUCTURE</p>
                                        <div className={"data_white_table_row"}>
                                            <div id="protein_image" style={{position: "relative", textAlign: "center"}}>
                                            </div>
                                        </div>
                                    </div>) : null
                                }
                                <div className="edge_function_data">
                                    <p className={"edge_organism_wrap"}>PROTEIN SEQUENCES</p>
                                    <div className={"data_white_table_row"}>
                                        <div className={"svg-container"}>
                                            {proteinSequence !== undefined ? (
                                                <p>{proteinSequence}</p>
                                            ) : (
                                                <p>Loading...</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default NodePage;