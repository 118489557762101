import React from "react";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const SearchMenu = () => {

    document.title = "STRING: functional protein association networks";

    const navigate = useNavigate();

    const handleClickSingleProtein = () => {
        navigate(`/singleProtein`);
        return;
    };
    const handleClickMultipleProteins = () => {
        navigate(`/multipleProteins`);
        return;
    }

    const handleClickOrganism = () => {
        navigate(`/SearchOrganism`);
        return;
    }

    const handleClickTerm = () => {
        navigate(`/SearchTerm`);
        return;
    }

    const handleClickProteinSequences = () => {
        navigate(`/proteinSequences`);
        return;
    }

    return (
        <>
            <Helmet>
                <title> STRING: functional protein association networks</title>
            </Helmet>

            <div className="menu_content">
                <div className="search_menu_wrap">
                    <div className="title">Search</div>
                    <button id={"singleProtein"} onClick={handleClickSingleProtein}>
                        <div className="search_menu_options_wrap">
                        <span>
                            <span className="title">Protein by name</span>
                        </span>
                        </div>
                    </button>
                    <button id={"multipleProtein"} onClick={handleClickMultipleProteins}>
                        <div className="search_menu_options_wrap">
                        <span>
                            <span className="title">Multiple proteins</span>
                        </span>
                        </div>
                    </button>
                    <button id={"proteinSequences"} onClick={handleClickProteinSequences}>
                        <div className="search_menu_options_wrap">
                        <span>
                            <span className="title">Proteins by sequences</span>
                        </span>
                        </div>
                    </button>
                    <button onClick={handleClickTerm}>
                        <div className="search_menu_options_wrap">
                        <span>
                            <span className="title">Pathway / Process / Disease</span>
                        </span>
                        </div>
                    </button>
                    <button id={"organism"} onClick={handleClickOrganism}>
                        <div className="search_menu_options_wrap">
                        <span>
                            <span className="title">Organism</span>
                        </span>
                        </div>
                    </button>
                </div>
                <div className="shadow"></div>
            </div>
        </>
    );
}

export default SearchMenu